// platformSlice.js

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {  connectPlatformAsync } from "../actions/platformActions";
import {  PlatformDataType, PlatformSettingsDataType, connectedPlatformPayload } from "src/interfaces/Platform.interface";

type PlatformSettingsPayload={
    platformSettingsLoading:boolean,
    platformSettingsData:PlatformSettingsDataType| null
}


const initialState:PlatformSettingsPayload = {
    platformSettingsLoading:false,
    platformSettingsData: null,

};

const createPlatformSettingsSlice = createSlice({
  name: "createPlatformSettingSlice",
  initialState,
  extraReducers: (builder) => {
   
    builder.addMatcher(isAnyOf(connectPlatformAsync.pending), (state,action)=>{
      state.platformSettingsLoading=true; 
    })
    builder.addMatcher(isAnyOf(connectPlatformAsync.fulfilled), (state,action)=>{
      
        state.platformSettingsLoading=false; 
    
        state.platformSettingsData = action?.payload;
      })
    builder.addMatcher(isAnyOf(connectPlatformAsync.rejected), (state)=>{
      state.platformSettingsLoading=true; 
    })
      
  },
  reducers: { 
    setPlatformSettingsData :(state,action:{payload:PlatformSettingsPayload,type:string})=> {
      state.platformSettingsData=action.payload?.platformSettingsData
    }
  },
});


export const {setPlatformSettingsData} = createPlatformSettingsSlice.actions
export default createPlatformSettingsSlice.reducer;
