import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getAllProductsVarientsFromChannelAsync} from '../actions/productActions'

const initialState = {
loadingProductVarientsFromChannel:false,
productVarientsOfSingleChannel:[],
productVarientsCountOfSingleChannel:0
}

const productVarientSlice = createSlice({
  name: 'productVarientSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllProductsVarientsFromChannelAsync.pending), (state, action) => {
      state.loadingProductVarientsFromChannel = true
    })
    builder.addMatcher(isAnyOf(getAllProductsVarientsFromChannelAsync.rejected), (state) => {
        state.loadingProductVarientsFromChannel = true
    })
    builder.addMatcher(isAnyOf(getAllProductsVarientsFromChannelAsync.fulfilled), (state, action) => {
      console.log('getAllProductsFromChannelAsync is in fulfilled state ', action.payload )
        state.productVarientsOfSingleChannel = action?.payload?.results
        state.productVarientsCountOfSingleChannel = action?.payload?.count
        state.loadingProductVarientsFromChannel = false
    })
  },
  reducers: {},
})

export const {} = productVarientSlice.actions
export default productVarientSlice.reducer
