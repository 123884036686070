import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Formik} from 'formik'
import React, {Fragment, useEffect, useState} from 'react'
import {Button, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {PlatformSettingDataTypesValues} from 'src/app/_interfaces/setting.interface'
import useAppToast from 'src/hooks/useAppToast'
import {PLATFORM_FIELD, RetrievePlatformDataType, ShippingMethodMapping, platformfielddata_set} from 'src/interfaces/Platform.interface'
import {
  createPlatformSettingAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import {
  deleteFieldMappingAsync,
  getFieldMappingAsync,
  getPlatformFieldsAsync,
  getPlatformFieldsForMainStoreAsync,
  getPlatformFieldsForMainStoreForLocationAsync,
  saveFieldMappingAsync,
} from 'src/redux/actions/productActions'
import {getPlatformParentFieldDataCategoriesAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import ShipmentSyncValues from '../components/ShipmentSyncValues'
import {toast} from 'react-toastify'
import {isActiveBoolean} from 'src/redux/actions/changeisActiveAction'

const InfoTooltip = ({message = ''}) => {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>{message}</Tooltip>}>
      <i className='bi bi-info-circle text-dark fs-5 ms-3'></i>
    </OverlayTrigger>
  )
}


const PlatformSetting = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {selectedPlatformId} = useParams()

  const {errorToast, successToast, warningToast} = useAppToast()
  const [isShortCodeVisible, setIsShortCodeVisible] = useState(false)
  const [isNotesShortCodeVisible, setIsNotesShortCodeVisible] = useState(false)
  const [copyMarkup, setCopyMarkup] = useState<boolean>(false)
  const [buttonString, setButtonString] = useState('')
  const [shortCode, setShortCode] = useState(['[ORDER_ID]', '[ORDER_NUMBER]'])
  const [platformDisplayName, setPlatformDisplayName] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [mainStoreLocationsFieldData, setMainStoreLocationsFieldData] = useState<platformfielddata_set[]|[]>([])
  const [shippingMethodMappings, setShippingMethodMappings] = useState<ShippingMethodMapping[]>([
    {
      id: null,
      source_platform_data_id: '',
      destination_platform_data_id: '',
      destination_platform_data_str:'',
      source_platform_data_str:''
    },
  ])

  const [retrivedPlatformData, setRetrivedPlatformData] = useState<RetrievePlatformDataType | null>(
    null
  )
  const {retrivedPlatformLoading} = useAppSelector(
    (state) => state.retrivePlatformData
  )

  
  const {getPlatformParentFieldCategoryDataLoading} = useAppSelector(
    (state) => state.templateAndPricing
  )
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const {shipmentData, fieldValuesLoader, shipmentFieldMappings} = useAppSelector(
    (state) => state.productsOfSingleChannel
  )

  const [platformFields, setPlatformFields] = useState<{
    mainStorePlatformField:null|PLATFORM_FIELD,
    selectedPlatformOfPlatformField:null|PLATFORM_FIELD
  }>({
    mainStorePlatformField:null,
    selectedPlatformOfPlatformField:null
  })
  const [shipmentMappingFieldData, setShipmentMappingFieldData] = useState<{
    shipmentMappingFieldDataForSelectedStore: platformfielddata_set[] | []
    shipmentMappingFieldDataForMainStore: platformfielddata_set[] | []
  }>({shipmentMappingFieldDataForMainStore: [], shipmentMappingFieldDataForSelectedStore: []})

  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)

  const [loadingInitials, setLoadingInitials] = useState(false)

  const handleCopyMarkup = (data: string) => {
    setCopyMarkup(true)
    setButtonString(data)

    setTimeout(() => {
      setCopyMarkup(false)
      setButtonString('')
    }, 1000)
  }

  const renderTooltip = (shortcode: string) => {
    let tooltipText = ''
    switch (shortcode) {
      case '[ORDER_ID]':
        tooltipText = ` ${mainStoreData?.code} Auto Generated`
        break
      case '[ORDER_NUMBER]':
        tooltipText = `Order Number from ${retrivedPlatformData?.code}`
        break
      default:
        tooltipText = ''
    }
    return <Tooltip id={`${shortcode}-tooltip`}>{tooltipText}</Tooltip>
  }

  useEffect(() => {
    if (selectedPlatformId && mainStoreData?.id ) {
      Promise.all([
        dispatch(
          getPlatformFieldsAsync({
            platformId: Number(selectedPlatformId),
            otherParams: {field_code: 'SHIPPINGPROVIDER'},
          })
        ).then((response) => {
          if (response.payload?.length) {
            setPlatformFields((pre)=>{
              return {...pre, selectedPlatformOfPlatformField:response.payload[0] }
            })
            dispatch(
              getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
            ).then((response) => {
              setShipmentMappingFieldData((pre) => {
                return {...pre, shipmentMappingFieldDataForSelectedStore: response.payload}
              })
            })
          }
        }),
        dispatch(
          getPlatformFieldsForMainStoreAsync({
            platformId: mainStoreData?.id,
            otherParams: {field_code: 'SHIPPINGPROVIDER'},
          })
        ).then((response) => {
          if (response.payload?.length) {
            setPlatformFields((pre)=>{
              return {...pre, mainStorePlatformField:response.payload[0] }
            })
            dispatch(
              getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
            ).then((response) => {
              setShipmentMappingFieldData((pre) => {
                return {...pre, shipmentMappingFieldDataForMainStore: response.payload}
              })
            })
          }
        }),
        dispatch(
          getPlatformFieldsForMainStoreForLocationAsync({
            platformId: mainStoreData?.id,
            otherParams: {field_code: 'LOCATION'},
          })
        ).then((response) => {
          if (response.payload?.length) {
            dispatch(
              getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
            ).then((response) => {
              setMainStoreLocationsFieldData(response.payload)
            })
          }
        })

      ])
    }
  }, [selectedPlatformId, mainStoreData])

  useEffect(() => {
    if (shipmentFieldMappings.length) {
      const savedFieldMappings = shipmentFieldMappings[0].fieldmappingdata_set.map((item) => {
        console.log('item_id', item)
        return {
          id: item?.id,
          destination_platform_data_id: item?.destination_platform_data?.id + ''  ?? '' ,
          source_platform_data_id: item?.source_platform_data?.id + '' ?? '' ,
          destination_platform_data_str: item.destination_platform_data_str ?? '' ,
          source_platform_data_str: item.source_platform_data_str ?? ''
        }
      })
      setShippingMethodMappings(savedFieldMappings)
    }
  }, [shipmentFieldMappings])

  useEffect(() => {
  
    if (selectedPlatformId) {
   
      Promise.all([
      dispatch(retrivePlatformDataAsync({id: selectedPlatformId})).then((resp) => {
        setRetrivedPlatformData(resp.payload)
      }),
      dispatch(getFieldMappingAsync({destination_platform_id: selectedPlatformId}))
    ])
    }
  }, [selectedPlatformId])

  const handleSaveShipmentMappings = () => {

    let mapping_for_save = shippingMethodMappings.map((item) => {
      if (item.id) {
        return item
      } else {
        return {
          id:null,
          source_platform_data_id: item?.source_platform_data_id,
          destination_platform_data_id: item?.destination_platform_data_id,
          source_platform_data_str: item?.source_platform_data_str,
          destination_platform_data_str: item?.destination_platform_data_str,
        }
      }
    })

    if (!shipmentData.selectedStoreShipmentData?.id) {
      return warningToast('Destination field is required')
    }

    if (!shipmentData.mainStoreShipmentData?.id) {
      return warningToast('Source field is required')
    }

    dispatch(
      saveFieldMappingAsync({
        source_field_id: shipmentData.mainStoreShipmentData?.id!,
        destination_field_id: shipmentData.selectedStoreShipmentData?.id!,
        fieldmappingdata_set: mapping_for_save,
      })
    ).then(() => {
      dispatch(getFieldMappingAsync({destination_platform_id: selectedPlatformId}))
    })
  }

  const initialValues: PlatformSettingDataTypesValues = {
    main_product_identifier: '',

    secondary_product_identifier: '',
    pricing_setting: {
      isPriceSync: false,
      // repricing: {
      //   type: 'FLAT',
      //   value: '',
      // },
    },
    inventory_setting: {
      isInventorySync: false,
      default_location:'',
      buffer:0,
    },
    order_setting: {
      order_number_map: '',
      order_note_map:'',
      isNotifyUnlinkedOrders: false,
      isSkipUnpaidOrders: false,
      isImportOrderToSource: false,
      sync_start_date: null,
      isImportCanceledOrder: false,
      isAcceptOrder: false,
      estimated_delivery_days: null,
      estimated_dispatch_days: null,
      tags: '',
    },
    product_setting: {
      isImportProductFromSource: false,
      default_quantity: 0,
      export_product: false,
    },
    shipment_setting: {
      isImportShipmentFromMain: false,
    },
  }

  return (
    <div className='card' key={retrivedPlatformData + ''}>
      <Formik
        initialValues={
          retrivedPlatformData?.platform_settings
            ? retrivedPlatformData?.platform_settings
            : initialValues
        }
        onSubmit={(values, helpers) => {
          console.log('values', values)
          helpers.setSubmitting(true)
          const {...othervalues} = values
          const allSettings = {
            platformId: Number(selectedPlatformId),
            ...othervalues,
          }
          try {
            setLoading(true)
            dispatch(createPlatformSettingAsync(allSettings)).then((response) => {
              console.log('response in connected platform', response)
              if (response?.payload?.status_code === 200) {
                // alert("connectedplatform toast")
                toast.success('Platform settings saved successfully !', {
                  autoClose: 3000, // Set the duration for which the toast will be displayed
                })
                helpers.setSubmitting(false)
                if (selectedPlatformId) {
                  dispatch(retrivePlatformDataAsync({id: selectedPlatformId}))
                }
                setLoading(false)
              }
            })
            console.log('Platform settings created successfully!', allSettings)
            setLoading(false)
            // showSuccessToast() // Show toast on successful save
          } catch (error) {
            console.error('Error creating platform settings:', error)
            helpers.setSubmitting(false)
            setLoading(false)
          }

          const id = Number(selectedPlatformId)!
          const display_name = connectedPlatformData?.display_name
          const isActive = connectedPlatformData?.isActive == false ? true : true
          const payload = {
            display_name,
            isActive,
          }
          console.log('data in connectedplatform', {...payload})
          console.log('id  for now', id)
          dispatch(isActiveBoolean({...payload, id}))
        }}
      >
        {({handleSubmit, handleChange, setFormikState, values,isSubmitting, setSubmitting}) => {
          console.log('string value', values)
          return (
            <form onSubmit={handleSubmit}>
              <div className='card-header bg-white'>
                <div className='card-title'>
                  <span>Platform Settings</span>
                  {/* <div className='d-flex flex-column'>
                    <p className='mb-0'>The Range Store Friendly Name: The Range </p>
                    <small>
                      <span className='badge badge-success'>Connected</span>
                    </small>
                  </div> */}
                </div>
                <div className='card-toolbar'>
                  {/* <Link to={'/listing-channels'} className='btn btn-sm btn-primary  me-2'>
                    Close
                  </Link> */}
                  <button
                    className='btn btn-sm btn-success '
                    type='submit'
                    
                    disabled={
                      values.main_product_identifier.length === 0 ||
                      values.secondary_product_identifier.length === 0 ||
                      loading||isSubmitting
                    }
                  >
                    {isSubmitting ? 'Saving..' : 'Save Changes'}
                  </button>
                </div>
              </div>

              {retrivedPlatformLoading  ? (
                <div className='d-flex align-items-center justify-content-center h-100'>
                  <h1>
                    <Spinner animation='border' variant='primary' />
                  </h1>
                </div>
              ) : (
                <div className='card-body px-3 px-sm-5'>
                  <div className='mt-3 border shadow rounded'>
                    <div className='  ribbon ribbon-start'>
                      <div className='ribbon-label bg-primary'>Platform Connection</div>
                    </div>
                    <div className='row m-4'>
                      <div className='col-12 col-sm-4 h4'>
                        {' '}
                        {retrivedPlatformData?.platform_info?.display_name} Store Friendly Name:
                      </div>
                      <div className='col-6 col-sm-6 d-flex align-items-center h4'>
                        {' '}
                        {retrivedPlatformData?.platform_info?.display_name}
                      </div>

                      <div className='w-100'></div>

                      <div className='row'>
                        <div className='col-6 col-sm-4 h4 mt-1 '>Status:</div>
                        <div className='col-6 col-sm-6 mt-1 ms-sm-2'>
                          <span
                            className={`badge ${
                              retrivedPlatformData?.isConnected ? 'badge-success' : 'badge-danger'
                            }`}
                            style={{fontSize: '1.3rem', borderRadius: '6px'}}
                          >
                            {retrivedPlatformData?.isConnected ? 'Connected' : 'Not Connected'}
                          </span>
                        </div>
                      </div>

                      <div className='w-100'></div>

                      {retrivedPlatformData?.code === 'SHOPIFY' && (
                        <>
                          <div className='col-12 col-sm-4 h4 mt-1'>
                            {retrivedPlatformData?.platform_info?.display_name} {''}Url:
                          </div>
                          <div className='col-12 col-sm-6 h4 mt-1'>
                            {retrivedPlatformData?.credentials?.identifier}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className='mt-5 border shadow rounded'>
                    <div className=' ribbon ribbon-start'>
                      <div className='ribbon-label bg-primary'>Select Product Identifiers</div>
                    </div>
                    <div className='row p-4'>
                      <div className='col-12 col-sm-6'>
                        <label htmlFor='' className='form-label mt-1'>
                          Primary Store
                          <InfoTooltip
                           message={'Choose a unique product identifier for your Primary store to link products.'}
                          
                           />
                        </label>
                        <select
                          className='form-select form-select-lg form-select-white'
                          aria-label='Select example'
                          name='main_product_identifier'
                          value={values.main_product_identifier}
                          onChange={handleChange}
                        >
                          <option value=''>Select Unique identifier</option>
                          <option value='SKU'>SKU</option>
                          <option value='UPC'>UPC</option>
                        </select>
                      </div>
                      <div className='col-12 col-sm-6 '>
                        <label htmlFor='' className='form-label mt-1'>
                          Secondary Store
                          <InfoTooltip
                           message={'Choose a unique product identifier for your Secondary store to link products.'}
                          
                           />
                        </label>
                        {retrivedPlatformData?.code === 'THERANGEPLUS' ? (
                          <>
                            <select
                              className='form-select form-select-lg form-select-white'
                              aria-label='Select example'
                              name='secondary_product_identifier'
                              value={values.secondary_product_identifier}
                              onChange={handleChange}
                            >
                              <option value=''>Select Unique identifier</option>
                              <option value='SKU'>Product Reference</option>{' '}
                              <option value='UPC'>UPC</option>
                            </select>
                          </>
                        ) : (
                          <>
                            <select
                              className='form-select form-select-lg form-select-white'
                              aria-label='Select example'
                              name='secondary_product_identifier'
                              value={values.secondary_product_identifier}
                              onChange={handleChange}
                            >
                              <option value=''>Select Unique identifier</option>
                              <option value='SKU'>SKU</option> <option value='UPC'>UPC</option>
                            </select>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
         



                  <div className='mt-5 border shadow rounded'>
                    <div className=' ribbon ribbon-start'>
                      <div className='ribbon-label bg-primary'>Order</div>
                    </div>
                    <div className='row p-4 mt-4'>
                      <div className='col-12 col-sm-6'>
                        <div className='form-check form-switch form-check-solid d-flex ps-0 align-items-center '>
                          <label
                            className='form-label  me-10 me-sm-0 fs-4 mb-0'
                            htmlFor='isImportOrderToSource'
                          >
                            Order Sync
                          </label>
                          <input
                            className='form-check-input h-30px w-50px ms-5'
                            type='checkbox'
                            name='order_setting.isImportOrderToSource'
                            onChange={handleChange}
                            checked={values.order_setting.isImportOrderToSource}
                            id='isImportOrderToSource'
                          />
                        </div>
                      </div>
                      <div className='separator mt-3'></div>
                    </div>

                    {values.order_setting.isImportOrderToSource && (
                      <>
                        <div className='row p-4'>
                          <div className='col-12 col-sm-6'>
                            <label className='form-label' htmlFor='isNotifyUnlinkedOrders'>
                              Notify on Unlinked Items or any Failed Orders{' '}

                           <InfoTooltip
                           message={'Upon sync failure, you will receive an email with error details and order/item information at your registered email address.'}
                          
                           />
                            
                            </label>
                            <div className='form-check form-switch form-check-solid '>
                              <input
                                className='form-check-input h-30px w-50px'
                                type='checkbox'
                                name='order_setting.isNotifyUnlinkedOrders'
                                checked={values.order_setting.isNotifyUnlinkedOrders}
                                onChange={handleChange}
                                id='isNotifyUnlinkedOrders'
                              />
                            </div>
                          </div>
                          <div className='col-12 col-sm-6'>
                            <label className='form-label' htmlFor='skipUnpaidOrders'>
                              Skip the Sync of Unpaid Orders{' '}
                            <InfoTooltip
                            message={'Any unpaid orders will be automatically skipped and not downloaded'}
                          
                            />
                            
                            </label>
                            <div className='form-check form-switch form-check-solid '>
                              <input
                                className='form-check-input h-30px w-50px'
                                type='checkbox'
                                name='order_setting.isSkipUnpaidOrders'
                                checked={values.order_setting.isSkipUnpaidOrders}
                                onChange={handleChange}
                                id='skipUnpaidOrders'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row p-4'>
                          <div className='col-12 col-sm-6'>
                            <label className='form-label' htmlFor='order_number_map'>
                              {' '}
                              Order Number Preference
                            </label>
                            <input
                              type='text'
                              className='form-control form-control-lg'
                              placeholder='e.g. PREFIX-[ORDER_NUMBER]-SUFFIX'
                              name='order_setting.order_number_map'
                              value={values.order_setting.order_number_map}
                              onChange={handleChange}
                            />
                            <div className='text-end w-md-100'>
                              <p
                                className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                                onClick={() => setIsShortCodeVisible(!isShortCodeVisible)} // Pass input identifier to handleShortCode}
                              >
                                <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options
                                here
                              </p>
                              {isShortCodeVisible && (
                                <div>
                                  {shortCode?.length &&
                                    shortCode.map((ele: string) => (
                                      <Fragment key={ele}>
                                        <OverlayTrigger
                                          placement='bottom'
                                          overlay={renderTooltip(ele)}
                                        >
                                          <CopyToClipboard text={ele}>
                                            <Button
                                              key={ele}
                                              size='sm'
                                              variant={
                                                copyMarkup && buttonString === ele
                                                  ? 'outline-success'
                                                  : 'outline-primary'
                                              }
                                              className={`px-3 py-2 m-1 border border-1 ${
                                                copyMarkup && buttonString === ele
                                                  ? 'border-success'
                                                  : 'border-primary'
                                              } rounded text-hover-dark fs-sm-8 inline-block`}
                                              onClick={() => handleCopyMarkup(ele)}
                                            >
                                              {copyMarkup && buttonString === ele ? (
                                                <span className='fs-sm-8'>Copied !</span>
                                              ) : (
                                                ele
                                              )}
                                            </Button>
                                          </CopyToClipboard>
                                        </OverlayTrigger>
                                      </Fragment>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-12 col-sm-6'>
                            <label className='form-label' htmlFor='order_number_map'>
                              {' '}
                              Order Notes Preference
                            </label>
                            <input
                              type='text'
                              className='form-control form-control-lg'
                              placeholder='e.g. PREFIX-[ORDER_NUMBER]-SUFFIX'
                              name='order_setting.order_note_map'
                              value={values.order_setting.order_note_map}
                              onChange={handleChange}
                            />
                            <div className='text-end w-md-100'>
                              <p
                                className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                                onClick={() => setIsNotesShortCodeVisible(!isNotesShortCodeVisible)} // Pass input identifier to handleShortCode}
                              >
                                <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options
                                here
                              </p>
                              {isNotesShortCodeVisible && (
                                <div>
                                  {shortCode?.length &&
                                    shortCode.map((ele: string) => (
                                      <Fragment key={ele}>
                                        <OverlayTrigger
                                          placement='bottom'
                                          overlay={renderTooltip(ele)}
                                        >
                                          <CopyToClipboard text={ele}>
                                            <Button
                                              key={ele}
                                              size='sm'
                                              variant={
                                                copyMarkup && buttonString === ele
                                                  ? 'outline-success'
                                                  : 'outline-primary'
                                              }
                                              className={`px-3 py-2 m-1 border border-1 ${
                                                copyMarkup && buttonString === ele
                                                  ? 'border-success'
                                                  : 'border-primary'
                                              } rounded text-hover-dark fs-sm-8 inline-block`}
                                              onClick={() => handleCopyMarkup(ele)}
                                            >
                                              {copyMarkup && buttonString === ele ? (
                                                <span className='fs-sm-8'>Copied !</span>
                                              ) : (
                                                ele
                                              )}
                                            </Button>
                                          </CopyToClipboard>
                                        </OverlayTrigger>
                                      </Fragment>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                          {mainStoreData?.name === 'SHOPIFY' ? (
                            <div className='col-12 col-sm-6'>
                              <label className='form-label' htmlFor='tags'>
                                Tags
                              </label>
                              <input
                                className=' form-control form-control-lg mw-550px'
                                name='order_setting.tags'
                                value={values.order_setting.tags}
                                onChange={(ev) => {
                                  // let updatedTags  = ev.target.value.split(' ').join(',')

                                  // Step 1: Get the input value
                                  let inputValue = ev.target.value

                                  // Step 2: Replace multiple spaces with a single space
                                  // inputValue = inputValue.replace(/\s+/g, ' ');

                                  // Step 3: Replace spaces with commas
                                  inputValue = inputValue.replace(/ /g, ',')

                                  // Step 4: Replace multiple commas with a single comma
                                  inputValue = inputValue.replace(/,+/g, ',')

                                  // Step 5: Remove any leading or trailing commas
                                  // inputValue = inputValue.replace(/^,|,$/g, '');

                                  // Update the tags state with the cleaned value
                                  setFormikState((pre) => {
                                    return {
                                      ...pre,
                                      values: {
                                        ...pre.values,
                                        order_setting: {...values.order_setting, tags: inputValue},
                                      },
                                    }
                                  })
                                }}
                                onBlur={() => {
                                  if (values.order_setting.tags.endsWith(',')) {
                                    let updatedTags = values.order_setting.tags.slice(0, -1) // Remove the trailing comma
                                    setFormikState((pre) => {
                                      return {
                                        ...pre,
                                        values: {
                                          ...pre.values,
                                          order_setting: {
                                            ...values.order_setting,
                                            tags: updatedTags,
                                          },
                                        },
                                      }
                                    })
                                  }
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className='row p-4'>
                          <div className='col-12'>
                            <div className='mt-3 border rounded'>
                              <div className=' ribbon ribbon-start'>
                                <div className='ribbon-label bg-primary'>
                                  Choose a starting point of Order (in UTC)
                                </div>
                              </div>
                              <div className='row p-4'>
                                <div className='col-12 col-sm-6 mt-4'>
                                  <label className='form-label' htmlFor='goLiveDate'>
                                    Date Time For Sync
                                    <InfoTooltip
                                    message={'Please select the starting date for downloading orders.'}
                          
                                    />
                                  </label>
                                  <input
                                    type='datetime-local'
                                    className='form-control  w-100 '
                                    id='goLiveDate'
                                    name='order_setting.sync_start_date'
                                    value={values.order_setting.sync_start_date?new Date(values.order_setting.sync_start_date).toISOString().slice(0, 16) : '' }
                                    style={{width: '100%'}} // Inline style for full width
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {['NOTONTHEHIGHSTREET', 'CATCH', 'DEBENHAMS', 'SUPERPHARM'].includes(
                          retrivedPlatformData?.code ?? ''
                        ) ? (
                          <div className='row p-4'>
                            <div className='col-12'>
                              <div className='mt-5 border rounded'>
                                <div className=' ribbon ribbon-start'>
                                  <div className='ribbon-label bg-primary'> Accept Order</div>
                                </div>
                                <div className='row p-4 mt-4'>
                                  <div className='col-12 col-sm-6'>
                                    <div className='form-check form-switch form-check-solid d-sm-flex ps-0 align-items-center '>
                                      <label
                                        className='form-label fs-4 mb-0'
                                        htmlFor='accept_order'
                                      >
                                        Accept Order in {retrivedPlatformData?.code}
                                      </label>
                                      <input
                                        className='form-check-input h-30px w-50px ms-5'
                                        type='checkbox'
                                        name='order_setting.isAcceptOrder'
                                        checked={values.order_setting.isAcceptOrder}
                                        id='accept_order'
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  {retrivedPlatformData?.code !== 'CATCH' && (
                                    <div className='separator mt-3'></div>
                                  )}
                                </div>

                                {values.order_setting.isAcceptOrder &&
                                  retrivedPlatformData?.code !== 'CATCH' && (
                                    <div className='row p-4'>
                                      <div className='col-12 col-sm-6'>
                                        <label
                                          className='form-label'
                                          htmlFor='estimated_dispatch_days'
                                        >
                                          {' '}
                                          Order Dispatch Time (In Days){' '}
                                        
                                        </label>
                                        <input
                                          type='text'
                                          id='estimated_dispatch_days'
                                          className='form-control form-control-lg'
                                          placeholder='name@example.com'
                                          name='order_setting.estimated_dispatch_days'
                                          value={values.order_setting.estimated_dispatch_days + ''}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      <div className='col-12 col-sm-6'>
                                        <label
                                          className='form-label'
                                          htmlFor='estimated_delivery_days'
                                        >
                                          {' '}
                                          Order Delivery Time (In Days){' '}
                                        
                                        </label>
                                        <input
                                          type='text'
                                          id='estimated_delivery_days'
                                          name='order_setting.estimated_delivery_days'
                                          value={values.order_setting.estimated_delivery_days + ''}
                                          className='form-control form-control-lg'
                                          placeholder='name@example.com'
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {retrivedPlatformData?.platform_info?.name === 'ICONIC' && (
                          <div className='d-inline-flex form-check form-switch form-check-custom form-check-solid me-10 mt-4 mb-4 '>
                            <label
                              htmlFor='syncOrderCancel'
                              className=' form-check-label text-dark h4 ms-0'
                            >
                              Sync cancelled orders
                            </label>
                            <input
                              type='checkbox'
                              name='order_setting.isImportCanceledOrder'
                              className='ms-8 form-check-input h-20px w-40px'
                              id='order_setting.syncOrderCancel'
                              checked={values.order_setting.isImportCanceledOrder}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className='mt-5 border shadow rounded'>
                    <div className=' ribbon ribbon-start'>
                      <div className='ribbon-label bg-primary'>Shipment</div>
                    </div>
                    <div className='row p-4 mt-4'>
                      <div className='col-12 col-sm-6'>
                        <div className='form-check form-switch form-check-solid d-flex ps-0 align-items-center '>
                          <label
                            className='form-label  me-10 me-sm-0  fs-4 mb-0'
                            htmlFor='shipmentSync'
                          >
                            Shipment Sync
                          </label>
                          <input
                            className='form-check-input h-30px w-50px ms-5'
                            type='checkbox'
                            name='shipment_setting.isImportShipmentFromMain'
                            checked={values.shipment_setting.isImportShipmentFromMain}
                            id='shipmentSync'
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className='separator mt-3'></div>
                    </div>

                    {values.shipment_setting.isImportShipmentFromMain && (
                      <div className='row p-4'>
                        {
                          fieldValuesLoader ? 
                          <div className='d-flex align-items-center justify-content-center' >
                            <Spinner animation='border' /> 
                          </div> :
                          platformFields.mainStorePlatformField && platformFields.selectedPlatformOfPlatformField ?
                          <div className='col-12 col-sm-12'>
                            <ShipmentSyncValues
                              shippingMethodMappings={shippingMethodMappings}
                              mainStoreShipmentData={
                                shipmentMappingFieldData.shipmentMappingFieldDataForMainStore
                              }
                              selectedStoreShipmentData={
                                shipmentMappingFieldData.shipmentMappingFieldDataForSelectedStore
                              }
                              addNewItem={() => {
                                let newItem : ShippingMethodMapping = { id:null }
                                if(platformFields.mainStorePlatformField?.input_type==='SELECT'){
                                  newItem.source_platform_data_id =''
                                }else{
                                  newItem.source_platform_data_str = ''
                                }

                                if(platformFields.selectedPlatformOfPlatformField?.input_type==='SELECT'){
                                  newItem.destination_platform_data_id = ''
                                }else {
                                  newItem.destination_platform_data_str = ''
                                }

                                setShippingMethodMappings((pre) => {
                                  return [
                                    ...pre,
                                    {...newItem },
                                  ]
                                })
                              }}
                              setShipment={(item, itemIndex) => {
                                let mappings = [...shippingMethodMappings]
                                if (mappings.length) {
                                  let cure = mappings.find((it, inn) => {
                                    if (inn == itemIndex) {
                                      return it
                                    }
                                  })
                                  if (cure) {
                                    cure = item
                                  }

                                  mappings[itemIndex] = cure!
                                  setShippingMethodMappings(mappings)
                                }
                              }}
                              deleteMappingItem={(id, index) => {
                                let copyMppings = [...shippingMethodMappings]
                                if (id) {
                                  dispatch(deleteFieldMappingAsync({mappingDataId: id})).then(
                                    (response) => {
                                      dispatch(
                                        getFieldMappingAsync({
                                          destination_platform_id: selectedPlatformId,
                                        })
                                      )
                                    }
                                  )
                                } else {
                                  let filteredMappings = copyMppings.filter((it, ind) => {
                                    if (ind !== index) {
                                      return it
                                    }
                                  })
                                  setShippingMethodMappings(filteredMappings)
                                }
                              }}
                              mainStorePlatformField={platformFields.mainStorePlatformField}
                              selectedPlatformOfPlatformField={platformFields.selectedPlatformOfPlatformField}
                            />
                            <div className='d-flex justify-content-end'>
                              <button
                                type='button'
                                className='btn btn-success btn-sm mt-2 me-3 align-self-end'
                                onClick={() => handleSaveShipmentMappings()}
                              >
                                Save Shipment
                              </button>
                            </div>
                          </div> : 
                          <div>
                            <p>Platform fields not found</p>
                          </div>
                          
                        }
                      </div>
                    )}
                  </div>

                  <div className='mt-5 border   shadow rounded'>
                    <div className=' ribbon ribbon-start'>
                      <div className='ribbon-label bg-primary'>Inventory</div>
                    </div>
                    <div className='row p-4 mt-4'>
                      <div className='col-12 col-sm-6'>
                        <div className='form-check form-switch form-check-solid d-flex ps-0 align-items-center '>
                          <label
                            className='form-label me-10 me-sm-0 fs-4 mb-0'
                            htmlFor='inventorySync'
                          >
                            Inventory Sync
                          </label>
                          <input
                            className='form-check-input h-30px w-50px ms-5'
                            type='checkbox'
                            name='inventory_setting.isInventorySync'
                            checked={values.inventory_setting.isInventorySync}
                            id='inventorySync'
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className='separator mt-3'></div>
                    </div>
                    {values.inventory_setting.isInventorySync && (
                      <div className='row p-4'>
                        <div className='col-12 col-sm-6'>
                          <label className='form-label' htmlFor='buffer'>
                            Set Safety Stock Level
                            <InfoTooltip
                           message={`Safety Stock: Stock displayed on the marketplace UI will be the primary store's stock minus the entered value.`}
                          
                           />
                          </label>
                          <input
                            type='number'
                            name='inventory_setting.buffer'
                            value={values.inventory_setting.buffer}
                            className='form-control form-control-lg w-200px'
                            placeholder=''
                            id='buffer'
                            onChange={handleChange}
                          />
                        </div>
                       { ['BANDCAMP'].includes(retrivedPlatformData?.code!) ?
                        <div className='col-12 col-sm-6'>
                          <label className='form-label' htmlFor='buffer'>
                            Set Default Location <InfoTooltip message='Set default location for inventory' />
                          </label>
                          <select
                            name='inventory_setting.default_location'
                            onChange={handleChange}
                            className='form-select form-select-lg w-sm-75'
                          >
                            <option value="">Select Location</option>
                            { 
                              mainStoreLocationsFieldData.map((item)=>{
                                return <option key={item.id} value={item.id}>{item.api_name}</option>
                              })
                            }
                          </select>
                        </div>
                       : null  }
                      </div>
                    )}
                  </div>

                  <div className='mt-5 border shadow rounded'>
  <div className='ribbon ribbon-start'>
    <div className='ribbon-label bg-primary'>Pricing</div>
  </div>
  <div className='row p-4 mt-4'>
    <div className='col-12 col-sm-6'>
      <div className='form-check form-switch form-check-solid d-flex ps-0 align-items-center '>
        <label
          className='form-label me-10 me-sm-0 fs-4 mb-0'
          htmlFor='isPriceSync'
        >
          Pricing Sync
        </label>
        <input
          className='form-check-input h-30px w-50px ms-5'
          type='checkbox'
          name='pricing_setting.isPriceSync'
          checked={values?.pricing_setting?.isPriceSync}
          id='isPriceSync'
          onChange={handleChange}
        />
      </div>
    </div>
    {/* <div className='separator mt-3'></div> */}
  </div>
  {/* {values?.pricing_setting?.isPriceSync && (
    <div className='row p-2 me-2 mb-2'>
      <div className='col-sm-3 col-12 align-items-center d-flex justify-content-sm-center'>
        <p className='form-label text-center mb-0'>Price Adjustment</p>
      </div>
      <div className='col-sm-3 col-12 mt-2 mt-sm-0'>
        <select
          className='form-select form-select-lg'
          onChange={(ev) => {
            if (!isNaN(Number(values?.pricing_setting?.repricing?.value))) {
              const adjustment = ev.target.value === 'decrease' ? -1 : 1;
              setFormikState((prev) => ({
                ...prev,
                values: {
                  ...prev.values,
                  pricing_setting: {
                    ...prev.values.pricing_setting,
                    repricing: {
                      ...prev.values?.pricing_setting?.repricing,
                      value: adjustment * Math.abs(Number(prev.values?.pricing_setting?.repricing?.value)) + '',
                    },
                  },
                },
              }));
            }
          }}
          //    onChange={(ev) => {
          //   if (!isNaN(Number(values.pricing_setting?.repricing?.value))) {
          //     if (ev.target.value == 'decrease') {
          //       setFormikState((pre) => {
          //         return {
          //           ...pre,
          //           values: {
          //             ...pre.values,
          //             repricing: {
          //               type: values.pricing_setting.repricing.type,
          //               value: -1 * Number(values.pricing_setting.repricing.value) + '',
          //             },
          //           },
          //         }
          //       })
          //     } else {
          //       if (Number(values.pricing_setting?.repricing?.value) < 0) {
          //         setFormikState((pre) => {
          //           return {
          //             ...pre,
          //             values: {
          //               ...pre.values,
          //               repricing: {
          //                 type: values.pricing_setting.repricing.type,
          //                 value: -1 * Number(values.pricing_setting.repricing.value) + '',
          //               },
          //             },
          //           }
          //         })
          //       } else {
          //         setFormikState((pre) => {
          //           return {
          //             ...pre,
          //             values: {
          //               ...pre.values,
          //               repricing: {
          //                 type: values.pricing_setting.repricing.type,
          //                 value: Number(values.pricing_setting.repricing.value) + '',
          //               },
          //             },
          //           }
          //         })
          //       }
          //     }
          //   }
          // }}
          defaultValue={
            Number(values?.pricing_setting?.repricing?.value) < 0 ? 'decrease' : 'increase'
          }
        >
          <option value={'increase'}>Increase</option>
          <option value={'decrease'}>Decrease</option>
        </select>
      </div>
      <div className='col-sm-3 col-12 mt-2 mt-sm-0'>
        <div className='d-flex align-items-center '>
          <label className='form-label'>by</label>
          <input
            type='number'
            className='ms-2 form-control form-control-lg '
            placeholder={'Enter value'}
            name='pricing_setting.repricing.value'
            value={values?.pricing_setting?.repricing?.value +''}
            onChange={handleChange}
            
          />
        </div>
      </div>
      <div className='col-sm-3 col-12 mt-2 mt-sm-0'>
        <select
          className='form-select form-select-lg'
          defaultValue={'FLAT'}
          name='pricing_setting.repricing.type'
          onChange={handleChange}
          value={values?.pricing_setting?.repricing?.type }
        >
          <option value={'FLAT'}>Flat Amount</option>
          <option value={'PERCENTAGE'}>Percent</option>
        </select>
      </div>
    </div>
  )} */}
</div>


                  <div className='mt-5 border shadow rounded'>
                    <div className=' ribbon ribbon-start'>
                      <div className='ribbon-label bg-primary'>Product</div>
                    </div>
                    <div className='row p-4 mt-4'>
                      <div className='col-12 col-sm-6'>
                        <div className='form-check form-switch form-check-solid d-flex ps-0 align-items-center '>
                          <label
                            className='form-label fs-4  me-13 me-sm-0 mb-0'
                            htmlFor='isImportProductFromSource'
                          >
                            Product Sync
                          </label>
                          <input
                            className='form-check-input h-30px w-50px ms-5'
                            type='checkbox'
                            checked={values.product_setting?.isImportProductFromSource}
                            id='isImportProductFromSource'
                            onChange={handleChange}
                            name='product_setting.isImportProductFromSource'
                          />
                        </div>
                      </div>
                      <div className='separator mt-3'></div>
                    </div>
                    {values.product_setting?.isImportProductFromSource && (
                      <div className='row p-4'>
                        <div className='col-12 col-sm-6'>
                          <label className='form-label'>
                            {' '}
                            Set a Default Stock level for Non-inventory Products{' '}
                            <InfoTooltip
                           message={`This stock will be displayed on your marketplace UI`}
                          
                           />
                          </label>
                          <input
                            type='number'
                            className='form-control form-control-lg w-200px'
                            name='product_setting.default_quantity'
                            value={values.product_setting.default_quantity+'' }
                            placeholder=''
                            onChange={handleChange}
                            min="0" 
                          />
                        </div>
                        {retrivedPlatformData?.code === 'THERANGEPLUS' && (
                          <div className='col-12  col-sm-6 '>
                            <label className='form-label' htmlFor='export_product'>
                              Export Product
                            </label>
                            <div className='form-check form-switch form-check-solid '>
                              <input
                                className='form-check-input h-30px w-50px'
                                type='checkbox'
                                name='product_setting.export_product'
                                checked={values.product_setting.export_product}
                                id='export_product'
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PlatformSetting
