import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getCustomerReportAsync, getCustomersAsync} from '../actions/customerAction'
import { CUSTOMER, ReportData, VENDOR_USER } from 'src/app/_interfaces/customer.interface'



export type initialState = {
  customerLoader: boolean
  totalCustomer:number
  customerList: CUSTOMER[] | []
  reportLoader:boolean
  reportData:ReportData
  selectedCustomerForReport:CUSTOMER|null
  selectedVendorData:VENDOR_USER|null
}

const initialState: initialState = {
  customerLoader: false,
  totalCustomer:0,
  customerList: [],
  reportLoader:false,
  reportData:null,
  selectedCustomerForReport:null,
  selectedVendorData:null,
}

const customerSlice = createSlice({
  name: 'customerSlice',
  initialState,
  extraReducers: (builder) => {
    //get customer list .....
    builder.addMatcher(isAnyOf(getCustomersAsync.pending), (state) => {
      state.customerLoader = true
    })
    builder.addMatcher(isAnyOf(getCustomersAsync.rejected), (state) => {
      state.customerLoader = false
    })
    builder.addMatcher(isAnyOf(getCustomersAsync.fulfilled), (state, action) => {
      state.customerList = action.payload?.data?.data
      state.totalCustomer = action.payload?.data?.total
      state.customerLoader = false
    })
    //get customer list end .....

    // get report data of customer .....
    builder.addMatcher(isAnyOf(getCustomerReportAsync.pending), (state) => {
      state.reportLoader = true
    })
    builder.addMatcher(isAnyOf(getCustomerReportAsync.rejected), (state) => {
      state.reportLoader = false
    })
    builder.addMatcher(isAnyOf(getCustomerReportAsync.fulfilled), (state) => {
      state.reportLoader = false
      console.log('customer report got successfully');
      
    })
    // get report data of customer end .....

  },
  reducers: {
    setSelectedCustomerForReport : (state,  action : { payload : CUSTOMER }  )=>{
        state.selectedCustomerForReport = action.payload
    },
    setVendorDataForUpdate : (state, action :{ payload:VENDOR_USER|null } )=>{
      state.selectedVendorData = action.payload
    }
  },
})

export const { setSelectedCustomerForReport, setVendorDataForUpdate } = customerSlice.actions
export default customerSlice.reducer
