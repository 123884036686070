import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { requestSubscriptionAsync, updateSubscriptionAsync } from '../actions/pricingActions';
import { SubscriptionResponse } from 'src/interfaces/Platform.interface';

interface InitialState {
  requestSubscriptionLoading: boolean;
  data: SubscriptionResponse | null;
  error: string | null;
  changeSubscriptionLoading:boolean,
}

const initialState: InitialState = {
  requestSubscriptionLoading: false,
  data: null,
  error: null,
  changeSubscriptionLoading:false,
};

const pricingSlice = createSlice({
  name: 'pricingSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(requestSubscriptionAsync.pending), (state) => {
      console.log('requestSubscriptionAsync is in pending state');
      state.requestSubscriptionLoading = true;
      state.error = null; // Clear previous errors
    });
    builder.addMatcher(isAnyOf(requestSubscriptionAsync.rejected), (state, action) => {
      console.log('requestSubscriptionAsync is in rejected state');
      state.requestSubscriptionLoading = false;
      state.error = action.error.message || 'An error occurred'; // Set error message
    });
    builder.addMatcher(isAnyOf(requestSubscriptionAsync.fulfilled), (state, action) => {
      console.log('requestSubscriptionAsync is in fulfilled state', action.payload);
      state.requestSubscriptionLoading = false;
      state.data = action.payload; // Store response data
      state.error = null; // Clear previous errors
    });

    //change plan updateSubscriptionAsync

    builder.addMatcher(isAnyOf(updateSubscriptionAsync.pending), (state) => {
        console.log('updateSubscriptionAsync is in pending state');
        state.changeSubscriptionLoading = true;
        state.error = null; // Clear previous errors
      });
      builder.addMatcher(isAnyOf(updateSubscriptionAsync.rejected), (state, action) => {
        console.log('updateSubscriptionAsync is in rejected state');
        state.changeSubscriptionLoading = false;
        state.error = action.error.message || 'An error occurred'; // Set error message
      });
      builder.addMatcher(isAnyOf(updateSubscriptionAsync.fulfilled), (state, action) => {
        console.log('updateSubscriptionAsync is in fulfilled state', action.payload);
        state.changeSubscriptionLoading = false;
        state.data = action.payload; // Store response data
        state.error = null; // Clear previous errors
      });
  },
  reducers: {},
});

export default pricingSlice.reducer;
