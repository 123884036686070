import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {FC} from 'react'
import { Link } from 'react-router-dom'
import {ConnectedPlatform} from 'src/interfaces/Platform.interface'
import {KTIcon, KTSVG} from 'src/marketConnect/helpers'

type ConnectedPlatformStripe = {
  platform: ConnectedPlatform
  onDelete: () => void
  openSettingsModal: () => void
}

const ConnectPlatformStrip: FC<ConnectedPlatformStripe> = ({
  platform,
  onDelete,
  openSettingsModal,
}) => {

  return (
    <div className='card shadow p-2 my-2'>
      <div className='d-sm-flex flex-md-row justify-content-between p-8 border border-light-primary rounded'>
        <div className='d-flex flex-row align-self-center text-align-center'>
          <img
            src={platform.platform_info.logo_ref}
            alt='Channel Logo'
            className='me-2'
            style={{width: '30px', height: '30px'}}
          />
          <h4 className='title mt-1'>{platform?.platform_info?.display_name}</h4>
        </div>
        <div className='d-md-inline-flex d-flex  mt-sm-0 mt-4 justify-content-sm-start justify-content-between'>
          <span className={`badge d-flex align-items-center justify-content-center align-self-center fs-6  ${platform.isConnected ? 'badge-success' : 'badge-danger' }`} style={{ height: '36px', lineHeight: '36px' }}>{ platform.isConnected ?  'Connected' : 'Not Connected' }</span>
          <div className='d-flex' >

          {/* <Link to={`/platform-setting-new/${platform.platform_info.id}/${platform.id}`} className='btn btn-sm me-0 me-sm-2 '> */}
          <Link to={`/platform-setting/${platform.id}`} className='btn btn-sm me-0 me-sm-2 '>
              <KTSVG 
                path='media/icons/duotune/coding/cod009.svg'
                className='svg-icon-muted svg-icon-2hx'
              />
      
            </Link>
            <button className='btn btn-sm btn-danger me-0 me-sm-4' onClick={onDelete}>
              {/* <KTSVG
                path='media/icons/duotune/general/gen034.svg'
                className='svg-icon-muted svg-icon-2hx'
              /> */}
               <KTIcon iconName='trash' className=' fs-3' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectPlatformStrip
