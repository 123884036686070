import {faPlus, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import {FormikState} from 'formik'
import React, {FC, Fragment, useEffect, useState} from 'react'
import { Button, OverlayTrigger } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  MORE_MAPPING_FORM_DATA,
  MORE_TEMPLATE_DATA,
  MORE_TEMPLATE_DATA_ON_GET,
  MoreFieldMappingItem,
  MoreMapping_Item_On_Get,
  PLATFORM_FIELD_DATA,
} from 'src/interfaces/Template.interface'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import {getPlatformParentFieldDataCategoriesAsync, getShortCodeAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import AppCustomEditor2 from 'src/utils/AppCustomEditor2'

type OPTION_TYPE = 'NATIVE' | 'CUSTOM' | 'DEFAULT'

type TemplateMappingItemPropsType = {
  handleRemoveRow: (index: number, templateDataId:number|null) => void
  categoryAttributes: MoreFieldMappingItem
  index: number
  values: MORE_MAPPING_FORM_DATA
  handleChange: (e: string | React.ChangeEvent<any>) => void
  handleValueTextChange: (attributeId: number, value: string) => void
  selectedTextValues: {[key: number]: string | null}
  selectedFieldValues: {[key: number]: number}
  rowData: MORE_TEMPLATE_DATA
  setFormikState: (
    f:
      | FormikState<MORE_MAPPING_FORM_DATA>
      | ((prevState: FormikState<MORE_MAPPING_FORM_DATA>) => FormikState<MORE_MAPPING_FORM_DATA>),
    cb?: (() => void) | undefined
  ) => void
  moreTemplateItemData: MoreMapping_Item_On_Get | null
  moreTemplateItemDataUnderMap: MORE_TEMPLATE_DATA_ON_GET | null
  deletingTemplateData:boolean
}

const TemplateMappingItem: FC<TemplateMappingItemPropsType> = ({
  handleRemoveRow,
  categoryAttributes,
  index,
  values,
  handleChange,
  handleValueTextChange,
  selectedTextValues,
  selectedFieldValues,
  rowData,
  setFormikState,
  moreTemplateItemData,
  moreTemplateItemDataUnderMap,
  deletingTemplateData,
}) => {
  const [attributeMappingOptions, setAttributeMappingOptions] = useState<OPTION_TYPE>('DEFAULT')

  const dispatch = useAppDispatch()
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)

  const [fieldData, setFieldData] = useState<any[]>([])
  const [fieldDataCustom, setFieldDataCustom] = useState<any[]>([])
  const [dataSetLoading, setDataSetLoading] = useState<boolean>(false)
  const [platformFieldDataSet, setPlatformFieldDataSet] = useState<PLATFORM_FIELD_DATA[] | []>([])
  const [shortCode, setShortCode] = useState([])
  const [copyMarkup, setCopyMarkup] = useState<boolean>(false)
  const [buttonString, setButtonString] = useState('');
  const [shortCodeVisibility, setShortCodeVisibility] = useState(false);

  const handleCopyMarkup = (data: string, identifier: string) => {
      setCopyMarkup(true)
      setButtonString(data)
  };

  const handleShortCode = async (input: string) => {
    try {
      const response = await dispatch(
        getShortCodeAsync({ shortcode_type: 'PRODUCT_SC', search_string: input })
      );
      if (response.payload) {
        setShortCode(response.payload?.results);
        setShortCodeVisibility(true)
      }
    } catch (error) {
      console.error('Error fetching short codes:', error);
    }
  };

  const handleMappingOptionChange = (mappedIndex: number, option: any) => {
    setAttributeMappingOptions(option)

    if (option === 'NATIVE' || option === 'CUSTOM') {
      const fieldType = option === 'CUSTOM' ? 'CUSTOM' : 'NATIVE'
      setDataSetLoading(true)
      dispatch(
        getPlatformFieldsAsync({
          platformId: mainStoreData?.id!,
          otherParams: {field_type: fieldType, includeLocalFields: option === 'NATIVE'},
        })
      ).then((response) => {
        if (fieldType === 'NATIVE') {
          setFieldData(response.payload)
          setDataSetLoading(false)
        } else {
          setFieldDataCustom(response.payload)
          setDataSetLoading(false)
        }
      })
    }

    // resetting the field value on change of field type
    let templateDataMappingShadow = [...values.template_data]
    let singleOuterValues = {...values}
    if (mappedIndex === 0) {
      if (option === 'DEFAULT') {
        singleOuterValues.mapped_field = null
        singleOuterValues.value = null
        singleOuterValues.value_str = ''
        console.log('singleOuterValues', singleOuterValues)
        setFormikState((pre) => {
          return {
            ...pre,
            values: {
              ...singleOuterValues,
              template_data: [{id: null, mapped_field: null, value: null, value_str: ''}],
            },
          }
        })
      } else {
        templateDataMappingShadow[mappedIndex] = {
          id: templateDataMappingShadow[mappedIndex].id,
          mapped_field: null,
          value: null,
          value_str: '',
        }
        setFormikState((pre) => {
          return {...pre, values: {...pre.values, template_data: templateDataMappingShadow}}
        })
      }
    } else {
      templateDataMappingShadow[mappedIndex] = {
        id: templateDataMappingShadow[mappedIndex].id,
        mapped_field: null,
        value: null,
        value_str: '',
      }
      setFormikState((pre) => {
        return {...pre, values: {...pre.values, template_data: templateDataMappingShadow}}
      })
    }
  }

  useEffect(() => {
    if (
      (categoryAttributes && categoryAttributes.input_type === 'MULTI_INSTANCE_SELECT') ||
      (categoryAttributes && categoryAttributes.input_type === 'SELECT')
    ) {
      setDataSetLoading(true)
      dispatch(
        getPlatformParentFieldDataCategoriesAsync({
          platform_Id: categoryAttributes.id!,
        })
      )
        .then((response) => {
          setPlatformFieldDataSet(response.payload)
          setDataSetLoading(false)
        })
        .catch(() => {
          setDataSetLoading(false)
        })
    }
  }, [categoryAttributes])

  //under mapping case fill the values with their field type

  useEffect(() => {
    if (moreTemplateItemDataUnderMap?.mapped_field) {
      if (
        moreTemplateItemDataUnderMap.mapped_field.field_type === 'LOCAL' ||
        moreTemplateItemDataUnderMap.mapped_field.field_type === 'NATIVE'
      ) {
        setDataSetLoading(true)
        setAttributeMappingOptions('NATIVE')
        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {
              field_type: moreTemplateItemDataUnderMap.mapped_field.field_type,
              includeLocalFields: true,
            },
          })
        ).then((response) => {
          setFieldData(response.payload)
          setDataSetLoading(false)
        })
      } else {
        setAttributeMappingOptions('CUSTOM')
        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {field_type: moreTemplateItemDataUnderMap.mapped_field.field_type},
          })
        ).then((response) => {
          setFieldDataCustom(response.payload)
          setDataSetLoading(false)
        })
      }
    }
  }, [moreTemplateItemDataUnderMap])

  useEffect(() => {
    if (moreTemplateItemData?.mapped_field) {
      if (
        moreTemplateItemData.mapped_field.field_type === 'LOCAL' ||
        moreTemplateItemData.mapped_field.field_type === 'NATIVE'
      ) {
        setDataSetLoading(true)
        setAttributeMappingOptions('NATIVE')
        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {
              field_type: moreTemplateItemData.mapped_field.field_type,
              includeLocalFields: true,
            },
          })
        ).then((response) => {
          setFieldData(response.payload)
          setDataSetLoading(false)
        })
      } else {
        setAttributeMappingOptions('CUSTOM')
        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {field_type: moreTemplateItemData.mapped_field.field_type},
          })
        ).then((response) => {
          setFieldDataCustom(response.payload)
          setDataSetLoading(false)
        })
      }
    }
  }, [moreTemplateItemData?.mapped_field, moreTemplateItemData?.value])

  return (
    <div className={clsx('row w-100 mb-3', categoryAttributes.input_type ==='MULTILINE_TEXT' ? '' : 'align-items-center'  )}>
      <div className='col-sm-2 col-6'>
        <label className='form-label flex-shrink-0 '>
          {categoryAttributes.display_name}
        </label>
      </div>

      <div className='col-sm-4 col-12'>
        <div className='row align-items-center'>
          <div className='col-sm-6 col-4'>
            <label className='form-label '>Mapping Option</label>
          </div>
          <div className='col-sm-6 col-4'>
            <select
              className='form-select ms-3 flex-grow-1'
              value={attributeMappingOptions}
              onChange={(e) => handleMappingOptionChange(index, e.target.value)}
            >
              <option value='DEFAULT'>Default</option>
              <option value='NATIVE'>Native Field</option>
              <option value='CUSTOM'>Custom Field</option>
            </select>
          </div>
        </div>
      </div>
      {index == 0 ? (
        <>
          <div className='col-12 col-sm-6 mt-2 mt-sm-0'>
            <div className='row p-0'>
              {attributeMappingOptions === 'DEFAULT' ? (
                <div className='col-10 col-sm-8 ms-sm-3  mb-0 ms-sm-0 me-sm-3 '>
                  {categoryAttributes.input_type === 'SELECT' && (
                    <select
                      className='form-select d-flex flex-wrap  flex-grow-1'
                      name='value'
                      disabled={dataSetLoading}
                      value={values?.value ? values?.value : ''}
                      onChange={handleChange}
                    >
                      <option>{dataSetLoading?'Loading...':`Select ${categoryAttributes.display_name}`}</option>
                      {platformFieldDataSet?.map((field: PLATFORM_FIELD_DATA) => (
                        <option key={field.id} value={field.id}>
                          {field.api_name}
                        </option>
                      ))}
                    </select>
                  )}
                  {categoryAttributes.input_type === 'TEXT' && (
                    <input
                      type='text'
                      name='value_str'
                      className='form-control flex-grow-1'
                      placeholder={categoryAttributes.display_name}
                      value={values?.value_str ? values?.value_str : ''}
                      onChange={handleChange}
                    />
                  )}
                   {categoryAttributes.input_type === 'INTEGER' && (
                    <input
                      type='number'
                      name='value_str'
                      className='form-control flex-grow-1'
                      placeholder={categoryAttributes.display_name}
                      // value={values?.value ?  values?.value : 0}
                      value={values?.value_str ? values?.value_str : ''}
                      onChange={handleChange}
                    />)}
                  {categoryAttributes.input_type === 'MULTI_INSTANCE_TEXT' && (
                    <input
                      type='text'
                      name={`template_data[${index}].value_str`}
                      className='form-control  flex-grow-1'
                      placeholder={categoryAttributes.display_name}
                      value={rowData?.value_str ? rowData?.value_str : ''}
                      onChange={handleChange}
                    />
                  )}
                  {categoryAttributes.input_type === 'MULTI_INSTANCE_SELECT' && (
                    <select
                      className='form-select d-flex flex-wrap  flex-grow-1'
                      name={`template_data[${index}].value`}
                      disabled={dataSetLoading}
                      value={rowData?.value ? rowData?.value : ''}
                      onChange={handleChange}
                    >
                      <option>Select {categoryAttributes.display_name}</option>
                      {platformFieldDataSet?.map((field: PLATFORM_FIELD_DATA) => (
                        <option key={field.id} value={field.id}>
                          {field.api_name}
                        </option>
                      ))}
                    </select>
                  )}

                  {categoryAttributes.input_type === 'MULTILINE_TEXT' && (
                        <>
                        <AppCustomEditor2 value={rowData.value_str} onChangeData={(val)=>{
                          let shadowTemplate = [...values.template_data]
                          shadowTemplate[index].value_str = val
                          setFormikState(pre=>{
                            return {...pre, values:{ ...pre.values , template_data: shadowTemplate }}
                          })
                        }} />
                        <div>
                        <div className='text-end'>
                          <button type='button' className='btn text-muted text-hover-primary fw-bold mt-1 cursor-pointer' onClick={() => handleShortCode('description')}>
                            <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
                          </button>
                        </div>
                        <div className='text-end w-md-100'>
                        {shortCodeVisibility ? (
                          <div className='text-end'>
                            {shortCode
                              ? shortCode.map((item: any) => {
                                  return (
                                    <Fragment key={item.id}>
                                      <CopyToClipboard text={item.name}>
                                        <Button
                                          size='sm'
                                          variant={copyMarkup && buttonString === item.name ? 'outline-success' : 'outline-primary'}
                                          className={`px-3 py-2 border border-1 m-2 ${
                                            copyMarkup && buttonString === item.name ? 'border-success' : 'border-primary'
                                          } rounded text-hover-white fs-sm-8 inline-block`}
                                          onClick={() => handleCopyMarkup(item.name, 'description')}
                                        >
                                          {copyMarkup && buttonString === item.name ? <span className='fs-sm-8'>Copied !</span> : item.name}
                                        </Button>
                                      </CopyToClipboard>
                                    </Fragment>
                                  );
                                })
                              : null}
                          </div>
                        ) : null}
                        </div>
                        </div>
                      </>
                  )}
                  
                </div>
              ) : (
                <div className='col-10 col-sm-8  ms-sm-3 '>
                  {categoryAttributes.input_type.includes('MULTI') ? (
                    <select
                      className='form-select d-flex flex-wrap   me-2 flex-grow-1 '
                      name={`template_data[${index}].mapped_field`}
                      value={rowData?.mapped_field ? rowData?.mapped_field : ''}
                      onChange={handleChange}
                      disabled={dataSetLoading}
                    >
                      <option>{dataSetLoading?'Loading...':`Select ${categoryAttributes.display_name}`}</option>
                      {attributeMappingOptions === 'NATIVE'
                        ? fieldData?.map((field) => (
                            <option key={field.id} value={field.id}>
                              {field.display_name}
                            </option>
                          ))
                        : fieldDataCustom?.map((field) => (
                            <option key={field.id} value={field.id}>
                              {field.display_name}
                            </option>
                          ))}
                    </select>
                  ) : (
                    <select
                      className='form-select d-flex flex-wrap  me-2 flex-grow-1 '
                      name={`mapped_field`}
                      value={values?.mapped_field ? values?.mapped_field : ''}
                      onChange={handleChange}
                      disabled={dataSetLoading}
                    >
                      <option>{dataSetLoading?'Loading...':`Select ${categoryAttributes.display_name}`}</option>
                      {attributeMappingOptions === 'NATIVE'
                        ? fieldData?.map((field) => (
                            <option key={field.id} value={field.id}>
                              {field.display_name}
                            </option>
                          ))
                        : fieldDataCustom?.map((field) => (
                            <option key={field.id} value={field.id}>
                              {field.display_name}
                            </option>
                          ))}
                    </select>
                  )}
                </div>
              )}

              <div className='col-2'>
                {index !== 0 && (
                  <button
                    className='btn btn-outline btn-light-danger ms-4 mt-2 mb-4 text-end'
                    type='button'
                    disabled={deletingTemplateData}
                    onClick={() => handleRemoveRow(index, rowData.id)}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='col-12 col-sm-6 mt-1 mt-sm-0 '>
            <div className='row p-0 ms-sm-1'>
              {attributeMappingOptions === 'DEFAULT' ? (
                <div className='col-10 col-sm-8  ms-sm-0  mb-0 ms-sm-0 me-sm-0'>
                  {categoryAttributes.input_type === 'SELECT' && (
                    <select
                      className='form-select d-flex flex-wrap  flex-grow-1'
                      name='value'
                      disabled={dataSetLoading}
                      value={values?.value ? values?.value : ''}
                      onChange={handleChange}
                    >
                      <option>Select {categoryAttributes.display_name}</option>
                      {platformFieldDataSet?.map((field: PLATFORM_FIELD_DATA) => (
                        <option key={field.id} value={field.id}>
                          {field.api_name}
                        </option>
                      ))}
                    </select>
                  )}
                  {categoryAttributes.input_type === 'TEXT' && (
                    <input
                      type='text'
                      name='value_str'
                      className='form-control flex-grow-1'
                      placeholder={categoryAttributes.display_name}
                      value={values?.value_str ? values?.value_str : ''}
                      onChange={handleChange}
                    />
                  )}
                  {categoryAttributes.input_type === 'MULTI_INSTANCE_TEXT' && (
                    <input
                      type='text'
                      name={`template_data[${index}].value_str`}
                      className='form-control flex-grow-1'
                      placeholder={categoryAttributes.display_name}
                      value={rowData?.value_str ? rowData?.value_str : ''}
                      onChange={handleChange}
                    />
                  )}
                  {categoryAttributes.input_type === 'MULTI_INSTANCE_SELECT' && (
                    <select
                      className='form-select d-flex flex-wrap flex-grow-1'
                      name={`template_data[${index}].value`}
                      disabled={dataSetLoading}
                      value={rowData?.value ? rowData?.value : ''}
                      onChange={handleChange}
                    >
                      <option>Select {categoryAttributes.display_name}</option>
                      {platformFieldDataSet?.map((field: PLATFORM_FIELD_DATA) => (
                        <option key={field.id} value={field.id}>
                          {field.api_name}
                        </option>
                      ))}
                    </select>
                  )}
                  {categoryAttributes.input_type === 'MULTILINE_TEXT' && (
                    <>
                      <AppCustomEditor2 value={rowData.value_str} onChangeData={(val)=>{
                        let shadowTemplate = [...values.template_data]
                        shadowTemplate[index].value_str = val
                        setFormikState(pre=>{
                          return {...pre, values:{ ...pre.values , template_data: shadowTemplate }}
                        })
                      }} />
                      <div>
                      <div className='text-end'>
                        <button type='button' className='btn text-muted text-hover-primary fw-bold mt-1 cursor-pointer' onClick={() => handleShortCode('description')}>
                          <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
                        </button>
                      </div>
                      <div className='text-end w-md-100'>
                      {shortCodeVisibility ? (
                        <div className='text-end'>
                          {shortCode
                            ? shortCode.map((item: any) => {
                                return (
                                  <Fragment key={item.id}>
                                    <CopyToClipboard text={item.name}>
                                      <Button
                                        size='sm'
                                        variant={copyMarkup && buttonString === item.name ? 'outline-success' : 'outline-primary'}
                                        className={`px-3 py-2 border border-1 m-2 ${
                                          copyMarkup && buttonString === item.name ? 'border-success' : 'border-primary'
                                        } rounded text-hover-white fs-sm-8 inline-block`}
                                        onClick={() => handleCopyMarkup(item.name, 'description')}
                                      >
                                        {copyMarkup && buttonString === item.name ? <span className='fs-sm-8'>Copied !</span> : item.name}
                                      </Button>
                                    </CopyToClipboard>
                                  </Fragment>
                                );
                              })
                            : null}
                        </div>
                      ) : null}
                      </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className='col-10 col-sm-8  mb-0 '>
                  <select
                    className='form-select d-flex flex-wrap  me-4 flex-grow-1'
                    name={`template_data[${index}].mapped_field`}
                    value={rowData?.mapped_field ? rowData?.mapped_field : ''}
                    onChange={handleChange}
                    disabled={dataSetLoading}
                  >
                    <option>{dataSetLoading?'Loading...':`Select ${categoryAttributes.display_name}`}</option>
                    {attributeMappingOptions === 'NATIVE'
                      ? fieldData?.map((field) => (
                          <option key={field.id} value={field.id}>
                            {field.display_name}
                          </option>
                        ))
                      : fieldDataCustom?.map((field) => (
                          <option key={field.id} value={field.id}>
                            {field.display_name}
                          </option>
                        ))}
                  </select>
                </div>
              )}

              <div className='col-2  '>
                {index !== 0 && (
                  <button
                    className='btn btn-outline btn-light-danger ms-4  text-end'
                    type='button'
                    disabled={deletingTemplateData}
                    onClick={() => handleRemoveRow(index, rowData.id)}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default TemplateMappingItem
