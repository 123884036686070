import { createAsyncThunk } from "@reduxjs/toolkit"
import AxiosClient from "../AxiosClient"


export const getSettingsOfSelectedPlatformAsync = createAsyncThunk(
    'platformSettings/getSettingsOfSelectedPlatformAsync',
    async function (params:{availablePlatformId:string}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.availablePlatformId}/settings`, [], toolkit, [] )
    }
  )

  export const getSettingsOfPrimaryPlatformAsync = createAsyncThunk(
    'platformSettings/getSettingsOfPrimaryPlatformAsync',
    async function (params:{primaryAvailablePlatformId:number}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.primaryAvailablePlatformId}/settings?type=MAIN_STORE_SETTING`, [], toolkit, [] )
    }
  )