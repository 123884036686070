
import React, {FC, useEffect, useState, ChangeEvent} from 'react'
import {Alert, Button, Card, Col, Form, Modal, Offcanvas, Row} from 'react-bootstrap'
import InputPriceSyncValues from './InputPriceSyncValues'
import InventorySyncValues from './InventorySyncValues'
import OrderSyncValues from './OrderSyncValues'
import {
  PlatformDataType,
  PlatformSettingsDataType,
  RetrievePlatformDataType,
  platformfielddata_set,
} from 'src/interfaces/Platform.interface'
import ProductSyncValues from './ProductSyncValues'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  createPlatformSettingAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import Spinner from 'react-bootstrap/Spinner'
import ShipmentSyncValues from './ShipmentSyncValues'
import {toast} from 'react-toastify'
import {
  deleteFieldMappingAsync,
  getFieldMappingAsync,
  getPlatformFieldsAsync,
  getPlatformFieldsForMainStoreAsync,
  saveFieldMappingAsync,
} from 'src/redux/actions/productActions'
import Loader from 'src/utils/Loader'
import useAppToast from 'src/hooks/useAppToast'
import {isActiveBoolean} from 'src/redux/actions/changeisActiveAction'
import {getPlatformParentFieldDataCategoriesAsync} from 'src/redux/actions/templateAndPricingActions'
type ConnectedPlatformSettingModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  onSaveSettings: (settings: any) => void
  // platformData?: RetrievePlatformDataType | null
  selectedPlatformId?: number | null
  // retrivedPlatformLoading?: boolean
} 

interface ShippingMethodMapping {
  id: null | number
  source_platform_data_id: string
  destination_platform_data_id: string
}
interface InventorySettings {
  isInventorySync: boolean
  buffer: number
}

const ConnectedPlatformSettingModal: FC<ConnectedPlatformSettingModalPropsType> = ({
  isVisible,
  closeModal,
  // platformData,
  selectedPlatformId,
  // retrivedPlatformLoading,

  // onSaveSettings,
}) => {
  const {retrivedPlatformLoading, retrivedPlatformData} = useAppSelector(
    (state) => state.retrivePlatformData
  )

  const [inventoryValuesNumber, setInventoryValuesNumber] = useState(0)
  const [loading, setLoading] = useState(false)
  const {getPlatformParentFieldCategoryDataLoading} = useAppSelector(
    (state) => state.templateAndPricing
  )
  const [shipmentMappingFieldData, setShipmentMappingFieldData] = useState<{
    shipmentMappingFieldDataForSelectedStore: platformfielddata_set[] | []
    shipmentMappingFieldDataForMainStore: platformfielddata_set[] | []
  }>({shipmentMappingFieldDataForMainStore: [], shipmentMappingFieldDataForSelectedStore: []})

  // const handleInputChange = (e) => {
  //   const inputValue = e.target.value
  //   // console.log('number buffer', inputValue)
  //   if (inputValue > 0) {
  //     setInventoryValuesNumber(inputValue)
  //     console.log('number buffer func', inventoryValuesNumber)
  //   } else {
  //     setInventoryValuesNumber(0)
  //   }
  // }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(e.target.value, 10) // Parse input value to an integer
    if (!isNaN(inputValue) && inputValue >= 0) {
      // Check if the input is a valid number
      setInventoryValues((prevValues) => ({
        ...prevValues,
        buffer: inputValue,
      }))
    } else {
      setInventoryValues((prevValues) => ({
        ...prevValues,
        buffer: 0,
      }))
    }
  }

  const onSaveSettings = async (allSettings: PlatformSettingsDataType) => {
    // try {
    //   setLoading(true)
    //   await dispatch(createPlatformSettingAsync(allSettings)).then((response) => {
    //     console.log('response in connected platform', response)
    //     if (response?.payload?.status_code === 200) {
    //       // alert("connectedplatform toast")
    //       toast.success('Platform settings saved successfully !', {
    //         autoClose: 3000, // Set the duration for which the toast will be displayed
    //       })
    //       if (selectedPlatformId) {
    //         dispatch(retrivePlatformDataAsync({id: selectedPlatformId}))
    //       }
    //       setLoading(false)
    //     }
    //   })
    //   console.log('Platform settings created successfully!', allSettings)
    //   setLoading(false)
    //   // showSuccessToast() // Show toast on successful save
    // } catch (error) {
    //   console.error('Error creating platform settings:', error)
    //   setLoading(false)
    // }
  
    const id = selectedPlatformId!
    const display_name = connectedPlatformData?.display_name
    const isActive = connectedPlatformData?.isActive == false ? true : true
    const payload = {
      display_name,
      isActive,
    }
    console.log('data in connectedplatform', {...payload})
    console.log('id  for now', id)
    await dispatch(isActiveBoolean({...payload, id}))
  }

  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const {shipmentData, fieldValuesLoader, shipmentFieldMappings} = useAppSelector(
    (state) => state.productsOfSingleChannel
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedPlatformId) {
      dispatch(
        getPlatformFieldsAsync({
          platformId: selectedPlatformId,
          otherParams: {field_code: 'SHIPPINGPROVIDER'},
        })
      ).then((response) => {
        if (response.payload?.length) {
          dispatch(
            getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
          ).then((response) => {
            setShipmentMappingFieldData((pre) => {
              return {...pre, shipmentMappingFieldDataForSelectedStore: response.payload}
            })
          })
        }
      })
    }

    if (mainStoreData?.id) {
      dispatch(
        getPlatformFieldsForMainStoreAsync({
          platformId: mainStoreData?.id,
          otherParams: {field_code: 'SHIPPINGPROVIDER'},
        })
      ).then((response) => {
        if (response.payload?.length) {
          dispatch(
            getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
          ).then((response) => {
            setShipmentMappingFieldData((pre) => {
              return {...pre, shipmentMappingFieldDataForMainStore: response.payload}
            })
          })
        }
      })
    }
  }, [selectedPlatformId, mainStoreData])

  const [main_product_identifier, setMainProductIdentifier] = useState('')
  const [secondary_product_identifier, setSecondaryProductIdentifier] = useState('')
  const [showPriceSync, setShowPriceSync] = useState<boolean | undefined>(false)
  const [showOrderSync, setShowOrderSync] = useState<boolean>(false)
  const [showShipmentSync, setShowShipmentSync] = useState<boolean>(false)
  const {errorToast, successToast, warningToast} = useAppToast()
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [platformDisplayName, setPlatformDisplayName] = useState<string>('')
  const [shippingMethodMappings, setShippingMethodMappings] = useState<ShippingMethodMapping[]>([
    {
      id: null,
      source_platform_data_id: '',
      destination_platform_data_id: '',
    },
  ])
  const [pricingValues, setPricingValues] = useState<any>({
    isPriceSync: undefined,
    repricing: {
      type: '',
      value: '',
    },
  })

  const handleOptionChange1 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMainProductIdentifier(event.target.value)
  }

  const handleOptionChange2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSecondaryProductIdentifier(event.target.value)
  }
  const [orderValues, setOrderValues] = useState({
    order_number_map: '',
    isNotifyUnlinkedOrders: false,
    isSkipUnpaidOrders: true,
    isImportOrderToSource: true,
    // isImportUnlinkedOrders: false,
    isImportCanceledOrder: false,
    sync_start_date: null as Date | string | null,
    isAcceptOrder: false,
    estimated_delivery_days: null as number | null,
    estimated_dispatch_days: null as number | null,
    tags:'',
    
  })

  const [inventoryValues, setInventoryValues] = useState<InventorySettings>({
    isInventorySync: false,
    buffer: 0,
  })

  
  const [productValues, setProductValues] = useState({
    // isImportProductToSource: false,
    isImportProductFromSource:false,
    default_quantity:0,
    export_product:false,
  })

  const [shipmentValues, setShipmentValues] = useState({
    isImportShipmentFromMain: false,
  })

  useEffect(() => {
    setMainProductIdentifier(retrivedPlatformData?.platform_settings?.main_product_identifier || '')
    setSecondaryProductIdentifier(
      retrivedPlatformData?.platform_settings?.secondary_product_identifier || ''
    )
    setShowPriceSync(retrivedPlatformData?.platform_settings?.pricing_setting?.isPriceSync || false)
    setPricingValues({
      isPriceSync: retrivedPlatformData?.platform_settings?.pricing_setting?.isPriceSync || false,
      // repricing: {
      //   type: retrivedPlatformData?.platform_settings?.pricing_setting?.repricing?.type || '',
      //   value: retrivedPlatformData?.platform_settings?.pricing_setting?.repricing?.value || '',
      // },
    })
    setOrderValues({
      order_number_map:
        retrivedPlatformData?.platform_settings?.order_setting?.order_number_map ?? '',
      isNotifyUnlinkedOrders:
        retrivedPlatformData?.platform_settings?.order_setting?.isNotifyUnlinkedOrders ?? false,
      isSkipUnpaidOrders:
        retrivedPlatformData?.platform_settings?.order_setting?.isSkipUnpaidOrders ?? false,
      isImportOrderToSource:
        retrivedPlatformData?.platform_settings?.order_setting?.isImportOrderToSource ?? false,
      // isImportUnlinkedOrders:
      //   retrivedPlatformData?.platform_settings?.order_setting?.isImportUnlinkedOrders || false,
      sync_start_date:
        retrivedPlatformData?.platform_settings?.order_setting?.sync_start_date || null,
      isImportCanceledOrder:
        retrivedPlatformData?.platform_settings?.order_setting?.isImportCanceledOrder || false,
      isAcceptOrder: retrivedPlatformData?.platform_settings?.order_setting?.isAcceptOrder || false,
      estimated_delivery_days:
        retrivedPlatformData?.platform_settings?.order_setting?.estimated_delivery_days || null,
      estimated_dispatch_days:
        retrivedPlatformData?.platform_settings?.order_setting?.estimated_dispatch_days || null,
        tags: retrivedPlatformData?.platform_settings?.order_setting?.tags ? retrivedPlatformData?.platform_settings?.order_setting?.tags : ''
    })
    setInventoryValues({
      isInventorySync:
        retrivedPlatformData?.platform_settings?.inventory_setting?.isInventorySync || false,
      buffer: retrivedPlatformData?.platform_settings?.inventory_setting?.buffer || 0,
    })

    setProductValues({
      // isImportProductToSource:
      //   retrivedPlatformData?.platform_settings?.product_setting?.isImportProductToSource || false,
      isImportProductFromSource:
        retrivedPlatformData?.platform_settings?.product_setting?.isImportProductFromSource || false,
        default_quantity: retrivedPlatformData?.platform_settings?.product_setting?.default_quantity ? retrivedPlatformData?.platform_settings?.product_setting?.default_quantity : 0 ,
        export_product:retrivedPlatformData?.platform_settings?.product_setting?.export_product ? retrivedPlatformData?.platform_settings?.product_setting?.export_product : false,
    })

    setShipmentValues({
      isImportShipmentFromMain:
        retrivedPlatformData?.platform_settings?.shipment_setting?.isImportShipmentFromMain ||
        false,
    })
    setShowShipmentSync(
      retrivedPlatformData?.platform_settings?.shipment_setting?.isImportShipmentFromMain ?? false
    )
  }, [retrivedPlatformData])

  useEffect(() => {
    if (shipmentFieldMappings.length) {
      const savedFieldMappings = shipmentFieldMappings[0].fieldmappingdata_set.map((item) => {
        console.log('item_id', item)
        return {
          id: item?.id,
          destination_platform_data_id: item?.destination_platform_data?.id + '',
          source_platform_data_id: item?.source_platform_data?.id + '',
        }
      })
      setShippingMethodMappings(savedFieldMappings)
    }
  }, [shipmentFieldMappings])

  useEffect(() => {
    if (selectedPlatformId) {
      dispatch(retrivePlatformDataAsync({id: selectedPlatformId}))
      dispatch(getFieldMappingAsync({destination_platform_id: selectedPlatformId}))
    }
  }, [selectedPlatformId])

  useEffect(() => {
    if (retrivedPlatformData) {
      setPlatformDisplayName(retrivedPlatformData.platform_info?.display_name || '')
    }
  }, [retrivedPlatformData])

  const createPayload = (): any => {
    return {
      platformId: selectedPlatformId,
      main_product_identifier: main_product_identifier,
      secondary_product_identifier: secondary_product_identifier,
      // pricing_setting: {
      //   isPriceSync: showPriceSync,
      //   repricing: {
      //     type: pricingValues.repricing.type,
      //     value: pricingValues.repricing.value,
      //   },
      // },
      inventory_setting: {
        ...inventoryValues,
      },
      order_setting: {
        ...orderValues, // Include all order settings
      },
      product_setting: {
        ...productValues,
        default_quantity: productValues.default_quantity
          ? Number(productValues.default_quantity)
          : null,
      },
      shipment_setting: {
        isImportShipmentFromMain: showShipmentSync,
      },
    }
  }

  const handleSaveAllSettings = async () => {
    const allSettings = createPayload()
    onSaveSettings(allSettings)
    // toast.success('Platform settings saved successfully !', {
    //   autoClose: 3000, // Set the duration for which the toast will be displayed
    // })
    // try {
    //   await dispatch(createPlatformSettingAsync(allSettings)).then((response) => {
    //     if (response?.payload?.status_code === 200) {
    //       toast.success('Platform settings saved successfully !', {
    //         autoClose: 3000, // Set the duration for which the toast will be displayed
    //       })
    //     }
    //   })
    // } catch (error) {
    //   console.error('Error creating platform settings:', error)
    // }

    // console.log('abhi', connectedPlatformData)
    // const id = connectedPlatformData?.id
    // const display_name = connectedPlatformData?.display_name
    // const isActive = connectedPlatformData?.isActive == false ? true : true
    // const payload = {
    //   display_name,
    //   isActive,
    // }
    // console.log('data', {...payload})
    // await dispatch(isActiveBoolean({...payload, id}))
  }

  const handleSaveShipmentMappings = () => {
    let mapping_for_save = shippingMethodMappings.map((item) => {
      if (item.id) {
        return item
      } else {
        return {
          source_platform_data_id: item?.source_platform_data_id,
          destination_platform_data_id: item?.destination_platform_data_id,
        }
      }
    })

    if (!shipmentData.selectedStoreShipmentData?.id) {
      return warningToast('Destination field is required')
    }

    if (!shipmentData.mainStoreShipmentData?.id) {
      return warningToast('Source field is required')
    }

    dispatch(
      saveFieldMappingAsync({
        source_field_id: shipmentData.mainStoreShipmentData?.id!,
        destination_field_id: shipmentData.selectedStoreShipmentData?.id!,
        fieldmappingdata_set: mapping_for_save,
      })
    ).then(() => {
      dispatch(getFieldMappingAsync({destination_platform_id: selectedPlatformId}))
    })
  }
  const isButtonDisabled =
    main_product_identifier.length === 0 || secondary_product_identifier.length === 0

  return (
    <>
      <Modal fullscreen  show={isVisible} onHide={closeModal}   backdrop= {false} >
        <Modal.Header>
          <Modal.Title>Platform Settings</Modal.Title>
          <div className='d-inline-flex'>
            <Button disabled={loading} size='sm' className='mx-2' onClick={closeModal}>
              Close
            </Button>
            <Button
              size='sm'
              variant='success'
              disabled={isButtonDisabled || loading}
              onClick={handleSaveAllSettings}
            >
              {loading ? 'Saving..' : 'Save Changes'}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {fieldValuesLoader ||
          retrivedPlatformLoading ||
          getPlatformParentFieldCategoryDataLoading ? (
            <div className='d-flex align-items-center justify-content-center h-100'>
              <h1>
                <Spinner animation='border' variant='primary' />
              </h1>
            </div>
          ) : (
            <div>
              <div>
                {/* platform connection section */}
                <Card className='border shadow border-2 border-light-primary mb-4 my-8'>
                  <div className='ribbon ribbon-start'>
                    <div className='ribbon-label bg-primary h5'>Platform Connection</div>
                  </div>
                  <Card.Body className='mt-4 p-4'>
                    <div className=' my-2 row  ms-2 align-items-center'>
                      <p className='h4 mb-0   col-5 col-sm-2'>
                        {retrivedPlatformData?.platform_info?.display_name} Store Friendly Name:
                      </p>
                      <span className=' col-6 col-sm-8'>
                        {' '}
                        <h4 className='mb-0'>
                          {retrivedPlatformData?.platform_info?.display_name}
                        </h4>
                      </span>
                    </div>
                    <div className=' my-2 row  ms-2 align-items-center'>
                      <p className='h4 mb-0 mt-2 col-5 col-sm-2'>Status:</p>
                      <span
                        className={`badge col-5 col-sm-2 ms-2 ms-sm-3 d-flex justify-content-center ${
                          retrivedPlatformData?.isConnected ? 'badge-success' : 'badge-danger'
                        }`}
                        style={{fontSize: '1.3rem', borderRadius: '6px'}}
                      >
                        {retrivedPlatformData?.isConnected ? 'Connected' : 'Not Connected'}
                      </span>
                    </div>
                    {retrivedPlatformData?.platform_info?.name === 'SHOPIFY' && (
                      <div className=' my-2 row ms-2 align-items-center'>
                        <p className='h4 mb-0 col-5 col-sm-2 '>
                          {retrivedPlatformData?.platform_info?.display_name} Url:
                        </p>
                        <span className='col-6 col-sm-8 '>
                          {' '}
                          <h4 className='mb-0'>{retrivedPlatformData?.credentials?.identifier}</h4>
                        </span>
                      </div>
                    )}
                  </Card.Body>
                </Card>

                {/* unique identifier section */}

                <Card className='border shadow border-2 border-light-primary mb-4 my-8'>
                  <div className='ribbon ribbon-start'>
                    <div className='ribbon-label bg-primary h5'>
                      Select a Unique Identifier of Product
                    </div>
                  </div>
                  {/* <div className='border-bottom my-2 border-2'></div> */}
                  <Card.Body className='mt-4 p-4'>
                    <Row className='mb-5'>
                      <Form.Group as={Col}>
                        <Form.Label>Primary Store</Form.Label>
                        <Form.Select
                          className='form-select'
                          id='mainProductIdentifier'
                          value={main_product_identifier}
                          onChange={handleOptionChange1}
                        >
                          <option value=''>Select Unique identifier</option>
                          <option value='SKU'>SKU</option>
                          <option value='UPC'>UPC</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                          This is a required field
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Secondary Store </Form.Label>
                        {retrivedPlatformData?.code === 'THERANGEPLUS' ? (
                          <>
                            <Form.Select
                              className='form-select form-select-sm'
                              id='secondaryProductIdentifier'
                              value={secondary_product_identifier}
                              onChange={handleOptionChange2}
                            >
                              <option value=''>Select Unique identifier</option>
                              <option value='SKU'>Product Reference</option>{' '}
                              <option value='UPC'>UPC</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                              This is a required field
                            </Form.Control.Feedback>
                          </>
                        ) : (
                          <>
                            <Form.Select
                              className='form-select form-select-sm'
                              id='secondaryProductIdentifier'
                              value={secondary_product_identifier}
                              onChange={handleOptionChange2}
                            >
                              <option value=''>Select Unique identifier</option>
                              <option value='SKU'>SKU</option>
                              <option value='UPC'>UPC</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                              This is a required field
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Form.Group>
                    </Row>
                  </Card.Body>
                  {/* <div className='ms-6'>
                    <div className='form-group    col-sm-10   ms-4 m-3'>
                      <div className='row '>
                        <div className='col-6 col-sm-2 '>
                          <label htmlFor='mainProductIdentifier' className='me-2'>
                            Primary Store
                          </label>
                          <div>
                          <select
                            className='form-select form-select-sm'
                            id='mainProductIdentifier'
                            value={main_product_identifier}
                            onChange={handleOptionChange1}
                          >
                            <option value=''>Select Unique identifier</option>
                            <option value='SKU'>SKU</option>
                            <option value='UPC'>UPC</option>
                          </select>
                        </div>
                        </div>
                       

                        <div className='col-6 col-sm-2'>
                          <label htmlFor='secondaryProductIdentifier' className='me-2'>
                            Secondary Store
                          </label>
                          <div>
                          {retrivedPlatformData?.code === 'THERANGEPLUS' ? (
                            <select
                              className='form-select form-select-sm'
                              id='secondaryProductIdentifier'
                              value={secondary_product_identifier}
                              onChange={handleOptionChange2}
                            >
                              <option value=''>Select Unique identifier</option>
                              <option value='SKU'>Product Reference</option>{' '}
                              <option value='UPC'>UPC</option>
                            </select>
                          ) : (
                            <select
                              className='form-select form-select-sm'
                              id='secondaryProductIdentifier'
                              value={secondary_product_identifier}
                              onChange={handleOptionChange2}
                            >
                              <option value=''>Select Unique identifier</option>
                              <option value='SKU'>SKU</option>
                              <option value='UPC'>UPC</option>
                            </select>
                          )}
                        </div>
                        </div>
                
                      </div>
                    </div>
                  </div> */}
                </Card>
              </div>
              {/* order section */}
              <Card className='border shadow  border-2 border-light-primary  mb-4 my-8'>
                <div className='ribbon ribbon-start'>
                  <div className='ribbon-label bg-primary h5'>Orders</div>
                </div>
                <Card.Body>
                  <div className=' m-2 p-4 '>
                    <div className=' d-inline-flex form-check form-switch form-check-custom form-check-solid me-10'>
                      <div className='mb-2'>
                        <label className=' ms-4 form-check-label text-dark h4' htmlFor='orderSync'>
                          Sync Order
                        </label>
                      </div>

                      <input
                        className='ms-8 mb-4 form-check-input h-20px w-40px'
                        type='checkbox'
                        id='orderSync'
                        checked={orderValues.isImportOrderToSource}
                        onChange={(ev) => {
                          setOrderValues((pre) => {
                            return {...pre, isImportOrderToSource: ev.target.checked}
                          })
                        }}
                      />
                    </div>
                    {orderValues.isImportOrderToSource && (
                      <div className='  justify-content-around'>
                        <OrderSyncValues
                          order_number_map={orderValues.order_number_map}
                          isNotifyUnlinkedOrders={orderValues.isNotifyUnlinkedOrders}
                          isSkipUnpaidOrders={orderValues.isSkipUnpaidOrders}
                          sync_start_date={orderValues.sync_start_date}
                          syncCancelOrder={orderValues.isImportCanceledOrder}
                          isAcceptOrder={orderValues.isAcceptOrder}
                          estimated_delivery_days={orderValues.estimated_delivery_days}
                          estimated_dispatch_days={orderValues.estimated_dispatch_days}
                          tags={orderValues.tags}
                          onChangeTags = {
                            (updatedTags)=>{
                              setOrderValues((pre)=>{
                                return {...pre, tags: updatedTags }
                              })
                            }
                          }
                          // isImportOrderToSource={orderValues.isImportOrderToSource}
                          onNotifyUnlinkedOrdersChange={(value) =>
                            setOrderValues((prevValues) => ({
                              ...prevValues,
                              isNotifyUnlinkedOrders: value,
                            }))
                          }
                          onAccept_OrderChange={(value) =>
                            setOrderValues((prevValues) => ({
                              ...prevValues,
                              isAcceptOrder: value,
                            }))
                          }
                          onEstimated_delivery_days={(value) =>
                            setOrderValues((prevValues) => ({
                              ...prevValues,
                              estimated_delivery_days: value,
                            }))
                          }
                          onEstimated_dispatch_days={(value) =>
                            setOrderValues((prevValues) => ({
                              ...prevValues,
                              estimated_dispatch_days: value,
                            }))
                          }
                          onSkipUnpaidOrdersChange={(value) =>
                            setOrderValues((prevValues) => ({
                              ...prevValues,
                              isSkipUnpaidOrders: value,
                            }))
                          }
                          onOrder_number_mapChange={(value) =>
                            setOrderValues((prevValues) => ({
                              ...prevValues,
                              order_number_map: value,
                            }))
                          }
                          onSyncStartDateChange={(value: Date | string | null) =>
                            setOrderValues((prevValues: typeof orderValues) => ({
                              ...prevValues,
                              sync_start_date: value,
                            }))
                          }
                          onSyncCancelOrder={(value) =>
                            setOrderValues((prevValues) => ({
                              ...prevValues,
                              isImportCanceledOrder: value,
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>

              {/* shipment section */}
              <Card className='border shadow  border-2 border-light-info  mb-4 my-8'>
                <div className='ribbon ribbon-start'>
                  <div className='ribbon-label bg-primary h5'>Shipment</div>
                </div>
                <Card.Body>
                  <div className=' m-2 p-4 '>
                    <div className=' d-inline-flex form-check form-switch form-check-custom form-check-solid me-10'>
                      <div className='mb-4'>
                        <label className='form-check-label text-dark h4' htmlFor='orderSync'>
                          Sync Shipment
                        </label>
                      </div>
                      <input
                        className='ms-8 mb-4 form-check-input h-20px w-40px'
                        type='checkbox'
                        id='shipmentSync'
                        checked={showShipmentSync}
                        onChange={() => {
                          setShowShipmentSync(!showShipmentSync)
                        }}
                      />
                    </div>
                    {showShipmentSync && (
                      <div className='border p-4 rounded'>
                        {/* <ShipmentSyncValues
                          shippingMethodMappings={shippingMethodMappings}
                          mainStoreShipmentData={
                            shipmentMappingFieldData.shipmentMappingFieldDataForMainStore
                          }
                          selectedStoreShipmentData={
                            shipmentMappingFieldData.shipmentMappingFieldDataForSelectedStore
                          }
                          addNewItem={() => {
                            setShippingMethodMappings((pre) => {
                              return [
                                ...pre,
                                {
                                  id: null,
                                  source_platform_data_id: '',
                                  destination_platform_data_id: '',
                                },
                              ]
                            })
                          }}
                          setShipment={(item, itemIndex) => {
                            let mappings = [...shippingMethodMappings]
                            if (mappings.length) {
                              let cure = mappings.find((it, inn) => {
                                if (inn == itemIndex) {
                                  return it
                                }
                              })
                              if (cure) {
                                cure = item
                              }

                              mappings[itemIndex] = cure!
                              setShippingMethodMappings(mappings)
                            }
                          }}
                          deleteMappingItem={(id, index) => {
                            let copyMppings = [...shippingMethodMappings]
                            if (id) {
                              dispatch(deleteFieldMappingAsync({mappingDataId: id})).then(
                                (response) => {
                                  dispatch(
                                    getFieldMappingAsync({
                                      destination_platform_id: selectedPlatformId,
                                    })
                                  )
                                }
                              )
                            } else {
                              let filteredMappings = copyMppings.filter((it, ind) => {
                                if (ind !== index) {
                                  return it
                                }
                              })
                              setShippingMethodMappings(filteredMappings)
                            }
                          }}
                        /> */}
                        <div className='d-flex justify-content-end'>
                          <button
                            className='btn btn-success btn-sm mt-2 align-self-end'
                            onClick={() => handleSaveShipmentMappings()}
                          >
                            Save Shipment
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>

              {/* inventory section */}
              <Card className='border shadow  border-2 border-light-info  mb-4 my-8'>
                <div className='ribbon ribbon-start'>
                  <div className='ribbon-label bg-primary h5'>Inventory</div>
                </div>
                <Card.Body>
                  <div className=' m-2 p-4 '>
                    <div className='d-inline-flex  form-check form-switch form-check-custom form-check-solid me-10'>
                      <div className='mb-4'>
                        <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
                          Sync Inventory
                        </label>
                      </div>

                      <input
                        className='ms-8 mb-4 form-check-input h-20px w-40px'
                        type='checkbox'
                        id='inventorySync'
                        checked={inventoryValues.isInventorySync}
                        onChange={(e) =>
                          setInventoryValues({
                            ...inventoryValues,
                            isInventorySync: e.target.checked,
                          })
                        }
                      />
                    </div>
                    {inventoryValues.isInventorySync ? (
                      <div>
                        <div className='d-flex  form-check justify-content-start ms-7 mt-2'>
                          <label className=' h5 mt-2 form-check-label' htmlFor='inventorySync'>
                            Set Safety Stock Level
                          </label>
                          <input
                            className='ms-8 mb-4 form-check-input h-30px w-100px'
                            type='number'
                            id='inventorySync'
                            value={inventoryValues.buffer}
                            onChange={handleInputChange}
                            style={{textAlign: 'center'}}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Card.Body>
              </Card>

              {/* <div className='card m-2 p-4 shadow'>
                <div>
                  <p className='h3'>Inventory</p>
                </div>
                <div className='border-bottom my-2 border-2'></div>
                <div className=' m-2 p-4 '>
                  <div className='d-inline-flex  form-check form-switch form-check-custom form-check-solid me-10'>
                    <div className='mb-4'>
                      <label className='form-check-label text-dark h4' htmlFor='inventorySync'>
                        Sync Inventory
                      </label>
                    </div>

                    <input
                      className='ms-8 mb-4 form-check-input h-20px w-40px'
                      type='checkbox'
                      id='inventorySync'
                      checked={inventoryValues.isInventorySync}
                      onChange={(e) =>
                        setInventoryValues({
                          ...inventoryValues,
                          isInventorySync: e.target.checked,
                        })
                      }
                    />
                  </div>
                  {inventoryValues.isInventorySync ? (
                    <div>
                      <div className='d-flex  form-check justify-content-start ms-12 mt-2'>
                        <label className=' h5 mt-2 form-check-label' htmlFor='inventorySync'>
                          Set Safety Stock Level
                        </label>
                        <input
                          className='ms-8 mb-4 form-check-input h-30px w-100px'
                          type='number'
                          id='inventorySync'
                          value={inventoryValues.buffer}
                          onChange={handleInputChange}
                          style={{textAlign: 'center'}}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div> */}
              {process.env.REACT_APP_ENV === 'staging' && (
                <Card className='border shadow  border-2 border-light-info  mb-4 my-8'>
                  <div className='ribbon ribbon-start'>
                    <div className='ribbon-label bg-primary h5'>Product</div>
                  </div>
                  <Card.Body>
                  <div className='m-2 p-4'>
                   <div className='d-inline-flex form-check form-switch form-check-custom form-check-solid me-10'>
                     <div className='mb-4'>
                       <label className='form-check-label text-dark h4' htmlFor='productsync'>
                         Sync Product
                       </label>
                     </div>
                     <input
                       className='ms-8 mb-4 form-check-input h-20px w-40px'
                       type='checkbox'
                       name='isImportProductFromSource'
                       checked={productValues.isImportProductFromSource}
                       onChange={(e) =>
                         setProductValues({
                           ...productValues,
                           isImportProductFromSource: e.target.checked,
                         })
                       }
                     />
                   </div>
                   {productValues.isImportProductFromSource && (
                     <div>
                       <div className='d-flex justify-content-start mt-2 ms-12'>
                         <label className='h5 mt-2 form-label' htmlFor='stocklevel'>
                           Set a Default Stock level for Non-inventory Products
                         </label>
                         <input
                           className='ms-2 mb-4 form-control h-30px w-100px'
                           type='number'
                           id='stocklevel'
                           value={productValues.default_quantity}
                           onChange={(ev) => {
                             if (Number(ev.target.value) >= 0) {
                               setProductValues((prev) => ({
                                 ...prev,
                                 default_quantity: Number(ev.target.value),
                               }));
                             }
                           }}
                           style={{ textAlign: 'center' }}
                         />
                       </div>
                       {retrivedPlatformData?.code === "THERANGEPLUS" && (
                         <div className='d-flex justify-content-start  form-check ps-0 form-switch form-check-custom form-check-solid  mt-2 ms-12'>
                           <label className='h5 mt-2 form-label' htmlFor='export_product'>
                             Export Product
                           </label>
                           <input
                              className='ms-8 form-check-input h-20px w-40px'
                             type='checkbox'
                             id='export_product'
                             checked={productValues.export_product}
                             onChange={(ev) => {
                               setProductValues((prev) => ({
                                 ...prev,
                                 export_product: ev.target.checked,
                               }));
                             }}
                           />
                         </div>
                       )}
                     </div>
                   )}
                 </div>
                  </Card.Body>
                </Card>
              )}

              {/* <div className='card m-2 p-4 shadow'>
                <div>
                  <p className='h3'>Product</p>
                </div>
                <div className='border-bottom my-2 border-2'></div>
                <div className='card m-2 p-4 shadow'>
                  <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                    <label className='form-check-label text-dark h4' htmlFor='productSync'>
                      Sync Product
                    </label>
                  </div>
                  <div>
                    <ProductSyncValues
                      isImportProductToSource={productValues.isImportProductToSource}
                      onImportProductToSourceChange={(value) =>
                        setProductValues((prevValues) => ({
                          ...prevValues,
                          isImportProductToSource: value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConnectedPlatformSettingModal
