import {faInfoCircle, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {time} from 'console'
import React, {Fragment, useEffect, useState} from 'react'
import {Button, OverlayTrigger, Stack, Tooltip} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import useAppToast from 'src/hooks/useAppToast'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

type OrderSyncValuesProps = {
  isNotifyUnlinkedOrders: boolean
  isSkipUnpaidOrders: boolean
  // isImportOrderToSource: boolean
  sync_start_date: Date | null | string
  order_number_map: string
  syncCancelOrder: boolean
  isAcceptOrder: boolean
  estimated_delivery_days: null | number
  estimated_dispatch_days: null | number
  tags:string
  onOrder_number_mapChange: (value: string) => void
  onNotifyUnlinkedOrdersChange: (value: boolean) => void
  onSkipUnpaidOrdersChange: (value: boolean) => void
  onSyncStartDateChange: (value: Date | null | string) => void
  onSyncCancelOrder: (value: boolean) => void
  onAccept_OrderChange: (value: boolean) => void
  onEstimated_delivery_days: (value: null | number) => void
  onEstimated_dispatch_days: (value: null | number) => void
  selectedPlatformId?: number | null
  onChangeTags:(val:string)=>void
  // onImportOrderToSourceChange: (value: boolean) => void
}


const OrderSyncValues: React.FC<OrderSyncValuesProps> = ({
  isNotifyUnlinkedOrders,
  isSkipUnpaidOrders,
  // isImportOrderToSource,
  sync_start_date,
  order_number_map,
  syncCancelOrder,
  isAcceptOrder,
  estimated_delivery_days,
  estimated_dispatch_days,
  onEstimated_delivery_days,
  onEstimated_dispatch_days,

  onOrder_number_mapChange,
  onNotifyUnlinkedOrdersChange,
  onSkipUnpaidOrdersChange,
  onSyncStartDateChange,
  onSyncCancelOrder,
  onAccept_OrderChange,
  selectedPlatformId,
  tags,
  onChangeTags
  // onImportOrderToSourceChange,
}) => {
  const [isShortCodeVisible, setShortCodeVisible] = useState(false)
  const [copyMarkup, setCopyMarkup] = useState<boolean>(false)
  const [buttonString, setButtonString] = useState('')
  const [shortCode, setShortCode] = useState(['[ORDER_ID]', '[ORDER_NUMBER]'])
  const {retrivedPlatformData} = useAppSelector((state) => state.retrivePlatformData)
  const { errorToast, successToast,warningToast } = useAppToast()
  const dispatch = useAppDispatch()
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [dispatchDays, setDispatchDays] = useState('')
  const [deliveryDays, setDeliveryDays] = useState('')
  


   

   

  const handleCopyMarkup = (data: string) => {
    setCopyMarkup(true)
    setButtonString(data)

    setTimeout(() => {
      setCopyMarkup(false)
      setButtonString('')
    }, 1000)
  }

  const renderTooltip = (shortcode: string) => {
    let tooltipText = ''
    switch (shortcode) {
      case '[ORDER_ID]':
        tooltipText = ` ${mainStoreData?.code} Auto Generated`
        break
      case '[ORDER_NUMBER]':
        tooltipText = `Order Number from ${retrivedPlatformData?.code}`
        break
      default:
        tooltipText = ''
    }
    return <Tooltip id={`${shortcode}-tooltip`}>{tooltipText}</Tooltip>
  }

  const renderTooltipForAcceptOrder = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      System will count from Order date
    </Tooltip>
  )

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = e.target.value
    console.log('datevalue :', dateValue)
    // Create a Date object with the local time zone
    const localDate = new Date(dateValue)
    // Convert the local date to UTC format
    const utcDate = new Date(
      localDate.getTime() - localDate.getTimezoneOffset() * 60000
    ).toISOString()
    onSyncStartDateChange(utcDate) // Pass the UTC date to the handler
  }

  // Initial state is null
  console.log('retrivedPlatformData-', retrivedPlatformData)

  return (
    <Fragment>
      <div className=' col-md-12  '>
        <div className=' m-2'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-8 col-sm-4'>
                <div className='d-flex justify-content-between form-check ps-4 form-switch form-check-custom form-check-solid mb-2'>
                  <label htmlFor='notifyUnlinkedOrders' className='fs-6'>
                    Notify on Unlinked Items or any Failed Orders
                  </label>
                  <input
                    type='checkbox'
                    className=' form-check-input h-20px w-40px ms-8'
                    id='notifyUnlinkedOrders'
                    checked={isNotifyUnlinkedOrders}
                    onChange={(e) => onNotifyUnlinkedOrdersChange(e.target.checked)}
                  />
                </div>
              </div>
              <div className='col-8 col-sm-4'>
                <div className='d-flex justify-content-between  form-check ps-4 form-switch form-check-custom form-check-solid mb-2'>
                  <label htmlFor='skipUnpaidOrders' className='fs-6'>
                    Skip the Sync of Unpaid Orders
                  </label>
                  <input
                    type='checkbox'
                    className='ms-8 form-check-input h-20px w-40px'
                    id='skipUnpaidOrders'
                    checked={isSkipUnpaidOrders}
                    onChange={(e) => onSkipUnpaidOrdersChange(e.target.checked)}
                  />
                </div>
              </div>
            </div>
            {/* <div className='row'>
            <div className='col-5 col-sm-3'>
              
       
         
          </div>
            </div> */}

            <div className='row'>
              <div className='col-4 mt-3 d-flex flex-column '>
                <div className='ps-2'>
                  <label htmlFor='order_number_map' className='fs-6 mb-2'>
                    Order Number Preference
                  </label>
                  <input
                    type='text'
                    className='form-control  w-30 w-sm-50'
                    value={order_number_map}
                    style={{border: '1px solid #ced4da'}}
                    placeholder='e.g. PREFIX-[ORDER_NUMBER]-SUFFIX'
                    onChange={(e) => onOrder_number_mapChange(e.target.value)}
                  />
                </div>
                <div className='text-end w-md-100 w-50'>
                  <p
                    className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                    onClick={() => setShortCodeVisible(!isShortCodeVisible)} // Pass input identifier to handleShortCode}
                  >
                    <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
                  </p>
                  {isShortCodeVisible && (
                    <div>
                      {shortCode?.length &&
                        shortCode.map((ele: string) => (
                          <Fragment key={ele}>
                            <OverlayTrigger placement='bottom' overlay={renderTooltip(ele)}>
                              <CopyToClipboard text={ele}>
                                <Button
                                  key={ele}
                                  size='sm'
                                  variant={
                                    copyMarkup && buttonString === ele
                                      ? 'outline-success'
                                      : 'outline-primary'
                                  }
                                  className={`px-3 py-2 m-1 border border-1 ${
                                    copyMarkup && buttonString === ele
                                      ? 'border-success'
                                      : 'border-primary'
                                  } rounded text-hover-dark fs-sm-8 inline-block`}
                                  onClick={() => handleCopyMarkup(ele)}
                                >
                                  {copyMarkup && buttonString === ele ? (
                                    <span className='fs-sm-8'>Copied !</span>
                                  ) : (
                                    ele
                                  )}
                                </Button>
                              </CopyToClipboard>
                            </OverlayTrigger>
                          </Fragment>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              {
                    mainStoreData?.name==='SHOPIFY' ? 
                    <div className="col-6 mt-3">
                        <div className='row' >
                            <label className='mb-2' htmlFor="tags">Tags &nbsp;&nbsp;
                              <OverlayTrigger overlay={<Tooltip>Use comma separate values</Tooltip>} >
                                  <i className="bi bi-info-circle"></i> 
                                </OverlayTrigger> 
                            </label>
                            <div className="col-12 col-sm-6">
                              <input
                              className='form-control mw-350px'
                              value={tags}
                              onChange={(ev)=>{
                                // let updatedTags  = ev.target.value.split(' ').join(',')
                                
                                // Step 1: Get the input value
                                let inputValue = ev.target.value;

                                // Step 2: Replace multiple spaces with a single space
                                // inputValue = inputValue.replace(/\s+/g, ' ');

                                // Step 3: Replace spaces with commas
                                inputValue = inputValue.replace(/ /g, ',');

                                // Step 4: Replace multiple commas with a single comma
                                inputValue = inputValue.replace(/,+/g, ',');

                                // Step 5: Remove any leading or trailing commas
                                // inputValue = inputValue.replace(/^,|,$/g, '');

                                // Update the tags state with the cleaned value
                                onChangeTags(inputValue);
                                                            
                              }}
                              onBlur={()=>{
                                if(tags.endsWith(',')){
                                  let updatedTags = tags.slice(0, -1); // Remove the trailing comma
                                  onChangeTags(updatedTags);   
                                }
                              }}
                              />
                            </div>
                        </div>
                    </div>
                    : null
                  }
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='form-check form-switch    ms-3 mb-2 d-flex flex-column col-md-12'></div>
        <div className='order_live_date col-md-12'>
          <div className='card m-2 mt-2 p-4  '>
            <div className='mb-2'>
              <span
                className='text-dark h4'
                // style={{fontSize: '1.3rem', color: ' black', fontWeight: 'bold'}}
              >
                Choose a starting point of Order (in UTC)
              </span>
            </div>
            <div className='border-bottom my-2 border-2'></div>

            <div className=' my-2 d-flex flex-row   align-items-center'>
              <div className=' d-flex   col-md-12 flex-column mt-2 ps-4 mb-2'>
                <div className='d-flex mb-2'>
                  <label htmlFor='goLiveDate' className='me-2'>
                    Date Time For Sync
                  </label>
                </div>
                <div>
                  <input
                    type='datetime-local'
                    className='form-control  w-50 w-sm-30 '
                    id='goLiveDate'
                    value={
                      sync_start_date ? new Date(sync_start_date).toISOString().slice(0, 16) : ''
                    }
                    // style={{width: '100%'}} // Inline style for full width
                    // Add state variable and handler to manage the go-live date

                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='form-check form-switch    ms-3 mb-2 d-flex flex-column col-md-12'></div>
        {['NOTONTHEHIGHSTREET', 'CATCH', 'DEBENHAMS', 'SUPERPHARM'].includes(retrivedPlatformData?.code ?? '') ? (
          <div className='col-md-12'>
            <div className='card m-2  '>
              <div className='card-body'>
                <div className='row'>
                  <div className=' col-6 d-inline-flex form-check ps-2 form-switch form-check-custom form-check-solid mb-2'>
                    <span className=' col-12 col-sm-7  text-dark h4'>
                      Accept Order in {retrivedPlatformData?.code}
                    </span>
                    <input
                      type='checkbox'
                      className=' col-12 form-check-input h-20px w-40px ms-8 ms-sm-0'
                      id='accept_order'
                      checked={isAcceptOrder}
                      onChange={(e) => onAccept_OrderChange(e.target.checked)}
                    />
                  </div>
                  {/* <div className='border-bottom my-2 border-2'></div> */}
                </div>

                {isAcceptOrder && (
                  <div className='container ms-0'>
                    <div className='row mt-2'>
                     { retrivedPlatformData?.code!=='CATCH' ?
                     <>
                     <div className='col-4 col-sm-4'>
                        <label className='form-label mt-1'>
                          Order Dispatch Days&nbsp;
                          <OverlayTrigger placement='right' overlay={renderTooltipForAcceptOrder}>
                            <i className='bi bi-info-circle'></i>
                          </OverlayTrigger>
                        </label>
                        <input
                          type='number'
                          className='form-control'
                          id='dispatch_days'
                          value={estimated_dispatch_days ?? ''}
                          onChange={(e) =>
                            onEstimated_dispatch_days(
                              e.target.value ? parseInt(e.target.value) : null
                            )
                          }
                        />
                      </div>
                      <div className='col-4 col-sm-4 ms-2'>
                        <label className='form-label mt-1'>
                          Order Delivery Days&nbsp;
                          <OverlayTrigger placement='right' overlay={renderTooltipForAcceptOrder}>
                            <i className='bi bi-info-circle'></i>
                          </OverlayTrigger>
                        </label>
                        <input
                          type='number'
                          className='form-control'
                          id='delivery_days'
                          value={estimated_delivery_days ?? ''}
                          onChange={(e) =>
                            onEstimated_delivery_days(
                              e.target.value ? parseInt(e.target.value) : null
                            )
                          }
                        />
                      </div>
                     </>: null }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {retrivedPlatformData?.platform_info?.name === 'ICONIC' && (
          <div className='d-inline-flex form-check form-switch form-check-custom form-check-solid me-10 mt-4'>
            <label htmlFor='syncOrderCancel' className=' form-check-label text-dark h4'>
              Sync cancelled orders
            </label>
            <input
              type='checkbox'
              className='ms-8 form-check-input h-20px w-40px'
              id='syncOrderCancel'
              checked={syncCancelOrder}
              onChange={(e) => onSyncCancelOrder(e.target.checked)}
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default OrderSyncValues
