// import {createSlice, isAnyOf} from '@reduxjs/toolkit'
// import {PlatformDataType} from 'src/interfaces/Platform.interface'
// import {fetchConnectedPlatformAsync} from '../actions/platformActions'

// type fetchConnectedPlatformPayoad = {
//   fetchedConnectedPlatformData: PlatformDataType[] | []
//   fetchedConnectedPlatformLoading: boolean
// }

// const initialState: fetchConnectedPlatformPayoad = {
//   fetchedConnectedPlatformData: [],
//   fetchedConnectedPlatformLoading: false,
// }

// const fetchConnectedPlatformSlice = createSlice({
//   name: 'fetchedConnectedPlatformSlice',
//   initialState,
//   extraReducers: (builder) => {
//     builder.addMatcher(isAnyOf(fetchConnectedPlatformAsync.pending), (state, action) => {
//       state.fetchedConnectedPlatformLoading = true
//     })
//     builder.addMatcher(isAnyOf(fetchConnectedPlatformAsync.fulfilled), (state, action) => {
//       state.fetchedConnectedPlatformLoading = false
//       state.fetchedConnectedPlatformData = action?.payload?.data
//     })
//     builder.addMatcher(isAnyOf(fetchConnectedPlatformAsync.rejected), (state) => {
//       state.fetchedConnectedPlatformLoading = true
//     })
//   },
//   reducers: {
//     setFetchedConnectedPlatformData: (state, action: {payload: fetchConnectedPlatformPayoad; type: string}) => {
//       state.fetchedConnectedPlatformLoading = action.payload?.fetchedConnectedPlatformLoading
//       state.fetchedConnectedPlatformData = action?.payload.fetchedConnectedPlatformData
//     },
//   },
// });

// export const {setFetchedConnectedPlatformData} = fetchConnectedPlatformSlice.actions
// export default fetchConnectedPlatformSlice.reducer;
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { PlatformDataType } from 'src/interfaces/Platform.interface';
import { fetchConnectedPlatformAsync, deletePlatformDataAsync } from '../actions/platformActions';

type FetchConnectedPlatformPayload = {
  fetchedConnectedPlatformData: PlatformDataType[] | [];
  fetchedConnectedPlatformLoading: boolean;
  mainStoreData:null|PlatformDataType
};

const initialState: FetchConnectedPlatformPayload = {
  fetchedConnectedPlatformData: [],
  fetchedConnectedPlatformLoading: true,
  mainStoreData:null
};

const fetchConnectedPlatformSlice = createSlice({
  name: 'fetchedConnectedPlatformSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnectedPlatformAsync.pending, (state, action) => {
        state.fetchedConnectedPlatformLoading = true;
      })
      .addCase(fetchConnectedPlatformAsync.fulfilled, (state, action) => {
        state.fetchedConnectedPlatformLoading = false;
        if(action.payload?.data){
          state.fetchedConnectedPlatformData = action.payload?.data;

          if(action.payload?.data?.length){
            const mainSD =  action.payload?.data?.find((item)=>{
                if(item?.isMainStore){
                  return item
                }
            })
            state.mainStoreData = mainSD
          }

        }
      
      })
      .addCase(fetchConnectedPlatformAsync.rejected, (state) => {
        state.fetchedConnectedPlatformLoading = false;
      })
      .addCase(deletePlatformDataAsync.pending, (state, action) => {
        // Since we're deleting, we don't necessarily need to set fetching to true here
      })
      .addCase(deletePlatformDataAsync.fulfilled, (state, action) => {
        // Check if action.payload exists and has the deletedPlatformId property
        if (action.payload && action.payload.deletedPlatformId) {
          state.fetchedConnectedPlatformData = state.fetchedConnectedPlatformData.filter(
            (platform) => platform.id !== action.payload.deletedPlatformId
          );
        }
      })
      .addCase(deletePlatformDataAsync.rejected, (state) => {
        // Handle any errors or set loading to false if necessary
      });
  },
  reducers: {
    setFetchedConnectedPlatformData: (
      state,
      action: { payload: FetchConnectedPlatformPayload; type: string }
    ) => {
      state.fetchedConnectedPlatformLoading = action.payload.fetchedConnectedPlatformLoading;
      
      state.fetchedConnectedPlatformData = action.payload.fetchedConnectedPlatformData;
    },
  },
});

export const { setFetchedConnectedPlatformData } = fetchConnectedPlatformSlice.actions;
export default fetchConnectedPlatformSlice.reducer;
