/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {accessSessionTokenKeyName, loggedInUserDataKeyName, } from 'src/utils/constants'
import {setLoggingData} from 'src/redux/slices/authSlice'
import VerifyUser from '../modules/auth/components/VerifyUser'
import VerifyForgetPassword from '../modules/auth/components/VerifyForgetPassword'
import HandleOauth from '../pages/HandleOauth'
import HandleShopifyAppInstallation from '../pages/HandleShopifyAppInstallation'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const {isLoggedIn} = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  useEffect(() => {
    console.log("Cookie data",  );
    
    const token = localStorage.getItem(accessSessionTokenKeyName)
    const loggedInUser = localStorage.getItem(loggedInUserDataKeyName)
    if (token) {
      dispatch(setLoggingData({isLoggedIn: true, userData: null}))
    }
    return () => {
      dispatch(setLoggingData({isLoggedIn: false, userData: null}))
    }
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isLoggedIn ? (
            <>
              <Route path='handle-app-installation/*' element={<HandleShopifyAppInstallation />} />
              <Route path='handle-oauth-callback-restricted/*' element={<HandleOauth />} />
              <Route path='/*' element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to='/dashboard' />} /> */}
            </>
          ) : (
            <>
              <Route path='handle-app-installation/*' element={<HandleShopifyAppInstallation />} />
              <Route path='handle-oauth-callback-restricted/*' element={<HandleOauth />} />
              <Route path='verify-user-registration/:activation_token' element={<VerifyUser />} />
              <Route path='reset-password/:forgot_password_token' element={<VerifyForgetPassword />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
