import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import {getOrdersListAsync} from 'src/redux/actions/orderActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

const ColProductName: FC<{
  name: string
}> = ({name}) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='ms-2'>
          <p className='mb-0'>
            <Button
              variant='link'
              className=' fw-bold fs-5 link-underline-info py-0 text-success text-decoration-underline mb-0'
            >
              {name}
            </Button>
          </p>
          {/* <p className='h6 text-muted'>{name.subtitle}</p> */}
        </div>
      </div>
    </>
  )
}

const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <>
      <p>{rowData}</p>
    </>
  )
}

const Inventory: FC = () => {
  const columns = [
    {
      name: 'Product',
      cell: (row) => <ColProductName name={row.product} />,
      sortable: true,
    },
    {
      name: 'SKU',
      cell: (row) => <CommonColumn rowData={row.sku} />,
      sortable: true,
    },
    {
      name: 'Adjustment',
      cell: (row) => <CommonColumn rowData={row.adjustment} />,
      sortable: true,
      right: true,
      center: true,
    },
    {
      name: 'Current Quantity',
      cell: (row) => <CommonColumn rowData={row.current_quantity} />,
      sortable: true,
      center: true,
    },
    {
      name: 'Updated At',
      cell: (row) => (
        <CommonColumn rowData={moment.utc(new Date(row.updated_at)).format('hh:mm:A, DD-MMM-YY')} />
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Sync Status',
      cell: (row) => (
        <CommonColumn rowData={row.sync_status} />
      ),
      sortable: true,
      center: true,
    },
  ]

  const dummyData = [
    {
      product: 'Sample Product 1',
      sku: 'sample1',
      adjustment: 'No adjustment',
      current_quantity: 10,
      updated_at: '2024-03-06',
      sync_status: 'Synced',
    },
    {
      product: 'Sample Product 2',
      sku: 'sample2',
      adjustment: 'Increase',
      current_quantity: 20,
      updated_at: '2024-03-06',
      sync_status: 'Pending',
    },
    {
      product: 'Sample Product 3',
      sku: 'sample3',
      adjustment: 'Decrease',
      current_quantity: 15,
      updated_at: '2024-03-06',
      sync_status: 'Synced',
    },
    {
      product: 'Sample Product 4',
      sku: 'sample4',
      adjustment: 'Increase',
      current_quantity: 30,
      updated_at: '2024-03-06',
      sync_status: 'Pending',
    },
    {
      product: 'Sample Product 5',
      sku: 'sample5',
      adjustment: 'Decrease',
      current_quantity: 25,
      updated_at: '2024-03-06',
      sync_status: 'Synced',
    },
    {
      product: 'Sample Product 6',
      sku: 'sample6',
      adjustment: 'No adjustment',
      current_quantity: 10,
      updated_at: '2024-03-06',
      sync_status: 'Pending',
    },
    {
      product: 'Sample Product 7',
      sku: 'sample7',
      adjustment: 'Increase',
      current_quantity: 35,
      updated_at: '2024-03-06',
      sync_status: 'Synced',
    },
    {
      product: 'Sample Product 8',
      sku: 'sample8',
      adjustment: 'Decrease',
      current_quantity: 18,
      updated_at: '2024-03-06',
      sync_status: 'Pending',
    },
    {
      product: 'Sample Product 9',
      sku: 'sample9',
      adjustment: 'No adjustment',
      current_quantity: 22,
      updated_at: '2024-03-06',
      sync_status: 'Synced',
    },
    {
      product: 'Sample Product 10',
      sku: 'sample10',
      adjustment: 'Increase',
      current_quantity: 28,
      updated_at: '2024-03-06',
      sync_status: 'Pending',
    },
  ]

  return (
    <>
      <div className='d-flex justify-content-end'>
        <input
          className='form-control mr-sm-2 w-25 input-sm'
          type='search'
          placeholder='Search'
          aria-label='Search'
        />
      </div>
      <DataTable
        title='Inventory Adjustment'
        fixedHeader={true}
        data={dummyData}
        columns={columns}
        selectableRows
        paginationTotalRows={20}
        pagination
        paginationServer
        customStyles={{
          table: {
            style: {
              height: '60vh',
            },
          },
          headCells: {
            style: {fontWeight: 'bold', fontSize: '14px'},
          },
          cells: {
            style: {
              padding: '10px',
              borderBottom: '1px solid gainsboro',
            },
          },
        }}
      />
    </>
  )
}

export default Inventory
