import {Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FIELD_MAPPING_TYPE, RetrievePlatformDataType, SETTINGS_OF_PLATFORM} from 'src/interfaces/Platform.interface'
import {getSettingsOfPrimaryPlatformAsync, getSettingsOfSelectedPlatformAsync} from 'src/redux/actions/platformSettingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import InfoTooltip from './components/InfoTooltip'
import CustomInput from './components/CustomInput'
import ShortCodeInput from './components/ShortCodeInput'
import {
  createPlatformSettingAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import {getFieldMappingAsync} from 'src/redux/actions/productActions'
import {useParams} from 'react-router-dom'
import {PlatformSettingDataTypesValues} from 'src/app/_interfaces/setting.interface'
import DynamicChildSetting from './components/DynamicChildSetting'
import useAppToast from 'src/hooks/useAppToast'
import {isActiveBoolean} from 'src/redux/actions/changeisActiveAction'
import MappingInput from './components/MappingInput'
import MappingInputModified from './components/MappingInputModiefied'

type GroupedSetting = {group_code: string; fields: SETTINGS_OF_PLATFORM[] | []}

const DynamicPlatformSetting: FC = () => {
  //possible groupcode ORDER, PRODUCT, INVENTORY, SHIPMENT, PRICING
  const {selectedPlatformId, availablePlatformId} = useParams()
  const dispatch = useAppDispatch()
  const {errorToast, successToast, warningToast} = useAppToast()
  const {loadPlatformSetting, settings} = useAppSelector((state) => state.platformSetting)
  const [platformSettings, setPlatformSettings] = useState<SETTINGS_OF_PLATFORM[] | []>([])
  const [primaryPlatformSettings, setPrimaryPlatformSettings] = useState<SETTINGS_OF_PLATFORM[] | []>([])
  const [formFields, setFormFields] = useState<any>({
    main_product_identifier: '',
    secondary_product_identifier: '',
  })
  const [loadingFormFields, setLoadingFormFields] = useState(true)
  const [loadInitialKeyValues, setLoadInitialKeyValues] = useState<boolean>(true)
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [retrivedPlatformData, setRetrivedPlatformData] = useState<RetrievePlatformDataType | null>(
    null
  )
  const {retrivedPlatformLoading} = useAppSelector((state) => state.retrivePlatformData)
    const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)

  // Grouping function
  function groupByGroupCode(fields) {
    // Use a temporary object to group fields by group_code
    const tempGrouped = {}

    fields.forEach((field) => {
      const {group_code} = field

      // Initialize group if it doesn't exist
      if (!tempGrouped[group_code]) {
        tempGrouped[group_code] = []
      }

      // Add the field to the appropriate group
      tempGrouped[group_code].push(field)
    })

    // Convert the grouped object into an array
    const groupedArray = Object.keys(tempGrouped).map((group_code) => ({
      group_code,
      fields: tempGrouped[group_code],
    }))

    return groupedArray
  }

  useEffect(() => {
    console.log(" mainStoreData?.platform_info?.id--", mainStoreData?.platform_info?.id)
    if (availablePlatformId && selectedPlatformId  && mainStoreData?.platform_info?.id) {
      Promise.all([
        dispatch(getSettingsOfSelectedPlatformAsync({availablePlatformId})).then((response) => {
          if (response.payload?.data?.length) {
            setPlatformSettings(response.payload?.data)
          } else {
            setPlatformSettings([])
            setLoadInitialKeyValues(false)
            setLoadingFormFields(false)
          }
        }),
        dispatch(getSettingsOfPrimaryPlatformAsync({ primaryAvailablePlatformId: mainStoreData?.platform_info?.id })).then((response) => {
          console.log("setPrimaryPlatformSettings", response)
          if (response.payload?.data?.length) {
            setPrimaryPlatformSettings(response.payload.data)
          } else {
            setPrimaryPlatformSettings([])
          }
        }),
        dispatch(retrivePlatformDataAsync({id: selectedPlatformId})).then((resp) => {
          setRetrivedPlatformData(resp.payload)
        }),
      ])
    }
  }, [availablePlatformId, selectedPlatformId,mainStoreData?.platform_info?.id])
 

  const generateInitialKeyFromoChildrenForPlatformSetting = (fields: SETTINGS_OF_PLATFORM[]) => {
    const initialValues = {}

    fields.forEach((field) => {
      if (field.input_type === 'BOOLEAN') {
        initialValues[field.name] = false
      } else if (field.input_type === 'TEXT' || field.input_type === 'DATETIME') {
        initialValues[field.name] = ''
      } else if (field.input_type === 'INTEGER') {
        initialValues[field.name] = 0
      } else {
        initialValues[field.name] = '' // Default to empty string for other types
      }

      // Recursively handle children
      if (field.children && field.children.length > 0) {
        const childValues = generateInitialKeyFromoChildrenForPlatformSetting(field.children)
        Object.assign(initialValues, childValues)
      }
    })

    return initialValues
  }

  //this effect works on collecting and generation initial key fields with blank value for formik
  useEffect(() => {
    setLoadingFormFields(true)
    if (platformSettings.length) {
      platformSettings.forEach((groupItem) => {
        const InitialValues = {}
        let settingField =
          groupItem.group_code === 'ORDER'
            ? 'order'
            : groupItem.group_code === 'PRODUCT'
            ? 'product'
            : groupItem.group_code === 'INVENTORY'
            ? 'inventory'
            : groupItem.group_code === 'SHIPMENT'
            ? 'shipment'
            : groupItem.group_code === 'PRICING'
            ? 'pricing'
            : groupItem.group_code.toLocaleLowerCase()
        if (!InitialValues[`${settingField}_setting`]) {
          InitialValues[`${settingField}_setting`] = {}
        }
        if (groupItem.children.length) {
          groupItem.children.forEach((childItem) => {
            if (childItem.input_type === 'BOOLEAN') {
              InitialValues[`${settingField}_setting`][`${childItem.name}`] = false
              if (childItem.children.length) {
                let children = generateInitialKeyFromoChildrenForPlatformSetting(childItem.children)
                InitialValues[`${settingField}_setting`] = {...children}
              }
            } else if (childItem.input_type === 'INTEGER') {
              InitialValues[`${settingField}_setting`][`${childItem.name}`] = 0
              if (childItem.children.length) {
                let children = generateInitialKeyFromoChildrenForPlatformSetting(childItem.children)
                InitialValues[`${settingField}_setting`] = {...children}
              }
            } else {
              InitialValues[`${settingField}_setting`][`${childItem.name}`] = ''
              if (childItem.children.length) {
                let children = generateInitialKeyFromoChildrenForPlatformSetting(childItem.children)
                InitialValues[`${settingField}_setting`] = {...children}
              }
            }
          })
        }
        setFormFields((pre) => {
          return {...pre, ...InitialValues}
        })
      })
      setLoadingFormFields(false)
    } else {
      setLoadingFormFields(false)
    }
  }, [platformSettings])

  // this effect is work to set the saved setting in shown fields
  useEffect(() => {
    setLoadInitialKeyValues(true)
    if (formFields && retrivedPlatformData) {
      Object.assign(formFields, {...retrivedPlatformData.platform_settings})
      setLoadInitialKeyValues(false)
    }
  }, [formFields, retrivedPlatformData])



  if (loadPlatformSetting || loadingFormFields || retrivedPlatformLoading || loadInitialKeyValues)
    return <Loader />

  return (
    <div
      key={
        {...formFields} +
        '' +
        retrivedPlatformData?.platform_settings +
        loadInitialKeyValues +
        retrivedPlatformLoading
      }
    >
      <div className='card'>
        <Formik
          // enableReinitialize={true}
          initialValues={{...formFields}}
          onSubmit={(values, helpers) => {
            const {...othervalues} = values
            const allSettings = {
              platformId: Number(selectedPlatformId),
              ...othervalues,
            }
            try {
              dispatch(createPlatformSettingAsync(allSettings)).then((response) => {
                if (response?.payload?.status_code === 200) {
                  successToast('Platform settings saved successfully !')
                  helpers.setSubmitting(false)
                } else {
                  warningToast('Setting not updated')
                  helpers.setSubmitting(false)
                }
              })
            } catch (error) {
              helpers.setSubmitting(false)
            }

            const id = Number(selectedPlatformId)!
            const display_name = connectedPlatformData?.display_name
            const isActive = connectedPlatformData?.isActive == false ? true : true
            const payload = {
              display_name,
              isActive,
            }

            dispatch(isActiveBoolean({...payload, id}))
          }}
        >
          {({handleChange, values, handleSubmit, setFormikState, isSubmitting, setValues}) => {
            console.log('formFields 2', values)

            return (
              <form onSubmit={handleSubmit}>
                <div className='card-header bg-white'>
                  <div className='card-title'>Platform Setting</div>
                  <div className='card-toolbar'>
                    <button type='button' disabled={isSubmitting} className='btn btn-sm btn-secondary text-white'>
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={isSubmitting|| settings?.length===0}
                      className='btn btn-sm btn-success ms-2'
                    >
                      {isSubmitting ? 'Saving...' : 'Save'}
                    </button>
                  </div>
                </div>
                <div className='card- p-3 p-sm-8'>
                  {platformSettings?.length
                    ? platformSettings.map(
                        (setting: SETTINGS_OF_PLATFORM, platformSettingIndex: number) => {
                          let settingField =
                            setting.group_code === 'ORDER'
                              ? 'order'
                              : setting.group_code === 'PRODUCT'
                              ? 'product'
                              : setting.group_code === 'INVENTORY'
                              ? 'inventory'
                              : setting.group_code === 'SHIPMENT'
                              ? 'shipment'
                              : setting.group_code === 'PRICING'
                              ? 'pricing'
                              : setting.group_code.toLocaleLowerCase()

                          return (
                            <section key={'sectionmap' + platformSettingIndex}>
                              <div className='mt-5 border shadow rounded'>
                                <div className=' ribbon ribbon-start'>
                                  <div className='ribbon-label bg-primary'>
                                    {setting.group_code}
                                  </div>
                                </div>
                                <div className='row p-2 p-sm-4'>
                                  {!setting.parent ? (
                                    //currently parent is only considered for boolean
                                    setting.input_type === 'BOOLEAN' ? (
                                      <div
                                        className='row p-2 p-sm-4 mt-4'
                                        key={
                                          platformSettingIndex +
                                          'parentSetting' +
                                          platformSettingIndex
                                        }
                                      >
                                        <div className='col-12'>
                                          <div className=''>
                                            <CustomInput
                                              label={setting.display_name}
                                              labelClass='form-label  me-sm-10 me-0 fs-4 mb-0'
                                              message={setting.description}
                                              containerClasses='form-check form-switch form-check-solid d-sm-flex ps-0 align-items-center'
                                              inputProps={{
                                                type: 'checkbox',
                                                className:
                                                  'form-check-input h-30px w-50px ms-sm-5 ms-0',
                                                name: settingField
                                                  ? `${settingField}_setting.${setting.name}`
                                                  : setting.name,
                                                checked: settingField
                                                  ? values[`${settingField}_setting`][
                                                      `${setting.name}`
                                                    ]
                                                  : values[setting.name],
                                                onChange: handleChange,
                                                id: setting.group_code + setting.name,
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className='separator mt-3'></div>
                                        <div className='row p-4'>
                                          {values[`${settingField}_setting`][`${setting.name}`]
                                            ? setting?.children?.length
                                              ? setting?.children?.map(
                                                  (
                                                    childSetting1: SETTINGS_OF_PLATFORM,
                                                    childIndex1: number
                                                  ) => {
                                                    return (
                                                      <DynamicChildSetting
                                                        key={
                                                          'childsetting' +
                                                          platformSettingIndex +
                                                          platformSettingIndex +
                                                          childIndex1
                                                        }
                                                        childItem={childSetting1}
                                                        childItemIndex={childIndex1}
                                                        settingField={settingField}
                                                        values={values}
                                                        handleChange={handleChange}
                                                      />
                                                    )
                                                  }
                                                )
                                              : null
                                            : null}
                                        </div>
                                        {setting?.field_mappings?.length ? (
                                          <div className=''>
                                            {values[`${settingField}_setting`][
                                              `${setting.name}`
                                            ] ? setting.field_mappings.map((field_mapping:FIELD_MAPPING_TYPE)=>{
                                              return (
                                              <MappingInput
                                                selectedPlatformId={Number(selectedPlatformId)}
                                                destinationSide={Number(selectedPlatformId)!}
                                                sourceSide={mainStoreData?.id!}
                                                isMappingVisible={
                                                  values[`${settingField}_setting`][
                                                    `${setting.name}`
                                                  ]
                                                    ? values[`${settingField}_setting`][
                                                        `${setting.name}`
                                                      ]
                                                    : false
                                                }
                                                handleChange={handleChange}
                                                field_mappings={field_mapping}
                                              />
                                            ) 
                                            })
                                             : null}
                                          </div>
                                        ) : null}
                                        {
                                            primaryPlatformSettings.length ? // checking the length of primary pltfm setting
                                              primaryPlatformSettings.find(it=>it.group_code===setting.group_code)?.field_mappings.length ?
                                              primaryPlatformSettings.find(it=>it.group_code===setting.group_code)?.field_mappings.map((field_mapping:FIELD_MAPPING_TYPE)=>{
                                                return (<>
                                                    <MappingInput
                                                      selectedPlatformId={Number(selectedPlatformId)}
                                                      sourceSide={Number(selectedPlatformId)}
                                                      destinationSide={Number(mainStoreData?.id)}
                                                      isMappingVisible={values[`${settingField}_setting`][`${setting.name}`]}
                                                      handleChange={handleChange}
                                                      field_mappings={field_mapping}
                                                    />
                                                </>)
                                              })
                                                 : 
                                                null
                                            : null
                                        }
                                      </div>
                                    ) : setting.input_type === 'SELECT' ? (
                                      <div
                                        className='row p-4'
                                        key={
                                          platformSettingIndex +
                                          'parentSetting' +
                                          platformSettingIndex
                                        }
                                      >
                                        <div className='row p-4'>
                                          <>
                                            <div className='col-12 col-sm-6'>
                                              <label className='form-label' htmlFor={setting.name}>
                                                {setting.display_name}
                                                &nbsp;&nbsp;<InfoTooltip message={setting.description} />
                                              </label>
                                              <select
                                                name={setting.name}
                                                value={values[`${setting.name}`]}
                                                onChange={handleChange}
                                                className='form-select form-select-lg'
                                              >
                                                <option value=''>{setting.placeholder}</option>
                                                {setting.setting_options.map((item: any) => {
                                                  return (
                                                    <option key={item.id} value={item.name}>
                                                      {item.display_name}
                                                    </option>
                                                  )
                                                })}
                                              </select>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                              <label className='form-label' htmlFor={primaryPlatformSettings[0]?.name}>
                                                {primaryPlatformSettings[0]?.display_name}
                                                &nbsp;&nbsp;<InfoTooltip message={primaryPlatformSettings[0]?.description} />
                                              </label>
                                              <select
                                                name={primaryPlatformSettings[0]?.name}
                                                value={values[`${primaryPlatformSettings[0]?.name}`]}
                                                onChange={handleChange}
                                                className='form-select form-select-lg'
                                              >
                                                <option value=''>{primaryPlatformSettings[0]?.placeholder}</option>
                                                {primaryPlatformSettings[0]?.setting_options?.map((item: any) => {
                                                  return (
                                                    <option key={item.id} value={item.name}>
                                                      {item.display_name}
                                                    </option>
                                                  )
                                                })}
                                              </select>
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                    ) : null
                                  ) : //rest input type of parent...

                                  //under this part the rest settings come those are not parent setting
                                  setting.input_type === 'BOOLEAN' ? (
                                    <div
                                      className='col-12 col-sm-6 mt-4'
                                      key={
                                        platformSettingIndex +
                                        'parentSetting' +
                                        platformSettingIndex
                                      }
                                    >
                                      <CustomInput
                                        label={setting.display_name}
                                        labelClass='form-label  me-sm-10 me-0 mb-0'
                                        message={setting.description}
                                        containerClasses='form-check form-switch form-check-solid '
                                        inputProps={{
                                          type: 'checkbox',
                                          className: 'form-check-input h-30px w-50px ms-sm-5 ms-0',
                                          name: settingField
                                            ? `${settingField}_setting.${setting.name}`
                                            : setting.name,
                                          checked: settingField
                                            ? values[`${settingField}_setting`][`${setting.name}`]
                                            : values[setting.name],
                                          onChange: handleChange,
                                          id: setting.group_code + setting.name,
                                        }}
                                      />
                                    </div>
                                  ) :  setting.input_type === 'SELECT' ? (
                                    <div
                                      className='row p-4'
                                      key={
                                        platformSettingIndex +
                                        'parentSetting' +
                                        platformSettingIndex
                                      }
                                    >
                                      <div className='row p-4'>
                                        <>
                                          <div className='col-12 col-sm-6'>
                                            <label className='form-label' htmlFor={setting.name}>
                                              {setting.display_name}
                                              &nbsp;&nbsp;<InfoTooltip message={setting.description} />
                                            </label>
                                            <select
                                              name={setting.name}
                                              value={values[`${setting.name}`]}
                                              onChange={handleChange}
                                              className='form-select form-select-lg'
                                            >
                                              <option value=''>{setting.placeholder}</option>
                                              {setting.setting_options.map((item: any) => {
                                                return (
                                                  <option key={item.id} value={item.name}>
                                                    {item.display_name}
                                                  </option>
                                                )
                                              })}
                                            </select>
                                          </div>
                                        </>
                                      </div>
                                    </div>
                                  ) :
                                  setting.input_type === 'INTEGER' ? (
                                    <CustomInput
                                      key={
                                        platformSettingIndex +
                                        'parentSetting' +
                                        platformSettingIndex
                                      }
                                      label={setting.display_name}
                                      message={setting.description}
                                      inputProps={{
                                        type: 'number',
                                        className: 'form-control form-control-lg',
                                        name: settingField
                                          ? `${settingField}_setting.${setting.name}`
                                          : setting.name,
                                        value: settingField
                                          ? values[`${settingField}_setting`][`${setting.name}`]
                                          : values[setting.name],
                                        onChange: handleChange,
                                        id: setting.group_code + setting.name,
                                      }}
                                    />
                                  ) : setting.input_type === 'DATETIME' ? (
                                    <CustomInput
                                      key={
                                        platformSettingIndex +
                                        'parentSetting' +
                                        platformSettingIndex
                                      }
                                      label={setting.display_name}
                                      message={setting.description}
                                      inputProps={{
                                        type: 'datetime-local',
                                        className: 'form-control form-control-lg',
                                        name: settingField
                                          ? `${settingField}_setting.${setting.name}`
                                          : setting.name,
                                        value: settingField
                                          ? new Date(
                                              values[`${settingField}_setting`][`${setting.name}`]
                                            )
                                              .toISOString()
                                              .slice(0, 16)
                                          : values[setting.name],
                                        onChange: handleChange,
                                        id: setting.group_code + setting.name,
                                      }}
                                    />
                                  ) : //default is text field
                                  setting.showShortcodes ? (
                                    <>
                                      <ShortCodeInput
                                        key={
                                          platformSettingIndex +
                                          'parentSetting' +
                                          platformSettingIndex
                                        }
                                        label={setting.display_name}
                                        message={setting.description}
                                        inputProps={{
                                          type: 'text',
                                          className: 'form-control form-control-lg',
                                          name: settingField
                                            ? `${settingField}_setting.${setting.name}`
                                            : setting.name,
                                          value: settingField
                                            ? values[`${settingField}_setting`][`${setting.name}`]
                                            : values[setting.name],
                                          onChange: handleChange,
                                          id: setting.group_code + setting.name,
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <CustomInput
                                      key={
                                        platformSettingIndex +
                                        'parentSetting' +
                                        platformSettingIndex
                                      }
                                      label={setting.display_name}
                                      message={setting.description}
                                      inputProps={{
                                        type: 'text',
                                        className: 'form-control form-control-lg',
                                        name: settingField
                                          ? `${settingField}_setting.${setting.name}`
                                          : setting.name,
                                        value: settingField
                                          ? values[`${settingField}_setting`][`${setting.name}`]
                                          : values[setting.name],
                                        onChange: handleChange,
                                        id: setting.group_code + setting.name,
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </section>
                          )
                        }
                      )
                    : (
                      <div className='card-body' >
                        <p className='h3' >No settings found</p>
                      </div>
                    )}
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default DynamicPlatformSetting
