import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'
import { handleOauthCallbackAsync, loginAsync, userOauthLoginAsync } from 'src/redux/actions/authActions'
import { setShopifyConnectFirstTimeMessageShow } from 'src/redux/slices/authSlice'
import { setPlatformSettingModalData } from 'src/redux/slices/connectedPlatformSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import { accessSessionTokenKeyName, refreshSessionTokenKeyName, shopifyDetailsForOauth, shopifyDetailsForWithoutAuth } from 'src/utils/constants'
import { messageTemplete } from 'src/utils/messageTemplete'

const HandleOauth:FC = () => {

    const [loading, setLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [userId,setUserId]=useState<any>();
    const dispatch = useAppDispatch()
    const {errorToast,successToast,warningToast} = useAppToast()
    const navigate = useNavigate()

    useEffect(() => {
      // Dispatch the fetch user data action on component mount
      dispatch(fetchUserAccountDetailsAsync())
        .then((response) => {
          console.log('User account data:', response.payload)
          setUserId(response.payload) // Log the response data
        })
        .catch((error) => {
          console.error('Error fetching user data:', error)
        })
    }, [])

    useEffect(() => {
       
        const code = searchParams.get('code')
        const hmac = searchParams.get('hmac')
        const host = searchParams.get('host')
        const shop = searchParams.get('shop')
        const state = searchParams.get('state')
        const timestamp = searchParams.get('timestamp')
        const spapi_oauth_code = searchParams.get('spapi_oauth_code')
        const selling_partner_id = searchParams.get('selling_partner_id')
        const pltfrmdetail = localStorage.getItem(shopifyDetailsForOauth)
        const platfrmDtlWithoutAuth = localStorage.getItem(shopifyDetailsForWithoutAuth)

        //this condition always work after login so no need to check user data
        if(pltfrmdetail){
            const parsedDetail = JSON.parse(pltfrmdetail)
           dispatch(handleOauthCallbackAsync({code,hmac,host,shop, state, timestamp, isMainStore: parsedDetail?.isStoreCreated ? 0 : 1  , user_id:parsedDetail?.userId, available_platform_name:parsedDetail?.name, display_name: parsedDetail?.friendlyName, spapi_oauth_code : spapi_oauth_code ? spapi_oauth_code :'',selling_partner_id: selling_partner_id ? selling_partner_id : '' }))
           .then((response)=>{
            console.log('final check response', response.payload );
            if(response.payload?.status_code==400){
                errorToast("Url is expired")
                navigate('/auth')
            }

            if(response.payload?.status_code===200){
                successToast("platform created successfully")
                localStorage.removeItem(shopifyDetailsForOauth)
                // dispatch(setPlatformSettingModalData(response.payload.data))
                // navigate?('/listing-channels')

              //   if (response.payload?.data?.id && userId) {
              //     navigate(`/pricing/${userId}/${response.payload?.data?.id}`);
              // }


                if(response?.payload?.data?.id){
              
                    if(response?.payload?.data?.isMainStore){
                    
                      navigate(`/primary-platform-setting/${response?.payload?.data?.id}`)
                    }else{
                      navigate(`/platform-setting/${response?.payload?.data?.id}`)
                    }
                 
                }
                
            }
            
           })
        }else if(platfrmDtlWithoutAuth){
            let parsedPltDtl = JSON.parse(platfrmDtlWithoutAuth)
            let payload = {}
            let shopifyPlatform = parsedPltDtl?.platform_name
            if(parsedPltDtl?.user_id){
             payload = {code,hmac,host,shop, state, timestamp, available_platform_name:parsedPltDtl?.platform_name, display_name:parsedPltDtl?.platform_name, user_id: parsedPltDtl?.user_id, spapi_oauth_code : spapi_oauth_code ? spapi_oauth_code :'',selling_partner_id: selling_partner_id ? selling_partner_id : '' }
            }else{
              payload = {code,hmac,host,shop, state, timestamp, available_platform_name:parsedPltDtl?.platform_name, display_name:parsedPltDtl?.platform_name, spapi_oauth_code : spapi_oauth_code ? spapi_oauth_code :'',selling_partner_id: selling_partner_id ? selling_partner_id : '' }
            }
            
            dispatch(handleOauthCallbackAsync(payload))
           .then((response)=>{
            console.log('final check response', response.payload );
            if(response.payload?.status_code==400){
                errorToast("Url is expired")
                navigate('/auth')
            }

            if(response.payload?.status_code===200){
                localStorage.removeItem(shopifyDetailsForWithoutAuth)
                dispatch(userOauthLoginAsync({email: response.payload?.data?.user?.email, 
                    // password: response.payload?.data?.credentials?.identifier 
                }))
                .then((response) => {
                  if (response?.payload?.status === 401) {
                    errorToast(response?.payload?.detail)
                    setLoading(false)
                  } else if (response?.payload?.access) {
                    console.log('show toast msg')
                    localStorage.setItem(accessSessionTokenKeyName, response?.payload?.access)
                    localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.refresh)
                    if(shopifyPlatform==='SHOPIFY'){
                      successToast("Shopify connected successfully.")
                    }else{
                      successToast("Success")
                    }
                    setLoading(false)
                    dispatch(setShopifyConnectFirstTimeMessageShow(true))
                    navigate('/auth')
                  } else {
                    errorToast(messageTemplete.SOMETHING_WENT_WRONG)
                    setLoading(false)
                  }
                })
                .catch((error) => {
                  console.log('error catch:', error)
                  setLoading(false)
                  navigate('/auth')
                })
            }
            
           })

        }else{
          //if both conditions not matched
          navigate('/auth')
        }

    }, [])
    


  return (
   <div className='d-flex align-items-center justify-content-center' >
        {
            loading ? <Loader/> : null
        }
   </div>
  )
}

export default HandleOauth