import React, {Fragment, useEffect, useRef, useState} from 'react'
import {
  Accordion,
  Button,
  Card,
  Spinner,
  Tab,
  TabContainer,
  TabContent,
  TabPane,
  Tabs,
} from 'react-bootstrap'
import AddNewPriceTemplateModal from './components/AddNewPriceTemplateModal'
import AddNewTitleDescriptionModal from './components/AddNewTitleDescriptionModal'
import AddNewRecipeModal from './components/AddNewRecipeModal'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {useDispatch} from 'react-redux'
import {fetchConnectedPlatformAsync, refreshPlatformDataAsync} from 'src/redux/actions/platformActions'
import {PlatformDataType, PlatformInfoType} from 'src/interfaces/Platform.interface'
import {
  deleteCategoryTemplateDataAsync,
  deleteMoreMappingAsync,
  deleteRecipeAsync,
  deleteTitleDescriptionTemplateAsync,
  getCategoriesByPlatformIdAsync,
  getMoreMappingAsync,
  getPlatformCategoryTemplateDataAsync,
  getPlatformTitleDescriptionTemplateAsync,
  getRecipesAsyncUsingPlatformId,
} from 'src/redux/actions/templateAndPricingActions'

import TemplateListItem from './components/TemplateListItem'
import useAppToast from 'src/hooks/useAppToast'
import {platform} from 'os'
import {
  deletPlatformPriceAsync,
  getPlatformPriceAsync,
} from 'src/redux/actions/productPricingAction'
import Loader from 'src/utils/Loader'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowDown, faArrowUp} from '@fortawesome/free-solid-svg-icons'
import AddNewCategoryTemplateModal from './components/AddNewCategoryTemplateModal'
import {toast} from 'react-toastify'
import {
  CATEGORY_ITEM,
  MoreFieldMappingItem,
  MoreMappingInputAttribute,
  MoreMapping_Item,
  PRICE_TEMPLATE_ITEM,
  RECIPE,
  TITLE_DESCRIPTION_TEMPLATE_ITEM,
} from 'src/interfaces/Template.interface'
import TemplateListItemcopy from './components/TemplateListItemcopy'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import AddNewMoreMappingTemplateModal from './components/AddNewMoreMappingTemplateModal'
import TemaplateComponent from './components/TemaplateComponent'
import { KTSVG } from 'src/marketConnect/helpers'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'



const TemplateAndPricing = () => {

  const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)
  const [refreshPlatformId, setRefreshPlatformId] = useState<number|null>(null)
  const [refreshing, setRefreshing] = useState(false)
  const [refreshMessage, setRefreshMessage] = useState<string>('')
  const [activeIndex, setActiveIndex] = useState<number>(1)
  const [showStoreTemplateModal, setShowStoreTemplateModal] = useState<boolean>(false)
  const [selectedPlatformIdCategory, setSelectedPlatformIdCategory] = useState<any>()
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const [secondaryStorePlatforms, setSecondaryStorePlatforms] = useState<PlatformDataType[]>([])

  const {successToast, errorToast} = useAppToast()

  const [isChecked, setIsChecked] = useState<boolean>(false)
  // const [isCheckedtitle, setIsCheckedtitle] = useState<boolean>(false)

  const [tabState, setTabState] = useState({})

  const [showtitle, setShowTitle] = useState(false)
  const [showtitleData, setShowTitleData] = useState()

  const [selectPriceId, setSelectPriceId] = useState()
  const [pricingState, setPricingState] = useState(false)
  const [updateItem, setUpdateItem] = useState()
  const [selectedTemplateData, setSelectedTemplateData] = useState(null)
  const [selectedCategoryTemplateData, setSelectedCategoryTemplateData] = useState(null)
  const [isCheckedtitle, setIsCheckedtitle] = useState<boolean>(false)
  const [selectedSecondaryPlatform, setSelectedSecondaryPlatform] =
    useState<PlatformDataType | null>(null)
  const [pageNumberTitle, setPageNumberTitle] = useState({
    page: 1,
    limit: 5,
  })
  const [paginationPriceData, setPaginationPriceData] = useState({
    page: 1,
    limit: 5,
  })
  const [paginationCategoryData, setPaginationCategoryData] = useState({
    page: 1,
    limit: 5,
  })

  const dispatch = useAppDispatch()
  const {
    loading,
    getTemplateData,
    getCount,
    previous,
    next,
    platformCategoryTemplateData,
    platformCategoryTemplateDataLoading,
  } = useAppSelector((state) => state.templateAndPricing)

  const {
    platformPricingTemplateLoading,
    getPriceingtemplate,
    getCountPrice,
    previousPrice,
    nextPrice,
  } = useAppSelector((state) => state.platformRepricing)

  const handelPaginationdata = (data) => {
    setPageNumberTitle(data)
  }

  // const onClickCategoryAdd = (id) => {
  //   setSelectedPlatformIdCategory(id)
  // }

  const handelShowTilteModal = (data, item) => {
    setShowTitle(data)
    setShowTitleData(item)
  }

  const handelonSendSelectData = (data) => {
    setSelectedTemplateData(data)
  }
  const handleonSendSelectCategoryData = (data) => {
    setSelectedCategoryTemplateData(data)
  }

  const handelonSendPaginationPrice = (data) => {
    setPaginationPriceData(data)
  }
  const handelonSendPricModalState = (data, item) => {
    setUpdateItem(item)
  }
  const handelonSendPaginationCategory = (data) => {
    setPaginationCategoryData(data)
  }

  useEffect(() => {
    if (fetchedConnectedPlatformData && fetchedConnectedPlatformData.length > 0) {
      const secondaryStore: any = fetchedConnectedPlatformData.filter(
        (platform) => !platform.isMainStore
      )
      setSecondaryStorePlatforms(secondaryStore)
    }
  }, [fetchedConnectedPlatformData])

  // const onClickAddNewStoreTemplate = () => {
  //   setShowStoreTemplateModal(true)
  // }

  // const handleCloseModal = () => {
  //   setShowTitle(false)
  //   setSelectedTemplateData(null) // Reset selected template data
  // }

  // Function to toggle tab 1, closing tab 2 if open
  const toggleTab1 = (id) => {
    setTabState((prevState) => {
      const newState = {...prevState}

      if (prevState[id]?.tab1) {
        newState[id].tab1 = false // Close tab 1
      } else {
        // Open tab 1 and ensure tab 2 is closed
        newState[id] = {tab1: true, tab2: false}
      }
      return newState
    })
  }

  // Function to toggle tab 2, closing tab 1 if open
  const toggleTab2 = (id) => {
    setTabState((prevState) => {
      const newState = {...prevState}

      if (prevState[id]?.tab2) {
        newState[id].tab2 = false // Close tab 2
      } else {
        // Open tab 2 and ensure tab 1 is closed
        newState[id] = {tab1: false, tab2: true}
      }

      return newState
    })
  }

  return (
    <>
      <h3>Listing Rules And Plan</h3>
      {/* <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mt-2'>
        <li className='breadcrumb-item'>
          <span className='bullet bg-gray-500 w-5px h-2px'></span>
        </li>
        <li className='breadcrumb-item px-3 text-muted text-hover-primary'>Template and Pricing</li>
      </ul> */}

      <div className='py-4 '>
        <div className='px-md-7 px-4  py-5 shadow '>
          {fetchedConnectedPlatformLoading ? (
            <div className='d-flex align-items-center justify-content-center h-100'>
              <h1>
                <Loader />
              </h1>
            </div>
          ) : (
            <div>
              {secondaryStorePlatforms.length ? 
                secondaryStorePlatforms.map((item) => (
                <TemaplateComponent
                  key={item.display_name}
                  item={item}
                  tabState={tabState}
                  toggleTab1={toggleTab1}
                  toggleTab2={toggleTab2}
                  platformPricingTemplateLoading={platformPricingTemplateLoading}
                  setShowRefreshModal={setShowRefreshModal}
                  setRefreshPlatformId={setRefreshPlatformId}
                />
              )) : 
              <div className="alert bg-light-primary d-flex align-items-center p-5 shadow-sm">
                <span className="svg-icon svg-icon-2hx svg-icon-primary me-8">
                  <KTSVG path="media/icons/duotune/communication/com009.svg" className="svg-icon-muted svg-icon-3hx" />
                </span>
          
                <div className="d-flex flex-column">
                  <h5 className="mb-1">No Rules and Plans yet </h5>
                  <span>Please connect a platform. Click here to connect <Link to={'/dashboard'} > <KTSVG path="media/icons/duotune/general/gen016.svg" className="svg-icon-primary svg-icon-hx" /></Link>  </span>
                </div>
              </div>
              }
            </div>
          )}
        </div>
      </div>
      <CommonAlertModal
        key={'reffresh data modal'}
        isVisible={showRefreshModal}
        successDisabled={refreshing}
        onCancel={()=>{
          setShowRefreshModal(false)
          setRefreshPlatformId(null)
        }}
        onSuccess={()=>{
          if(refreshPlatformId){
            setRefreshing(true)
            dispatch(refreshPlatformDataAsync({platformId:refreshPlatformId}))
            .then((response)=>{
              successToast(response.payload?.data?.message? response.payload?.data?.message : JSON.stringify(response.payload?.data?.message) )
              setRefreshing(false)
              setRefreshPlatformId(null)
              setShowRefreshModal(false)
            })
          }
        }}
        title='Are you sure ?'
        subtitle='This might take 10-15 minutes to complete'
        isTermsVisible={false}
      />
    </>
  )
}
export default TemplateAndPricing
