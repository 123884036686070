import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Formik} from 'formik'
import React, {Fragment, useEffect, useState} from 'react'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {PlatformSettingDataTypesValues} from 'src/app/_interfaces/setting.interface'
import useAppToast from 'src/hooks/useAppToast'
import {platformfielddata_set} from 'src/interfaces/Platform.interface'
import {retrivePlatformDataAsync} from 'src/redux/actions/platformActions'
import {
  getFieldMappingAsync,
  getPlatformFieldsAsync,
  getPlatformFieldsForMainStoreAsync,
} from 'src/redux/actions/productActions'
import {getPlatformParentFieldDataCategoriesAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

const InfoTooltip = ({message = ''}) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{message}</Tooltip>}>
      <i className='bi bi-info-circle text-dark fs-5'></i>
    </OverlayTrigger>
  )
}

const PrimaryPlatformSetting = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {selectedPlatformId} = useParams()

  const [platformDisplayName, setPlatformDisplayName] = useState<string>('')

  const {retrivedPlatformLoading, retrivedPlatformData} = useAppSelector(
    (state) => state.retrivePlatformData
  )

  useEffect(() => {
    if (selectedPlatformId) {
      dispatch(retrivePlatformDataAsync({id: selectedPlatformId}))
      dispatch(getFieldMappingAsync({destination_platform_id: selectedPlatformId}))
    }
  }, [selectedPlatformId])

  useEffect(() => {
    if (retrivedPlatformData) {
      setPlatformDisplayName(retrivedPlatformData.platform_info?.display_name || '')
    }
  }, [retrivedPlatformData])

  return (
    <div className='card'>
      <div className='card-header bg-white'>
        <div className='card-title'>
          <span>Platform Settings</span>
        </div>
        <div className='card-toolbar'>
          <Link to={'/listing-channels'} className='btn btn-sm btn-primary  me-2'>
            Close
          </Link>
        </div>
      </div>
      <div className='card-body px-3 px-sm-5'>
        <div className='mt-3 border shadow rounded'>
          <div className='  ribbon ribbon-start'>
            <div className='ribbon-label bg-primary'>Platform Connection</div>
          </div>
          <div className='row m-4'>
            <div className='col-12 col-sm-5 h4'>
              {' '}
              {retrivedPlatformData?.platform_info?.display_name} Store Friendly Name:
            </div>
            <div className='col-6 col-sm-6 d-flex align-items-center h4'>
              {' '}
              {retrivedPlatformData?.platform_info?.display_name}
            </div>

            <div className='w-100'></div>

            <div className='row'>
              <div className='col-6 col-sm-5 h4 mt-1 '>Status:</div>
              <div className='col-6 col-sm-6 mt-1'>
                <span
                  className={`badge ${
                    retrivedPlatformData?.isConnected ? 'badge-success' : 'badge-danger'
                  }`}
                  style={{fontSize: '1.3rem', borderRadius: '6px'}}
                >
                  {retrivedPlatformData?.isConnected ? 'Connected' : 'Not Connected'}
                </span>
              </div>
            </div>

            <div className='w-100'></div>

            {retrivedPlatformData?.code === 'SHOPIFY' && (
              <>
                <div className='col-12 col-sm-5 h4 mt-1'>
                  {retrivedPlatformData?.platform_info?.display_name} {''}Url:
                </div>
                <div className='col-12 col-sm-6 h4 mt-1'>
                  {retrivedPlatformData?.credentials?.identifier}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrimaryPlatformSetting
