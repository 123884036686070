import {AsyncThunkConfig, GetThunkAPI} from '@reduxjs/toolkit/dist/createAsyncThunk'
import axios from 'axios'
import React, {FC} from 'react'
import {accessSessionTokenKeyName} from 'src/utils/constants'

type REQUEST_METHODS = 'GET' | 'POST' | 'PUT' | 'DELETE'
type ENDPOINT = string
// type TOOLKIT = any
type PARAMS = Object
// type HEADER_OPTIONS = any

const getTokenSync = () => {
  return localStorage.getItem(accessSessionTokenKeyName)
}

const AxiosClient = async (
  method: REQUEST_METHODS,
  endPoint: ENDPOINT,
  payload: any,
  toolkit: any,
  params: PARAMS
) => {
  const token = getTokenSync()
  // console.log('token', token)

  const headerOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (getTokenSync()) {
    headerOptions['Authorization'] = `Bearer ${token}`
  }

  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      params: {...params},
      headers: {...headerOptions},
    })
    return toolkit.fulfillWithValue(response.data)
  } catch (error:any) {
    console.log('error: axios client', error)
    return toolkit.rejectWithValue(error.response)
  }
  // } catch (error:any) {
  //   // Extract only necessary serializable parts from the error response
  //   const serializableError = {
  //     status: error.response?.status,
  //     data: error.response?.data,
  //   };

  //   return toolkit.rejectWithValue(serializableError);
  // }
}

const publicAxiosClient = async (method: REQUEST_METHODS, endPoint: ENDPOINT, payload: any,  toolkit: any,
  params: PARAMS) => {

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + endPoint,
      method: method,
      data: payload,
      headers,
      params,
    })
    return toolkit.fulfillWithValue(response.data)
    // return response.data // Return raw data
  }   catch (error:any) {
    // Extract only necessary serializable parts from the error response
    const serializableError = {
      status: error.response?.status,
      data: error.response?.data,
    };

    console.log("error in rrsponse:",serializableError)
    return toolkit.rejectWithValue(serializableError);
  }
}

export {publicAxiosClient, AxiosClient}
export default AxiosClient
