import React, {FC, useEffect, useState} from 'react'
import {Alert, Button, Modal, Spinner} from 'react-bootstrap'
import {Dropdown} from 'primereact/dropdown'
import DropDownList from '../../listing/components/DropDownList'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {PLATFORM_FIELD, PlatformDataType} from 'src/interfaces/Platform.interface'
import {
  createRecipeAsync,
  deleteRecipeMappingItemAsync,
  getCategoriesByPlatformIdAsync,
  getPlatformTitleDescriptionTemplateAsync,
  getRecipesAsyncUsingRecipeId,
  updateRecipeAsync,
} from 'src/redux/actions/templateAndPricingActions'
import Loader from 'src/utils/Loader'
import {getPlatformPriceAsync} from 'src/redux/actions/productPricingAction'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import {FieldArray, Formik} from 'formik'
import Select, { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import * as yup from 'yup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import useAppToast from 'src/hooks/useAppToast'
import { OTHER_TEMPLATE_DATA_ON_UPDATE, RECIPE, RECIPE_DATA, RECIPE_FORM_DATA, RECIPE_MAPPING } from 'src/interfaces/Template.interface'
import RecipeRuleItem from './RecipeRuleItem'
import AdditionalTemplateMappingItem from './AdditionalTemplateMappingItem'

type AddNewRecipeModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  selectedSecondaryPlatform: PlatformDataType | null
  getLatestRecipesList:()=>void
  recipeData:RECIPE|null
}

const AddNewRecipeModal: FC<AddNewRecipeModalPropsType> = ({
  isVisible,
  closeModal,
  selectedSecondaryPlatform,
  getLatestRecipesList,
  recipeData=null
}) => {
  const [templateDataLoading, setTemplateDataLoading] = useState(false)
  const [platformNativeFields, setPlatformNativeFields] = useState<PLATFORM_FIELD[] | []>([])
  const [platformCustomFields, setPlatformCustomFields] = useState<PLATFORM_FIELD[] | []>([])
  const { mainStoreData } = useAppSelector((state) => state.fetchConnectedPlatform) || {};
  const [isCustomField, setIsCustomField] = useState(false)
  const [customFieldLoading, setCustomFieldLoading] = useState(false)
  const [recipeDataDetails, setRecipeDataDetails] = useState<RECIPE_DATA|null>(null)
  const dispatch = useAppDispatch()
  const {getTemplateData, platformCategories } = useAppSelector((state) => state.templateAndPricing)
  const {getPriceingtemplate} = useAppSelector((state) => state.platformRepricing)
  const [otherTemplateFieldLoader, setOtherTemplateFieldLoader ] = useState(false)
  const [otherTemplateFieldData, setOtherTemplateFieldData] = useState<PLATFORM_FIELD[]|[]>([])
  const [otherTemplates, setOtherTemplates] = useState<{
    id: number|null,
    template_id: number
  } []|[]>([])
  const { warningToast, successToast } = useAppToast()
 
  const getRecipeDataAsync = async () => {
    setTemplateDataLoading(true)
    if(recipeData?.id){
      dispatch(getRecipesAsyncUsingRecipeId({recipeId:recipeData.id+'', otherParams:{}}))
      .then((response)=>{
        if(response.payload?.id){
          if(response.payload?.other_templates?.length){
            if(recipeData?.id){
              getOtherMappingFieldsForTemplate()
            }
          }
          setRecipeDataDetails(response.payload)
          setTemplateDataLoading(false)
        }
      })
    }else{
      setOtherTemplateFieldData([])
    }
  }

  useEffect(() => {
    if (isVisible && selectedSecondaryPlatform && mainStoreData?.id ) {
      setTemplateDataLoading(true)
      Promise.all([
        dispatch(
          getPlatformPriceAsync({
            platform_id: selectedSecondaryPlatform.id! + '',
            paginationdata: {page: 1, limit: 100},
          })
        ),
        dispatch(
          getPlatformTitleDescriptionTemplateAsync({
            platform_id: selectedSecondaryPlatform.id! + '',
            paginationdata: {page: 1, limit: 100},
          })
        ),

        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData.id!,
            otherParams: {field_type: 'NATIVE', includeLocalFields: true, limit:100, page:1},
          })
        ).then((response) => {
          setPlatformNativeFields(response.payload)
        }),

        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData!.id!,
            otherParams: {field_type: 'CUSTOM', includeLocalFields: false, limit:100, page:1},
          })
        ).then((response) => {
          setPlatformCustomFields(response.payload)
        }),

        dispatch(
          getCategoriesByPlatformIdAsync({
            platformId: selectedSecondaryPlatform.id! +'', 
            paginationdata: {page: 1, limit: 100},
          })
        ).then((response) => {
          console.log('response of category---', response)
          // setPlatformNativeFields(response.payload)
        }),

        getRecipeDataAsync()
      ]).finally(() => {
        setTemplateDataLoading(false)
      })
    }
  }, [isVisible])


  const getOtherMappingFieldsForTemplate = () => {
    setOtherTemplateFieldLoader(true)
    dispatch(
      getPlatformFieldsAsync({
        platformId: selectedSecondaryPlatform!.id!,
        otherParams: { field_code:'', allowMapViaTemplate:true },
      })
    ).then((response) => {
      console.log('response OF additional template', response.payload)
      if(response.payload?.length){
        setOtherTemplateFieldData(response.payload)
      }else{
        warningToast("Additional plans not found")
      }
      setOtherTemplateFieldLoader(false)
    })

  }


  const recipeSchema = yup.object().shape({
    name:yup.string().required('Name is required'),
    selectedPricingTemplate: yup.string().required('Price Modifier Rule is required'),
    selectedTitleDescriptionTemplate: yup.string().required('Title Description Mapping Rule is required'),
    selectedCategoryTemplate: yup.string().required('Category Mapping Rule is required'),
    productRecipes: yup.array().of(
      yup.object().shape({
        inputType: yup.string(),
        selectedAndOr: yup.string(),
        selectedFieldType: yup.string(),
        selectedCondition: yup.string(),
        selectedAnyAll: yup.string(),
      })
    ).required(),
  })

  useEffect(() => {

    if(recipeDataDetails?.other_templates?.length){
      recipeDataDetails.other_templates.map((item:OTHER_TEMPLATE_DATA_ON_UPDATE)=>{
        setOtherTemplates(pre=>([...pre, { id: item.id, template_id:item.template.id } ])) 
      })
    }
    
  }, [recipeDataDetails])
  

  const initialValues: RECIPE_FORM_DATA = {
    name: recipeData?.name ? recipeData.name : '',
    selectedPricingTemplate: recipeData?.price_template ? recipeData.price_template + '' : '',
    selectedTitleDescriptionTemplate: recipeData?.title_description_template
      ? recipeData.title_description_template + ''
      : '',
    selectedCategoryTemplate: recipeData?.category_template
      ? recipeData.category_template + ''
      : '',
    dataConjunction: recipeData?.check_data_conjunction ? recipeData.check_data_conjunction : '',
    productRecipes: recipeData?.mapping_data
      ? recipeData.mapping_data.map((res: RECIPE_MAPPING) => {
          return {
            id: res.id,
            checkPlatformField: res.check_platform_field + '',
            checkOperator: res.check_operator,
            checkConjunction: res.check_conjunction,
            checkValue: res.check_value,
          }
        })
      : [],
      // other_templates:[]
      other_templates: recipeDataDetails?.other_templates.length ? recipeDataDetails.other_templates.map((item:OTHER_TEMPLATE_DATA_ON_UPDATE)=>{
        return { id: item.id, template_id:item.template.id }
      }) :  []
  }

  console.log('initialValues', initialValues)
  console.log('otherTemplates', otherTemplates)

  return (
    <>
      <Modal size={'xl'} backdrop='static' centered show={isVisible} onHide={()=>{
        closeModal()
        setOtherTemplateFieldData([])
      }}  >
        {templateDataLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{...initialValues, other_templates:otherTemplates }}
            key={templateDataLoading+''+otherTemplates.length}
            onSubmit={(values, helpers) => {
              console.log('values', values)
              if(values.selectedTitleDescriptionTemplate===''){
                  warningToast('Title Description Mapping Rule is required')
                  helpers.setSubmitting(false)
                  return 
                }else if(values.productRecipes.length < 1){
                  warningToast("Please add at least one rule to assign products")
                  helpers.setSubmitting(false)
                  return 
                }
                helpers.setSubmitting(true)
                if(recipeData){
                  //that means call update
                  const payload = {
                    platform: selectedSecondaryPlatform?.id,
                    name: values.name,
                    title_description_template: values.selectedTitleDescriptionTemplate,
                    price_template: values.selectedPricingTemplate,
                    category_template: values.selectedCategoryTemplate,
                    check_data_conjunction: values.dataConjunction, //# AND, OR
                    mapping_data: values.productRecipes.map((item) => {
                      return {
                        id:item.id,
                        check_platform_field: item.checkPlatformField,
                        check_operator: item.checkOperator,
                        check_conjunction: item.checkConjunction,
                        check_value: item.checkValue,
                      }
                    }),
                    other_templates:values.other_templates
                  }
    
                  dispatch(updateRecipeAsync({recipeId:recipeData.id+'', payload:payload })).then((response) => {
                    console.log('response platform----', response)
                    if(response.payload.status==400){
                      warningToast(response.payload?.data?.error)
                      helpers.setSubmitting(false)
                    }
                    if(response.payload?.id){
                      successToast("Recipe updated successfully")
                      helpers.setSubmitting(false)
                      getLatestRecipesList()
                      setOtherTemplates([])
                      closeModal()
                    }
    
                  })
                  .catch((error)=>{
                    console.log(' platform error', error)
                  })
                }else{
                  // call create 
                  const payload = {
                    platform: selectedSecondaryPlatform?.id,
                    name: values.name,
                    title_description_template: values.selectedTitleDescriptionTemplate,
                    price_template: values.selectedPricingTemplate,
                    category_template: values.selectedCategoryTemplate,
                    check_data_conjunction: values.dataConjunction, //# AND, OR
                    mapping_data: values.productRecipes.map((item) => {
                      return {
                        check_platform_field: item.checkPlatformField,
                        check_operator: item.checkOperator,
                        check_conjunction: item.checkConjunction,
                        check_value: item.checkValue,
                      }
                    }),
                    other_templates:values.other_templates
                  }
    
                  console.log('payload', payload)
                  dispatch(createRecipeAsync(payload)).then((response) => {
                    console.log('response platform----', response)
                    if(response.payload.status==400){
                      warningToast(response.payload?.data?.error)
                      helpers.setSubmitting(false)
                    }
                    if(response.payload?.id){
                      successToast("Recipe saved successfully")
                      helpers.setSubmitting(false)
                      getLatestRecipesList()
                      setOtherTemplates([])
                      closeModal()
                    }
    
                  })
                  .catch((error)=>{
                    console.log(' platform error', error)
                  })
                }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting, 
              setFormikState,
              setSubmitting
              /* and other goodies */
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <>
                    <Modal.Header>
                      <Modal.Title>{ recipeData?.id ? 'Update Listing Plan' : 'Add New Listing Plan'}</Modal.Title>
                      <div className='d-inline-flex'>
                        <Button disabled={isSubmitting} size='sm' className='mx-2' onClick={closeModal}>
                          Close
                        </Button>
                        <Button disabled={isSubmitting} size='sm' variant='success' type='submit'>
                        {isSubmitting?  "Saving" : " Save Changes"} 
                        </Button>
                      </div>
                    </Modal.Header>
                    <Modal.Body className='px-4'>
                      <div>
                        {/* <div className='p-2 d-flex mb-4 border rounded flex-row align-items-center'>
                          <p className='form-label mb-0'>Rule Name</p>
                          <div className='ms-8 d-flex flex-row justify-content-center align-items-center'>
                            <p className='h4  text-success ms-4'>
                              {selectedSecondaryPlatform?.display_name}
                            </p>
                          </div>
                        </div>*/}
                        <label className='form-label mt-2'>
                              Give the Listing Plan a descriptive name
                            </label>
                            <div className='d-flex align-items-center mt-2'>
                              <p
                                className='form-label w-300'
                                style={{whiteSpace: 'nowrap', marginRight: '10px'}}
                              >
                                Name
                              </p>
                              <input
                                type='text'
                                className='form-control'
                                autoComplete='OFF'
                                placeholder='Enter Name'
                                style={{width: '60%', marginLeft: 'auto'}}
                                name='name'
                                value={values.name}
                                onChange={handleChange}
                              />
                            </div>
                        <div className='border-bottom my-1 mt-3 border-2'></div> 
                        <div className='mb-3'>
                          <label className='form-label mt-2'>
                            Select the plans :-
                          </label>

                          <div className='col mt-2'>
                            <div className='row'>
                              <div className='col-12 col-sm-4' >
                                  <p className='form-label'>
                                  Price Modifier Rule
                                  </p>
                              </div>
                              <div className="col-12 col-sm-8">
                                <select
                                  id='dropdown'
                                  className='form-select'
                                  name={'selectedPricingTemplate'}
                                  value={values.selectedPricingTemplate}
                                  onChange={handleChange}
                                >
                                  <option value=''>-- Please Select --</option>
                                  {getPriceingtemplate.map((item, index) => {
                                    return (
                                      <option key={'pricingTemplate' + index} value={item.id}>
                                        {item.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className='row mt-3'>
                              <div className="col-12 col-sm-4 ">
                                <p className='form-label' >
                                  Title Description Mapping Rule
                                </p>
                              </div>
                              <div className="col-12 col-sm-8">
                                <select
                                  id='dropdown'
                                  className='form-select'
                                  name='selectedTitleDescriptionTemplate'
                                  value={values.selectedTitleDescriptionTemplate}
                                  onChange={handleChange}
                                >
                                  <option value=''>-- Please Select --</option>
                                  {getTemplateData.map((item, index) => {
                                    return (
                                      <option key={'titleDescription' + index} value={item.id}>
                                        {item?.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>

                            </div>
                            <div className='row mt-3'>
                              <div className="col-12 col-sm-4">
                                <p className='form-label'>
                                Category Mapping Rule
                                </p>
                              </div>
                              <div className="col-12 col-sm-8">
                                <select
                                  id='dropdown'
                                  className='form-select'
                                  name='selectedCategoryTemplate'
                                  value={values.selectedCategoryTemplate + ''}
                                  onChange={handleChange}
                                >
                                  <option value='null'>-- Please Select --</option>
                                  { platformCategories.length &&
                                    platformCategories.map((plt)=>{
                                      return(
                                        <option key={'categoryTemplate'+plt.id} value={plt.id}>{plt.name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </div>

                            {/* other mapping rules  */}
                            <div>
                              {
                                otherTemplateFieldData.length === 0 ? 
                                otherTemplateFieldLoader ? 
                                    <Spinner animation='border' /> :
                                <button type='button' className='btn btn-sm btn-primary mt-3' onClick={()=>getOtherMappingFieldsForTemplate()} >Add Other Template Rules </button>
                                : 
                                <div>
                                  <FieldArray
                                  name='other_templates'
                                  render={arrayHelpers=>{
                                    return(
                                      <>
                                        {
                                          otherTemplateFieldData.map((item:PLATFORM_FIELD, index)=>{
                                          return(<AdditionalTemplateMappingItem 
                                              key={'additionalmapping'+item.id}
                                              otherFieldIndex={index} 
                                              item={item}
                                              values={values}
                                              setFormikState={setFormikState}
                                              selectedSecondaryPlatform={selectedSecondaryPlatform}  
                                              handleChange={handleChange}
                                            />)
                                          })
                                        }
                                      </>
                                    )
                                  }}
                                  />
                                </div>
                              }
                            </div>
                            {/* other mapping rules  */}

                            <div className='border-bottom mt-3 border-2'></div>
                            <div className='d-flex align-items-center mt-2'>
                              <label className='form-label mt-3 required'>
                                Auto assign this rule to products (Required)
                              </label>
                            </div>

                            <div>
                              {values.productRecipes.map((recipe, recipeIndex) => {
                                
                                return(
                                  <RecipeRuleItem
                                    key={'recipeitem'+recipeIndex}
                                    customFieldLoading={false}
                                    formValues={values}
                                    getLatestRecipesList={getLatestRecipesList}
                                    handleChange={handleChange}
                                    recipe={recipe}
                                    recipeData={recipeData}
                                    recipeDataInDetail={ recipeDataDetails?.mapping_data?.length ? recipeDataDetails.mapping_data[recipeIndex] : null }
                                    recipeIndex={recipeIndex}
                                    setFormikState={setFormikState}
                                    setSubmitting={setSubmitting}
                                    platformCustomFields={platformCustomFields}
                                    platformNativeFields={platformNativeFields}
                                  />
                                )
                              })}
                            </div>
                            <div className='row mt-2'>
                              <div className='col-12 '>
                                <button
                                  type='button'
                                  className='btn btn-primary btn-sm'
                                  onClick={() => {
                                  
                                    setFormikState((pre) => {
                                      return {
                                        ...pre,
                                        values: {
                                          ...pre.values,
                                          dataConjunction: 'AND',
                                          // selectedCategoryTemplate: null,
                                          productRecipes: [
                                            ...pre.values.productRecipes,
                                            {
                                              id:null,
                                              checkConjunction: 'OR',
                                              checkOperator: 'CONTAINS',
                                              checkPlatformField: platformNativeFields[0].id + '',
                                              checkValue: '',
                                            },
                                          ],
                                        },
                                      }
                                    })

                                    
                                  }}
                                >
                                  + Add new
                                </button>
                              </div>
                            </div>

                            {/* <div className='border-bottom mt-3 border-2'></div> */}
                            {/* <label className='form-label mt-3'>
                              Give the recipe a descriptive name
                            </label>
                            <div className='d-flex align-items-center mt-3'>
                              <p
                                className='form-label w-300'
                                style={{whiteSpace: 'nowrap', marginRight: '10px'}}
                              >
                                Name
                              </p>
                              <input
                                type='text'
                                className='form-control'
                                autoComplete='OFF'
                                placeholder='Enter Name'
                                style={{width: '60%', marginLeft: 'auto'}}
                                name='name'
                                value={values.name}
                                onChange={handleChange}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </>
                </form>
              )
            }}
          </Formik>
        )}
      </Modal>
    </>
  )
}

export default AddNewRecipeModal
