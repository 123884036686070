import React, {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import {KTSVG} from 'src/marketConnect/helpers'
import { createProductCsvsAsync, getPlatformWiseProductCsvsAsync } from 'src/redux/actions/productActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

export type CSV_DATA = {
  id: number
  fileDirectory:string
  feature: string
  sync_status: string
  message: string
  created_at: string
  updated_at: string
  user: number
  platform: number
}

const CSVDownload: FC = () => {
  const dispatch = useAppDispatch()
  const {successToast, warningToast}= useAppToast()
  const [loading, setLoading] = useState<boolean>(true)
  const [platformData, setPlatformData] = useState<CSV_DATA[]|[]>([])
  const { theRangeId } = useAppSelector(state=>state.productsOfSingleChannel)

  const columns = [
    {
      name: 'Product Category',
      cell: (row) => <h6>{row.message}</h6>,
    },
    {
      name: 'File Url',
      cell: (row) => {
        if(row.fileUrl){
          return (
            <OverlayTrigger placement='top' overlay={<Tooltip>Download file</Tooltip>}>
              <a href={row.fileUrl ? row.fileUrl : '#' } download className='btn btn-link btn-color-primary me-5 mb-2 fw-bold'>
                Download
              </a>
            </OverlayTrigger>
          )
        }else{
          return null
        }
      },
      width:'160px',
      center:true
      
    },
  ]

  

  useEffect(() => {
    if(theRangeId){
        setLoading(true)
       dispatch(getPlatformWiseProductCsvsAsync({platform_id: theRangeId }))
       .then((res)=>{
        console.log('csv data', res.payload);
        if(res.payload?.status_code==200){
            setPlatformData(res.payload?.data)
        }
        setLoading(false)
       })
       .catch(()=>{
        setLoading(false)
       })
    }
  }, [theRangeId])

  

  return (
    <div>

      <div className='d-flex flex-row flex-wrap justify-content-between mb-3'>
        <span className='fw-bold text-lg ' style={{fontSize: '16px'}}>
          Platform Products Files
        </span>
        {{theRangeId} ? (
          <div className='d-flex justify-content-sm-end ms-2 pe-2 mb-3 '>
            <button
              className='btn btn-primary btn-sm d-inline-flex align-items-center me-2'
              onClick={()=>{
                dispatch(createProductCsvsAsync({ platform_id: theRangeId }))
                .then((res) => {
                  console.log("response",res)
                  if (res.payload?.status_code === 200) {
                    successToast(res?.payload?.message)
                  } else {
                    warningToast(res?.payload?.data?.error)
                  }
                }).catch(() => {
                  warningToast('Failed to create Product CSV.')
                })
          
             
              }}
              style={{fontSize: '14px', padding: '0.25rem 0.5rem'}}
            >
              Download CSV
            </button>
          </div>
        ) : null}
      </div>

      <div className='card'>
        <div className='card-body'>
          <DataTable
            data={platformData}
            pagination
            progressPending={loading}
            paginationServer
            columns={columns}
            responsive
            fixedHeader
            customStyles={{
              table: {
                style: {
                  height: '56vh',
                },
              },
              headCells: {
                style: {fontWeight: 'bold', fontSize: '14px'},
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CSVDownload
