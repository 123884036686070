import React, { FC } from 'react'
import { ChartsWidget1, ChartsWidget2, ChartsWidget3, ChartsWidget4, ChartsWidget5, ChartsWidget6, ChartsWidget7, ChartsWidget8 } from 'src/marketConnect/partials/widgets'
import OrderReportChart from './OrderReportChart'

const Dashboard:FC = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-md-8 col-12'>
          {/* stat card section  */}
          <div className='row g-4'>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Total SKU's</p>
                  <p className='fs-5 fw-bold mb-0'>9999</p>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Primary Channel</p>
                  <p className='fs-5 fw-bold mb-0'>Shopify</p>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Synced SKU's</p>
                  <p className='fs-5 fw-bold mb-0'>9999</p>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 col-sm-6 '>
                <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary '>
                  <p className='h5 mb-0 text-secondary'>Order Sync Errors</p>
                  <p className='fs-5 fw-bold mb-0'>9999</p>
                </div>
              </div>
          </div>
          {/* stat card section  */}
          <div className="chart-section mt-4">
            <OrderReportChart className='' />
          </div>
        </div>
        <div className='col-md-4 col-12'>
          <div className='card shadow border-0 mb-2' >
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip'>
              <div className='ribbon-label'> <p className='fs-5 fw-bold mb-0' >News & Updates</p>
              <span className="ribbon-inner bg-primary"></span>
              </div>
            </div>
            <div className='card-body px-2' >
              <ol>
                {
                    [1,2,3,4,5].map((item)=>{
                        return <li key={'newsitem'+item} className='fs-5 my-2' >This is a sample update / news</li>
                    })
                }
              </ol>
            </div>
          </div>
          <div className='card shadow border-0 mb-2' >
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip'>
              <div className='ribbon-label'> <p className='fs-5 fw-bold mb-0' >Setup Guide</p>
              <span className="ribbon-inner bg-primary"></span>
              </div>
            </div>
            <div className='card-body px-2' >
              <ol>
                {
                    [1,2,3,4,5].map((item)=>{
                        return <li key={'newsitem'+item} className='fs-5 my-2' >This is a sample update / news</li>
                    })
                }
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
