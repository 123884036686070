import React, {FC, useEffect, useState} from 'react'
import {Alert, Button, Modal} from 'react-bootstrap'
import InputPriceSyncValues from './InputPriceSyncValues'
import InventorySyncValues from './InventorySyncValues'
import {PlatformDataType, RetrievePlatformDataType} from 'src/interfaces/Platform.interface'
import ProductSyncValues from './ProductSyncValues'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {retrivePlatformDataAsync} from 'src/redux/actions/platformActions'
import Spinner from 'react-bootstrap/Spinner'
import ShipmentSyncValues from './ShipmentSyncValues'

type ConnectedPlatformSettingModalPropsType = {
  isVisible: boolean
  closeModal: () => void

  // platformData?: RetrievePlatformDataType | null
  selectedPlatformId?: number | null
  // retrivedPlatformLoading?: boolean
}

const ConnectedPlatformSettingModal1: FC<ConnectedPlatformSettingModalPropsType> = ({
  isVisible,
  closeModal,
  // platformData,
  selectedPlatformId,
  // retrivedPlatformLoading,
}) => {
  const {retrivedPlatformLoading, retrivedPlatformData} = useAppSelector(
    (state) => state.retrivePlatformData
  )
 
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedPlatformId) {
      dispatch(retrivePlatformDataAsync({id: selectedPlatformId}))
    }
  }, [selectedPlatformId])

  return (
    <>
      <Modal fullscreen show={isVisible} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <div className='d-inline-flex'>
            <Button size='sm' className='mx-2' onClick={closeModal}>
                Close
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {retrivedPlatformLoading ? (
            <div className='d-flex align-items-center justify-content-center h-100'>
              <h1>
                <Spinner animation='border' variant='primary' />
              </h1>
            </div>
          ) : (
            <div>
              <div className='card m-2 p-4 shadow'>
                <div>
                  <p className='h3'>Platform Connection</p>
                </div>
                <div className='border-bottom my-2 border-2'></div>
                <div className='card m-2 p-4 '>
                  <div className=' my-2 row  align-items-center'>
                    <p className='h4 mb-0 col-5 col-sm-2'>{retrivedPlatformData?.name ? retrivedPlatformData.name.charAt(0).toUpperCase() + retrivedPlatformData.name.slice(1).toLowerCase()+" " : ''}
                           Store Friendly Name:</p>
                    <span className=' col-6 col-sm-8'>
                      {' '}
                      <h4 className='mb-0'>{retrivedPlatformData?.platform_info?.display_name}</h4>
                    </span>
                  </div>
                  <div className=' my-2 row  align-items-center'>
                    <p className='h4 mb-0  col-5 col-sm-2 '>Status:</p>
                    <span
                      className={`badge col-5 col-sm-2 ms-2 ms-sm-3 d-flex justify-content-center ${
                        retrivedPlatformData?.isConnected ? 'badge-success' : 'badge-danger'
                      }`}
                      style={{fontSize: '1.3rem', borderRadius: '6px'}}
                    >
                      {retrivedPlatformData?.isConnected ? 'Connected' : 'Not Connected'}
                    </span>
                  </div>
                  {retrivedPlatformData?.code === 'SHOPIFY' && (
                    <div className=' my-2 row  align-items-center'>
                      <p className='h4 col-5 col-sm-2 mb-0 '>
                        {retrivedPlatformData?.platform_info?.display_name} Url:
                      </p>
                      <span className='col-6 col-sm-8 '>
                        {' '}
                        <a
                          href={retrivedPlatformData?.credentials?.identifier}
                          className='h4 mb-0 text-underline blue' // Optional: removes underline
                        >
                          {retrivedPlatformData?.credentials?.identifier}
                        </a>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConnectedPlatformSettingModal1
