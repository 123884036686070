import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {Button} from 'react-bootstrap' // Added import for Button

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  onClick?: () => void // Added optional onClick prop
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  onClick, // Added onClick prop
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      {onClick ? ( // Render Button if onClick is provided
        <Button
          onClick={onClick}
          variant='link'
          className='menu-item ms-3 d-flex '
          style={{textDecoration: 'none', color: 'white', fontWeight: 'bold'}} // Added styles
        >
          {icon && (
            <span className='menu-icon '>
              {/* Assuming you have an icon rendering mechanism */}
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}
          {/* <span className='menu-title ms-2' style={{fontSize: '16px', paddingTop: '-10px'}}> */}
          <p className='menu-title ms-2 ' style={{fontSize: '16px', paddingTop: '-10px'}}>
            {' '}
            {title}{' '}
          </p>
          {/* </span> */}
        </Button>
      ) : (
        // Render Link for navigation if no onClick
        <Link
          className={clsx('menu-link without-sub', {active: isActive})}
          to={to}
          style={{textDecoration: 'none'}}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon '>
              {' '}
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title' style={{fontSize: '16px', fontWeight: 'bold'}}>
            {title}{' '}
          </span>
        </Link>
      )}
      {children}
    </div>
  )
}

export {SidebarMenuItem}
