import {faArrowRightArrowLeft, faDeleteLeft, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Field, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {PlatformSettingDataTypesValues} from 'src/app/_interfaces/setting.interface'
import useAppToast from 'src/hooks/useAppToast'
import {
  FIELD_MAPPING_TYPE,
  PLATFORM_FIELD,
  ShippingMethodMapping,
  platformfielddata_set,
} from 'src/interfaces/Platform.interface'
import {
  deleteFieldMappingAsync,
  getFieldMappingAsync,
  getPlatformFieldsAsync,
  getPlatformFieldsForMainStoreAsync,
  saveFieldMappingAsync,
} from 'src/redux/actions/productActions'
import {getPlatformParentFieldDataCategoriesAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import InfoTooltip from './InfoTooltip'
import Loader from 'src/utils/Loader'

const MappingInput: FC<{
  sourceSide?:number
  destinationSide?:number
  selectedPlatformId: number
  isMappingVisible: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  field_mappings: FIELD_MAPPING_TYPE
}> = ({selectedPlatformId, handleChange, field_mappings, isMappingVisible,sourceSide,destinationSide}) => {
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [platformFields, setPlatformFields] = useState<{
    sourcePlatformField: null | PLATFORM_FIELD
    destinationPlatformField: null | PLATFORM_FIELD
  }>({
    sourcePlatformField: null,
    destinationPlatformField: null,
  })
  const [shipmentMappingFieldData, setShipmentMappingFieldData] = useState<{
    destinationPlatformFieldData: platformfielddata_set[] | []
    sourcePlatformFieldData: platformfielddata_set[] | []
  }>({sourcePlatformFieldData: [], destinationPlatformFieldData: []})

  const [shippingMethodMappings, setShippingMethodMappings] = useState<ShippingMethodMapping[]>([
    {
      id: null,
      source_platform_data_id: '',
      destination_platform_data_id: '',
      destination_platform_data_str: '',
      source_platform_data_str: '',
    },
  ])
  const [mainStoreLocationsFieldData, setMainStoreLocationsFieldData] = useState<
    platformfielddata_set[] | []
  >([])

  const [loadingMappingFields, setLoadingMappingFields] = useState(true)
  const [loadingShippingMappingFieldData, setLoadingShippingMappingFieldData] = useState(true)
  const [loadingMappingSave, setLoadingMappingSave] = useState(false)
  const [loadingSelectOption, setLoadingSelectOption] = useState(true)
  const [loadingLocationOption, setLoadingLocationOption] = useState(false)
  const [singleValueForDestination,setSingleValueForDestination]=useState<string>('')

  const dispatch = useAppDispatch()
  const {errorToast, successToast, warningToast} = useAppToast()

  //for getting saved values
  useEffect(() => {
    setLoadingMappingFields(true)
    if (selectedPlatformId) {
      dispatch(
        getFieldMappingAsync({
          // destination_platform_id: destinationSide,
          // field_mappings.destination_field_owner==='MAIN_STORE' ? Number(mainStoreData?.id) : Number(selectedPlatformId),
          source_field_id: platformFields.sourcePlatformField?.id ? platformFields.sourcePlatformField.id : null ,
          destination_field_id: platformFields.destinationPlatformField?.id ? platformFields.destinationPlatformField.id : null
        })
      )
        .then((response) => {
          console.log('shipmentFieldMappings:', response)
          console.log(
            ' response?.payload?.data?.fieldmappingdata_set--',
            response?.payload[0]?.fieldmappingdata_set
          )
          if(response?.payload?.length){
            const mappings = response?.payload[0]?.fieldmappingdata_set.map((item) => ({
              id: item?.id,
              destination_platform_data_id: item?.destination_platform_data?.id + '' ?? '',
              source_platform_data_id: item?.source_platform_data?.id + '' ?? '',
              destination_platform_data_str: item.destination_platform_data_str ?? '',
              source_platform_data_str: item.source_platform_data_str ?? '',
            }))
            setShippingMethodMappings(mappings)
            setLoadingMappingFields(false)

            if( platformFields.sourcePlatformField === null){
              setShippingMethodMappings([mappings[0]])
            }

          }
          setLoadingMappingFields(false)
        })
        .catch((error) => {
          console.error('Error fetching field mappings:', error)
        })
    }
  }, [selectedPlatformId, destinationSide, platformFields])

  //for getting platform fields and fields data
  useEffect(() => {

    if (sourceSide && destinationSide) {
      setLoadingShippingMappingFieldData(true)
      Promise.all([
        dispatch(
          getPlatformFieldsAsync({      //call for get platform field for source side
            platformId:Number(sourceSide),
            otherParams: {field_code: field_mappings?.field_code},
          })
        ).then((response) => {
          if (response.payload?.length) {
            setPlatformFields((pre) => {
              return {...pre, sourcePlatformField: response.payload[0]}
            })
            dispatch(
              getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
            ).then((response) => {
              setShipmentMappingFieldData((pre) => {
                return {...pre, sourcePlatformFieldData: response.payload}
              })
            })
          }else{
            // setLoadingLocationOption(false)
          }
        }),

       
        dispatch(
          getPlatformFieldsAsync({    //call for get platform field for destination side
            platformId:Number(destinationSide),
            otherParams: {field_code: field_mappings?.field_code},
          })
        ).then((response) => {
          if (response.payload?.length) {
            setPlatformFields((pre) => {
              return {...pre, destinationPlatformField: response.payload[0]}
            })
            dispatch(
              getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
            ).then((response) => {
              setShipmentMappingFieldData((pre) => {
                return {...pre, destinationPlatformFieldData: response.payload}
              })
         
            })
          
          }
      
        }),

      ]).finally(()=>{
        setLoadingShippingMappingFieldData(false)
      })
    }
  }, [isMappingVisible])

  const handleSaveShipmentMappings = (values: ShippingMethodMapping[], setSubmitting) => {
    console.log(values)
    setLoadingMappingSave(true)

    let mapping_for_save = values.map((item) => {
      if (item.id) {
        return item
      } else {
        return {
          source_platform_data_id: item?.source_platform_data_id,
          destination_platform_data_id: item?.destination_platform_data_id,
          source_platform_data_str: item?.source_platform_data_str,
          destination_platform_data_str: item?.destination_platform_data_str,
        }
      }
    })

    if (!platformFields.destinationPlatformField?.id) {
      return warningToast('Destination field is required')
    }

    if (!platformFields.sourcePlatformField?.id) {
      return warningToast('Source field is required')
    }

    dispatch(
      saveFieldMappingAsync({
        source_field_id: platformFields.sourcePlatformField?.id!,
        destination_field_id: platformFields.destinationPlatformField?.id!,
        fieldmappingdata_set: mapping_for_save,
      })
    ).then(() => {
      setLoadingMappingSave(false)
      setLoadingMappingFields(true)
      dispatch(
        getFieldMappingAsync({
          source_field_id: platformFields.sourcePlatformField?.id ? platformFields.sourcePlatformField.id : null ,
          destination_field_id: platformFields.destinationPlatformField?.id ? platformFields.destinationPlatformField.id : null
        })
      ).then((response) => {
        console.log('shipmentFieldMappings:', response)
        console.log(
          ' response?.payload?.data?.fieldmappingdata_set--',
          response?.payload[0]?.fieldmappingdata_set
        )
        if(response?.payload?.length){
          const mappings = response?.payload[0]?.fieldmappingdata_set.map((item) => ({
            id: item?.id,
            destination_platform_data_id: item?.destination_platform_data?.id + '' ?? '',
            source_platform_data_id: item?.source_platform_data?.id + '' ?? '',
            destination_platform_data_str: item.destination_platform_data_str ?? '',
            source_platform_data_str: item.source_platform_data_str ?? '',
          }))
          setShippingMethodMappings(mappings)
          setLoadingMappingFields(false)
          setLoadingMappingSave(false)

        }
        setLoadingMappingFields(false)
        setLoadingMappingSave(false)
      })
    })
  }

  const handleSingleValues=(values: ShippingMethodMapping[], setSubmitting)=>{
    setLoadingLocationOption(true)
    dispatch(
      saveFieldMappingAsync({
        source_field_id: null,
        destination_field_id: platformFields.destinationPlatformField?.id!,
        fieldmappingdata_set:[ {
          id: values.length ? values[0].id : null,
          source_platform_data_id: null,
          destination_platform_data_id: values.length ? values[0].destination_platform_data_id : '',
          source_platform_data_str: '',
          destination_platform_data_str: '',
        }]
      })
    ).then(() => {
        setLoadingLocationOption(false)
      setLoadingMappingFields(true)
      dispatch(
        getFieldMappingAsync({
          source_field_id: platformFields.sourcePlatformField?.id ? platformFields.sourcePlatformField.id : null ,
          destination_field_id: platformFields.destinationPlatformField?.id ? platformFields.destinationPlatformField.id : null
        })
      ).then((response) => {
        console.log('single values save', response.payload);
        
        if(response.payload?.length){
          const mappings = response?.payload[0]?.fieldmappingdata_set.map((item) => ({
            id: item?.id ?? null ,
            destination_platform_data_id: item?.destination_platform_data?.id + '' ?? '',
            source_platform_data_id: item?.source_platform_data?.id + '' ?? '',
            destination_platform_data_str: item.destination_platform_data_str ?? '',
            source_platform_data_str: item.source_platform_data_str ?? '',
          }))
          
          if( platformFields.sourcePlatformField === null){
            setShippingMethodMappings([mappings[0]])
          }
        }
        
        setLoadingMappingFields(false)
        setLoadingLocationOption(false)
      })
    })
  }

  
  // if (field_mappings?.source_field_code === null) {
  //   if (loadingLocationOption) return <Loader />
  //   return (
  //     <>
  //       <div className='col-12 col-sm-6'>
  //         <label className='form-label' htmlFor='inventory_setting.default_location'>
  //           Set Default Location <InfoTooltip message='Set default location for inventory' />
  //         </label>
  //         <select
  //           value={singleValueForDestination}
  //           onChange={
  //             (e) => {
  //               setSingleValueForDestination(e.target.value)
  //           }
  //         }
  //           className='form-select form-select-lg'
  //         >
  //           <option value=''>Select Location</option>
  //           {mainStoreLocationsFieldData.map((item) => {
  //             return (
  //               <option key={item.id} value={item.id}>
  //                 {item.api_name}
  //               </option>
  //             )
  //           })}
  //         </select>
  //       </div>
  //       <div className='col-12 col-sm-6 text-end'>
  //         <button
  //           type='button'
  //           form='location _mapping'
  //           className='btn btn-success btn-sm mt-2 me-3 align-self-end'
  //           onClick={() => handleSingleValues()}
  //         >
  //           {loadingLocationOption ? 'Saving' : 'Save Shipment'}
  //         </button>
  //       </div>
  //     </>
  //   )
  // } else {
    console.log('load shippingMethodMappings', shippingMethodMappings)
    if (loadingShippingMappingFieldData || loadingMappingFields) return <Loader />
    return (
      <Formik
        key={shippingMethodMappings?.length}
        initialValues={{mappings: shippingMethodMappings}}
        onSubmit={(values) => {
          console.log('Form values:', values)

          //   let mapping_for_save = values.mappings.map((item) => {
          //     if (item.id) {
          //       return item
          //     } else {
          //       return {
          //         source_platform_data_id: item?.source_platform_data_id,
          //         destination_platform_data_id: item?.destination_platform_data_id,
          //         source_platform_data_str: item?.source_platform_data_str,
          //         destination_platform_data_str: item?.destination_platform_data_str,
          //       }
          //     }
          //   })

          //   if (!platformFields.selectedPlatformOfPlatformField?.id) {
          //     warningToast('Destination field is required')
          //   }

          //   if (!platformFields.mainStorePlatformField?.id) {
          //     warningToast('Source field is required')
          //   }

          //   dispatch(
          //     saveFieldMappingAsync({
          //       source_field_id: platformFields.mainStorePlatformField?.id!,
          //       destination_field_id: platformFields.selectedPlatformOfPlatformField?.id!,
          //       fieldmappingdata_set: mapping_for_save,
          //     })
          //   ).then(() => {
          //     dispatch(getFieldMappingAsync({destination_platform_id: selectedPlatformId}))
          //   })
        }}
      >
        {({values, handleChange, handleSubmit, setFormikState, setSubmitting}) => (
          <form
            id='mapping_form'
            onSubmit={(ev) => {
              ev.preventDefault()
              handleSubmit(ev)
            }}
          >
            <div className='row'>
              <div className='mapping'>
                <div className='p-1 '>
                  <div>
                    <label className='form-label'>{field_mappings?.display_name}</label>
                  </div>
                  <div className='border-bottom my-2 border-2 my-2'></div>
                  {values.mappings?.length
                    ? values.mappings?.map((mapping, index) => {
                        return (
                          <div key={index} className='mb-4 p-2'>
                            <div className='row'>
                           {platformFields.sourcePlatformField? ( platformFields.sourcePlatformField?.input_type === 'SELECT' ? (
                                <div className='col-12 col-sm-5'>
                                  <div className=''>
                                    <label
                                      htmlFor={`primaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Primary Store Method
                                    </label>
                                    <select
                                      className='form-select form-select-lg mt-2'
                                      id='primaryStoreShippingMethod'
                                      name={`mappings[${index}][source_platform_data_id]`}
                                      value={mapping?.source_platform_data_id}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          source_platform_data_id: ev.target.value,
                                        }

                                        if (
                                          platformFields.destinationPlatformField
                                            ?.input_type === 'SELECT'
                                        ) {
                                          val.destination_platform_data_id =
                                            mapping.destination_platform_data_id
                                        } else {
                                          val.destination_platform_data_str =
                                            mapping.destination_platform_data_str
                                        }

                                        let shadowArrayMapping = [...values.mappings]
                                        shadowArrayMapping[index] = val

                                        setFormikState((prevState) => ({
                                          ...prevState,
                                          values: {
                                            ...prevState.values,
                                            mappings: [...shadowArrayMapping],
                                          },
                                        }))
                                      }}
                                    >
                                      <option value=''>Open this select menu</option>
                                      {shipmentMappingFieldData
                                        ?.sourcePlatformFieldData?.length &&
                                        shipmentMappingFieldData.sourcePlatformFieldData.map(
                                          (item: platformfielddata_set) => {
                                            return <option value={item.id}>{item.api_name}</option>
                                          }
                                        )}
                                    </select>
                                  </div>
                                </div>
                              ) : (
                                <div className='col-12 col-sm-5'>
                                  <div className=''>
                                    <label
                                      htmlFor={`primaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Primary Store Method
                                    </label>
                                    <input
                                      type='text'
                                      placeholder='Add a method'
                                      className='form-control form-control-sm mt-2'
                                      name={`mappings[${index}][source_platform_data_str]`}
                                      value={mapping.source_platform_data_str}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          source_platform_data_str: ev.target.value,
                                        }
                                        if (
                                          platformFields.destinationPlatformField
                                            ?.input_type === 'SELECT'
                                        ) {
                                          val.destination_platform_data_id =
                                            mapping.destination_platform_data_id
                                        } else {
                                          val.destination_platform_data_str =
                                            mapping.destination_platform_data_str
                                        }
                                        let shadowArrayMapping = [...values.mappings]
                                        shadowArrayMapping[index] = val

                                        setFormikState((prevState) => ({
                                          ...prevState,
                                          values: {
                                            ...prevState.values,
                                            mappings: [...shadowArrayMapping],
                                          },
                                        }))
                                      }}
                                    />
                                  </div>
                                </div>
                              )):null}
                           {  platformFields.sourcePlatformField ?( <div className='col-12 col-sm-1 my-2 my-sm-0'>
                                <div className='d-flex justify-content-center align-items-center h-100'>
                                  <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                                </div>
                              </div>):null}
                              { platformFields.destinationPlatformField? platformFields.destinationPlatformField?.input_type ===
                              'SELECT' ? (
                                <div className='col-12 col-sm-5'>
                                  <div className=''>
                                    <label
                                      htmlFor={`secondaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Secondary Store Method
                                    </label>
                                    <select
                                      className='form-select form-select-lg mt-2'
                                      id='secondaryStoreShippingMethod'
                                      name={`mappings[${index}][destination_platform_data_id]`}
                                      value={mapping?.destination_platform_data_id}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          destination_platform_data_id: ev.target.value,
                                        }
                                        if (
                                          platformFields.sourcePlatformField?.input_type ===
                                          'SELECT'
                                        ) {
                                          val.source_platform_data_id =
                                            mapping.source_platform_data_id
                                        } else {
                                          val.source_platform_data_str =
                                            mapping.source_platform_data_str
                                        }
                                        let shadowArrayMapping = [...values.mappings]
                                        shadowArrayMapping[index] = val

                                        setFormikState((prevState) => ({
                                          ...prevState,
                                          values: {
                                            ...prevState.values,
                                            mappings: [...shadowArrayMapping],
                                          },
                                        }))
                                      }}
                                    >
                                      <option value=''>Open this select menu</option>
                                      {shipmentMappingFieldData
                                        ?.destinationPlatformFieldData?.length &&
                                        shipmentMappingFieldData.destinationPlatformFieldData?.map(
                                          (item: platformfielddata_set) => {
                                            return <option value={item.id}>{item.api_name}</option>
                                          }
                                        )}
                                    </select>
                                  </div>
                                </div>
                              ) : (
                                <div className='col-12 col-sm-5'>
                                  <div className=''>
                                    <label
                                      htmlFor={`secondaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Secondary Store Method
                                    </label>
                                    <input
                                      type='text'
                                      placeholder='Add a method'
                                      className='form-control form-control-lg mt-2'
                                      name={`mappings[${index}][destination_platform_data_str]`}
                                      value={mapping.destination_platform_data_str}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          destination_platform_data_str: ev.target.value,
                                        }
                                        if (
                                          platformFields.sourcePlatformField?.input_type ===
                                          'SELECT'
                                        ) {
                                          val.source_platform_data_id =
                                            mapping.source_platform_data_id
                                        } else {
                                          val.source_platform_data_str =
                                            mapping.source_platform_data_str
                                        }
                                        let shadowArrayMapping = [...values.mappings]
                                        shadowArrayMapping[index] = val

                                        setFormikState((prevState) => ({
                                          ...prevState,
                                          values: {
                                            ...prevState.values,
                                            mappings: [...shadowArrayMapping],
                                          },
                                        }))
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null }
                              {index !== 0 && values.mappings?.length > 1 ? (
                                <div className='align-content-end col-12 col-sm-1'>
                                  <div className='d-flex justify-content-end'>
                                    <button
                                      type='button'
                                      onClick={() => {
                                        let copyMppings = [...values.mappings]
                                        if (mapping.id) {
                                          dispatch(
                                            deleteFieldMappingAsync({mappingDataId: mapping.id})
                                          ).then((response) => {
                                            successToast(response.payload.message)

                                            setLoadingMappingFields(true)
                                            dispatch(
                                              getFieldMappingAsync({
                                                source_field_id: platformFields.sourcePlatformField?.id ? platformFields.sourcePlatformField.id : null ,
                                                destination_field_id: platformFields.destinationPlatformField?.id ? platformFields.destinationPlatformField.id : null
                                              })
                                            ).then((response) => {
                                              console.log('shipmentFieldMappings:', response)
                                              console.log(
                                                ' response?.payload?.data?.fieldmappingdata_set--',
                                                response?.payload[0]?.fieldmappingdata_set
                                              )
                                              const mappings =
                                                response?.payload[0]?.fieldmappingdata_set.map(
                                                  (item) => ({
                                                    id: item?.id,
                                                    destination_platform_data_id:
                                                      item?.destination_platform_data?.id + '' ??
                                                      '',
                                                    source_platform_data_id:
                                                      item?.source_platform_data?.id + '' ?? '',
                                                    destination_platform_data_str:
                                                      item.destination_platform_data_str ?? '',
                                                    source_platform_data_str:
                                                      item.source_platform_data_str ?? '',
                                                  })
                                                )
                                              setShippingMethodMappings(mappings)
                                              setLoadingMappingFields(false)
                                            })
                                          })
                                        } else {
                                          let filteredMappings = copyMppings.filter((it, ind) => {
                                            if (ind !== index) {
                                              return it
                                            }
                                          })
                                          setFormikState((prevState) => ({
                                            ...prevState,
                                            values: {
                                              ...prevState.values,
                                              mappings: [...filteredMappings],

                                              //   selectedTagValues: newValue,
                                            },
                                          }))
                                        }
                                      }}
                                      className='btn btn-sm mt-2'
                                      style={{color: 'red'}}
                                    >
                                      <FontAwesomeIcon
                                        icon={faDeleteLeft}
                                        style={{fontSize: '20px'}}
                                      />
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                      })
                    : null}
                 { platformFields.sourcePlatformField ?
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-primary btn-sm me-3'
                      type='button'
                      onClick={() => {
                        let newItem: ShippingMethodMapping = {id: null}
                        if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                          newItem.source_platform_data_id = ''
                        } else {
                          newItem.source_platform_data_str = ''
                        }

                        if (
                          platformFields.destinationPlatformField?.input_type === 'SELECT'
                        ) {
                          newItem.destination_platform_data_id = ''
                        } else {
                          newItem.destination_platform_data_str = ''
                        }

                        let shadowArrayMapping = [...values.mappings]
                        shadowArrayMapping.push(newItem)

                        setFormikState((prevState) => ({
                          ...prevState,
                          values: {
                            ...prevState.values,
                            mappings: [...shadowArrayMapping],

                            //   selectedTagValues: newValue,
                          },
                        }))
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>{' '}
                  </div> : null
                  }

                  { platformFields.sourcePlatformField || platformFields.destinationPlatformField ? 
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      form='mapping_form'
                      className='btn btn-success btn-sm mt-2 me-3 align-self-end'
                      onClick={() => !platformFields.sourcePlatformField ? handleSingleValues(values.mappings, setSubmitting):   handleSaveShipmentMappings(values.mappings, setSubmitting)}
                    >
                      {loadingMappingSave || loadingLocationOption? 'Saving...' : 'Save Mapping'}
                    </button>
                  </div> : null }
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  
}

export default MappingInput
