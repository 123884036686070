/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from 'src/marketConnect/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 '>

      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50  order-2 order-lg-1 overflow-lg-scroll' >
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5 mb-4'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='https://constacloud.com/tou.html' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='https://constacloud.com/privacy-policy.html' className='px-5' target='_blank'>
              Policy
            </a>

            <a href='https://constacloud.com/contact.html' className='px-5' target='_blank'>
              Contact us
            </a>
          </div>
          {/* end::Links */}
        </div>
    
       
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 overflow-lg-scroll'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100 '>
          {/* begin::Logo */}
          <Link to='/' className=''>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/commercium-logo-white.png')} className='h-75px'/>
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-300px mb-10 mb-lg-5'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1>
              <div className='d-flex justify-content-center mt-11 mb-1'>
        <div className='text-white order-2 order-md-1 '>
        <span className='text-white  fw-semibold me-1 fw-bold'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <a
          href='https://constacloud.com/'
          target='_blank'
          className='text-white fw-bold'
        >
          ConstaCloud Private Limited
        </a>
      </div>
              </div>
          {/* end::Title */}

          {/* begin::Text */}
          {/* <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the interview.
          </div> */}
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
