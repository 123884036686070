import React, {useEffect, useState} from 'react'
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses'
import { useParams } from 'react-router-dom'
import { UserSubscriptionPlan } from 'src/interfaces/Platform.interface'
import {cancelSubscriptionAsync, getAllPlansAsync, getUserPlanAsync, requestSubscriptionAsync, updateSubscriptionAsync} from 'src/redux/actions/pricingActions'
import {useAppDispatch} from 'src/redux/storeHooks'

const Pricing = () => {
  const [billingPeriod, setBillingPeriod] = useState('month')
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [listOfPlans,setListOfPlans]=useState<any[]>([])
  const [userSubscribedPlan,setUserSubscriptionPlan]=useState<UserSubscriptionPlan>()
  const platformId =useParams()
  const dispatch = useAppDispatch()

  const handleBillingPeriodChange = (period) => {
    setBillingPeriod(period)
    setSelectedPlanId(null) // Reset selected plan when billing period changes
  }

  const handlePlanSelect = (planId) => {
    setSelectedPlanId(planId)
  }

  useEffect(() => {
    dispatch(getAllPlansAsync()).then((response)=>{
      console.log("list of plan",response)
      setListOfPlans(response.payload)

    })

    dispatch(getUserPlanAsync()).then((response)=>{
      console.log("list of  userplan",response)
      setUserSubscriptionPlan(response.payload)
    })

  }, [])
  

  // const plans =
  
  //   [
  //     {
  //       id: 1,
  //       plan_name: 'Standard',
  //       features: [
  //         {
  //           id: 1,
  //           name: 'Auto-Repricing',
  //           display_name: 'Auto - Repricing',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 1,
  //           show: true,
  //         },
  //         {
  //           id: 2,
  //           name: 'Tech-Support',
  //           display_name: 'Tech - Support',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 1,
  //           show: false,
  //         },
  //       ],
  //       plan_id: 'standard_001',
  //       display_name: 'Standard plan',
  //       platform_limit: 10,
  //       product_limit: 100,
  //       order_limit: 100,
  //       description: 'This is a description',
  //       trialPeriodDays: 15,
  //       billing_frequency: 'EVERY_30_DAYS',
  //       created_at: '2024-04-02T14:28:08Z',
  //       updated_at: '2024-04-02T14:28:08Z',
  //       price: {
  //         type: 'PRICE',
  //         value: 1,
  //         currency_iso: 'INR',
  //       },
  //       showcase_price: {
  //         type: 'PRICE',
  //         value: 1,
  //         currency_iso: 'INR',
  //       },
  //     },
  //     {
  //       id: 2,
  //       plan_name: 'Premium',
  //       plan_id: 'premium_001',
  //       features: [
  //         {
  //           id: 1,
  //           name: 'Auto-Repricing',
  //           display_name: 'Auto - Repricing',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 2,
  //           show: true,
  //         },
  //         {
  //           id: 2,
  //           name: 'Tech-Support',
  //           display_name: 'Tech - Support',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 2,
  //           show: false,
  //         },
  //       ],
  //       display_name: 'Premium plan',
  //       platform_limit: 50,
  //       product_limit: 1000,
  //       order_limit: 1000,
  //       description: 'This is a description',
  //       trialPeriodDays: 15,
  //       billing_frequency: 'EVERY_30_DAYS',
  //       created_at: '2024-04-02T14:28:08Z',
  //       updated_at: '2024-04-02T14:28:08Z',
  //       price: {
  //         type: 'PRICE',
  //         value: 2,
  //         currency_iso: 'INR',
  //       },
  //       showcase_price: {
  //         type: 'PRICE',
  //         value: 2,
  //         currency_iso: 'INR',
  //       },
  //     },
  //     {
  //       id: 3,
  //       plan_name: 'FREE_FOREVER',
  //       plan_id: 'FREE_FOREVER',
  //       features: [
  //         {
  //           id: 1,
  //           name: 'Auto-Repricing',
  //           display_name: 'Auto - Repricing',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 3,
  //           show: true,
  //         },
  //         {
  //           id: 2,
  //           name: 'Tech-Support',
  //           display_name: 'Tech - Support',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 3,
  //           show: false,
  //         },
  //       ],
  //       display_name: 'Basic free plan',
  //       platform_limit: 1,
  //       product_limit: 10,
  //       order_limit: 10,
  //       description: 'This is a description',
  //       trialPeriodDays: 0,
  //       billing_frequency: 'EVERY_30_DAYS',
  //       created_at: '2024-07-15T16:39:20Z',
  //       updated_at: '2024-07-15T16:39:20Z',
  //       price: {
  //         type: 'PRICE',
  //         value: 0,
  //         currency_iso: 'INR',
  //       },
  //       showcase_price: {
  //         type: 'PRICE',
  //         value: 0,
  //         currency_iso: 'INR',
  //       },
  //     },
  //     {
  //       id: 4,
  //       plan_name: 'ENTERPRIZE',
  //       plan_id: 'ENTERPRIZE',
  //       features: [
  //         {
  //           id: 1,
  //           name: 'Auto-Repricing',
  //           display_name: 'Auto - Repricing',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 4,
  //           show: true,
  //         },
  //         {
  //           id: 2,
  //           name: 'Tech-Support',
  //           display_name: 'Tech - Support',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 4,
  //           show: false,
  //         },
  //       ],
  //       display_name: 'ENTERPRIZE PLAN',
  //       platform_limit: 1,
  //       product_limit: 10,
  //       order_limit: 10,
  //       description: 'This is a description',
  //       trialPeriodDays: 10,
  //       billing_frequency: 'EVERY_30_DAYS',
  //       created_at: '2024-07-15T16:39:20Z',
  //       updated_at: '2024-07-15T16:39:20Z',
  //       price: {
  //         type: 'PRICE',
  //         value: 4,
  //         currency_iso: 'INR',
  //       },
  //       showcase_price: {
  //         type: 'PRICE',
  //         value: 4,
  //         currency_iso: 'INR',
  //       },
  //     },
  //     {
  //       id: 5,
  //       plan_name: 'ENTERPRIZE2',
  //       plan_id: 'ENTERPRIZE2 PLAN',
  //       features: [
  //         {
  //           id: 1,
  //           name: 'Auto-Repricing',
  //           display_name: 'Auto - Repricing',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 5,
  //           show: true,
  //         },
  //         {
  //           id: 2,
  //           name: 'Tech-Support',
  //           display_name: 'Tech - Support',
  //           platform_limit: 1,
  //           product_limit: 100,
  //           order_limit: 100,
  //           created_at: '2024-07-19T11:35:11Z',
  //           updated_at: '2024-07-19T11:35:11Z',
  //           billing_plan: 5,
  //           show: false,
  //         },
  //       ],
  //       display_name: 'ENTERPRIZE2',
  //       platform_limit: 1,
  //       product_limit: 10,
  //       order_limit: 10,
  //       description: 'This is a description',
  //       trialPeriodDays: 10,
  //       billing_frequency: 'EVERY_30_DAYS',
  //       created_at: '2024-07-15T16:39:20Z',
  //       updated_at: '2024-07-15T16:39:20Z',
  //       price: {
  //         type: 'PRICE',
  //         value: 5,
  //         currency_iso: 'INR',
  //       },
  //       showcase_price: {
  //         type: 'PRICE',
  //         value: 5,
  //         currency_iso: 'INR',
  //       },
  //     },
  //   ]

  // const plans=[
  //   {
  //     "id": 1,
  //     "plan_name": "Standard",
  //     "plan_id": "standard_001",
  //     "display_name": "Starter - Standard plan",
  //     "platform_limit": 10,
  //     "product_limit": 100,
  //     "order_limit": 100,
  //     "description": "This is a description",
  //     "trialPeriodDays": 15,
  //     "billing_frequency": "EVERY_30_DAYS",
  //     "created_at": "2024-04-02T14:28:08Z",
  //     "updated_at": "2024-04-02T14:28:08Z",
  //     "price": 1,
  //     "showcase_price": 1
  // }
  // ]

  // const userSubscribedPlan = {
  //   billing_plan: {
  //     plan_name: 'Standard',
  //     plan_id: 'standard_001',
  //     platform_limit: 10,
  //     product_limit: 100,
  //     display_name: 'Starter - Standard plan',
  //     description: 'This is a description',
  //     billing_frequency: 'EVERY_30_DAYS',
  //     price: {
  //       type: 'PRICE',
  //       value: 1,
  //       currency_iso: 'INR',
  //     },
  //     showcase_price: {
  //       type: 'PRICE',
  //       value: 1,
  //       currency_iso: 'INR',
  //     },
  //     created_at: '2024-04-02T14:28:08Z',
  //     updated_at: '2024-04-02T14:28:08Z',
  //   },
  //   isActive: true,
  //   valid_till: null,
  //   activated_on: null,
  //   subscription_id:'',
  // }

  const planCardStyle = (isSelected: boolean): React.CSSProperties => ({
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: isSelected ? '#f0f8ff' : '#f9f9f9',
    border: isSelected ? '2px solid #007bff' : '1px solid #ddd',
    zIndex: isSelected ? 10 : 1, // Adjust the values as needed
  })

  return (
    <div>
      <div className='d-flex flex-column flex-column-fluid'>
        <div id='kt_app_toolbar' className='app-toolbar py-0 py-lg-0'>
          <div id='kt_app_toolbar_container' className='app-container d-flex flex-stack'>
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              <h1 className='page-heading d-flex text-gray fw-bold fs-3 flex-column justify-content-center my-0'>
                Pricing
              </h1>
            </div>
          </div>
        </div>
        <div id='kt_app_content' className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className='app-container px-sm-0'>
            <div className='card' id='kt_pricing'>
              <div className='card-body p-lg-4'>
                <div className='d-flex flex-column'>
                  <div className='text-center'>
                    <h1 className='fs-2hx fw-bold mb-2'>Choose Your Plan</h1>
                  </div>
                  <div
                    className='nav-group nav-group-outline mx-auto mb-7'
                    data-kt-buttons='true'
                    data-kt-initialized='1'
                  >
                    <button
                      className={`btn  btn-active px-6 py-3 me-2 ${
                        billingPeriod === 'month' ? 'active' : ''
                      }`}
                      data-kt-plan='month'
                      data-fdprocessedid='ntglqa'
                      onClick={() => handleBillingPeriodChange('month')}
                      style={{
                        backgroundColor: billingPeriod === 'month' ? '#D3D3D3' : 'transparent', // Light gray color if active
                        borderColor: billingPeriod === 'month' ? '#D3D3D3' : '#ddd', // Light gray color if active
                        color: billingPeriod === 'month' ? '#000' : '#000', // Text color
                      }}
                    >
                      Monthly
                    </button>
                    <button
                      className={`btn btn-color-gray-600 btn-active  px-6 py-3 ${
                        billingPeriod === 'annual' ? 'active' : ''
                      }`}
                      data-kt-plan='annual'
                      data-fdprocessedid='g5m52i'
                      onClick={() => handleBillingPeriodChange('annual')}
                      style={{
                        backgroundColor: billingPeriod === 'annual' ? '#D3D3D3' : 'transparent', // Light gray color if active
                        borderColor: billingPeriod === 'annual' ? '#D3D3D3' : '#ddd', // Light gray color if active
                        color: billingPeriod === 'annual' ? '#000' : '#000', // Text color
                      }}
                    >
                      Annually 20 % Off
                    </button>
                  </div>
                  <div className='row g-10 overflow-x-auto d-flex flex-nowrap'>
                    { listOfPlans?.length  && listOfPlans?.map((plan) => (
                      <div className='col-auto mb-4' key={plan.id}>
                        <div className='d-flex h-100 align-items-center'>
                          <div
                            style={planCardStyle(selectedPlanId === plan.plan_id)}
                            className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-105 py-15 px-10'
                            onClick={() => handlePlanSelect(plan.plan_id)} // Handle plan selection on click
                          >
                            <div className='mb-5 text-center'>
                              <h1 className='text-gray-900 mb-2 fw-bolder'>{plan.display_name}</h1>
                              <div className='text-gray-600 fw-semibold mb-2'>
                                {plan.description}
                              </div>
                              <div className='text-center'>
                                <span className='mb-2 text-primary'>$</span>
                                <span className='fs-3x fw-bold text-primary'>
                                  {/* {plan?.price?.value}{' '} */}
                                  {plan?.price}
                                </span>
                                <span className='fs-7 fw-semibold opacity-50'>/Mon</span>
                              </div>
                            </div>
                            <div className='w-100 mb-10'>
                              {/* {plan.features.map((feature) => (
                                <div className='d-flex align-items-center mb-1' key={feature.id}>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                                    {feature.display_name}
                                  </span>
                                  {feature.show ? (
                                    <i className='ki-duotone ki-check-circle fs-1 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                              ))} */}
                            </div>
                            <div className='d-flex justify-content-center'>
                              {userSubscribedPlan?.billing_plan?.plan_id === plan.plan_id ? (
                                <button
                                  className='btn btn-sm btn-primary mx-2'
                                  style={{backgroundColor: 'green'}}
                                >
                                  Subscribed
                                </button>
                              ) :  plan?.price >Number( userSubscribedPlan?.billing_plan?.price?.value )? (
                                <button
                                  className='btn btn-sm btn-primary mx-2'
                                  style={{backgroundColor: 'green'}}
                                  onClick={() => {
                                    if (userSubscribedPlan?.subscription_id === '') {
                                      // Dispatch requestSubscriptionAsync if no subscription_id
                                      dispatch(requestSubscriptionAsync({
                                        plan_id: plan.plan_id,
                                        platform_id: platformId,
                                        take_free_days_trial: plan.trialPeriodDays
                                      }))
                                      .unwrap()
                                      .then((response) => {
                                        // Check if the response is successful
                                        if (response.status_code === 201 && response.data) {
                                          // Automatically open the link in a new tab
                                          window.open(response.data, '_blank');
                                        } else {
                                          // Handle error or different status codes
                                          console.error('Error response:', response);
                                          // Optionally, show a user-friendly message or perform other actions
                                        }
                                      })
                                      .catch((error) => {
                                        // Handle any errors that occur during dispatch or response handling
                                        console.error('Error during subscription request:', error);
                                        // Optionally, show a user-friendly message or perform other actions
                                      });
                                    } else {
                                      // Dispatch updateSubscriptionAsync if subscription_id is present
                                      dispatch(updateSubscriptionAsync({
                                        plan_id: plan.plan_id,
                                        platform_id: platformId,
                                        subscription_id: userSubscribedPlan?.subscription_id
                                      }))
                                      .unwrap()
                                      .then((response) => {
                                        // Check if the response is successful
                                        if (response.status_code === 201 && response.data) {
                                          // Automatically open the link in a new tab
                                          window.open(response.data, '_blank');
                                        } else {
                                          // Handle error or different status codes
                                          console.error('Error response:', response);
                                          // Optionally, show a user-friendly message or perform other actions
                                        }
                                      })
                                      .catch((error) => {
                                        // Handle any errors that occur during dispatch or response handling
                                        console.error('Error during subscription update:', error);
                                        // Optionally, show a user-friendly message or perform other actions
                                      });
                                    }
                                  }}
                                  
                                >
                                  Upgrade
                                </button>
                              ) : (
                                <button
                                className='btn btn-sm btn-primary mx-2'
                                style={{ backgroundColor: 'green' }}
                                onClick={() => {
                                  if (userSubscribedPlan?.subscription_id === '') {
                                    // Dispatch requestSubscriptionAsync if no subscription_id
                                    dispatch(requestSubscriptionAsync({
                                      plan_id: plan.plan_id,
                                      platform_id: platformId,
                                      take_free_days_trial: plan.trialPeriodDays
                                    }))
                                    .unwrap()
                                    .then((response) => {
                                      // Check if the response is successful
                                      if (response.status_code === 201 && response.data) {
                                        // Automatically open the link in a new tab
                                        window.open(response.data, '_blank');
                                      } else {
                                        // Handle error or different status codes
                                        console.error('Error response:', response);
                                        // Optionally, show a user-friendly message or perform other actions
                                      }
                                    })
                                    .catch((error) => {
                                      // Handle any errors that occur during dispatch or response handling
                                      console.error('Error during subscription request:', error);
                                      // Optionally, show a user-friendly message or perform other actions
                                    });
                                  } else {
                                    // Dispatch updateSubscriptionAsync if subscription_id is present
                                    dispatch(updateSubscriptionAsync({
                                      plan_id: plan.plan_id,
                                      platform_id: platformId,
                                      subscription_id: userSubscribedPlan?.subscription_id
                                    }))
                                    .unwrap()
                                    .then((response) => {
                                      // Check if the response is successful
                                      if (response.status_code === 201 && response.data) {
                                        // Automatically open the link in a new tab
                                        window.open(response.data, '_blank');
                                      } else {
                                        // Handle error or different status codes
                                        console.error('Error response:', response);
                                        // Optionally, show a user-friendly message or perform other actions
                                      }
                                    })
                                    .catch((error) => {
                                      // Handle any errors that occur during dispatch or response handling
                                      console.error('Error during subscription update:', error);
                                      // Optionally, show a user-friendly message or perform other actions
                                    });
                                  }
                                }}
                                
                              >
                                Downgrade
                              </button>
                              
                              
                              )}

                              {userSubscribedPlan?.billing_plan?.plan_id === plan?.plan_id &&
                              userSubscribedPlan?.isActive ? (
                                <button className='btn btn-sm btn-danger mx-2'
                                onClick={()=>{
                                  dispatch(cancelSubscriptionAsync({subscription_id:userSubscribedPlan?.subscription_id,platform_id:platformId}))
                                }

                                }
                                >
                                  Cancel Subscription
                                </button>
                              ) : (
                                plan.trialPeriodDays > 0 && (
                                  <button
                                    className='btn btn-sm btn-primary mx-2'
                                    style={{backgroundColor: 'orange'}}
                                  >
                                    Start 7-Day Free Trial
                                  </button>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
