import React, {FC, useEffect, useRef, useState} from 'react'
import {Alert, Button, Modal, Overlay, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import {
  getCategoryTemplateUsingTemplateIdAsync,
  getFieldDataAttributesAsync,
  getPlatformCategoryTemplateDataAsync,
  getPlatformChildFieldDataCategoriesAsync,
  getPlatformFieldDataAttributesAsync,
  getPlatformParentFieldDataCategoriesAsync,
  getSelectedCategoryTreeAsync,
  postFieldDataAttributesAsync,
  updateCategoryTemplateDataAsync,
} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

import {
  CATEGORY_DATA,
  CATEGORY_ITEM,
  CATEGORY_ITEM_ON_UPDATE,
  Category,
} from 'src/interfaces/Template.interface'
import Loader from 'src/utils/Loader'
import {toast} from 'react-toastify'
import InputAttributes from './InputAttributes'
import {PlatformDataType} from 'src/interfaces/Platform.interface'
import {Formik} from 'formik'

type AddNewStoreTemplateModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  selectedSecondaryPlatform: PlatformDataType | null
  getLatestCategoryTemplateList: () => void
  categoryData: CATEGORY_ITEM | null
}

const AddNewCategoryTemplateModal: FC<AddNewStoreTemplateModalPropsType> = ({
  isVisible,
  closeModal,
  selectedSecondaryPlatform,
  categoryData,
  getLatestCategoryTemplateList,
}) => {
  const [selectedDataAttributes, setSelectedDataAttributes] = useState<any>(null)
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform) || {}
  const [categories, setCategories] = useState<Category[]>([])
  const [childCategories, setChildCategories] = useState<Category[]>([])
  const [finalPayload, setFinalPayload] = useState<any>()
  const [categoryName, setCategoryName] = useState('')
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState<number[] | []>([])
  const [selectedCategoriesData, setSelectedCategoriesData] = useState<
    {[key: number]: CATEGORY_DATA[]} | {}
  >({})
  const [currentIndex, setCurrentIndex] = useState(0)
  const [selectedCategoryIdForMapping, setSelectedCategoryIdForMapping] = useState<number | null>(
    null
  )
  const [selectedCategories, setSelectedCategories] = useState<(number | null)[]>([])
  const [attributesInUpdateCase, setAttributesInUpdateCase] =
    useState<CATEGORY_ITEM_ON_UPDATE | null>(null)
  const [categoryMainId, setCategoryMainId] = useState<any>()
  const {getAttributeData, getAttributeDataLoading} = useAppSelector((state) => state.templateAndPricing)
  const [showAttributes, setShowAttributes] = useState(false)
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | null>(null)
  const {getPlatformChildFieldCategoryData,getPlatformChildFieldCategoryDataLoading, getPlatformParentFieldCategoryDataLoading} =
    useAppSelector((state) => state.templateAndPricing)
  const [categoryLoader, setCategoryLoader] = useState(true)
  const dispatch = useAppDispatch()

  const handleSubmitButtonClick = (selectedCategoryId: number | null) => {
    if (selectedCategoryId !== null) {
      dispatch(getPlatformFieldDataAttributesAsync({category_id: selectedCategoryId})).then(
        (response) => {
          setShowAttributes(true)
        }
      )
    }
  }

  const createMappingUsingSelectedCategory = () => {
    if (selectedCategoryIdForMapping !== null) {
      dispatch(
        getPlatformFieldDataAttributesAsync({category_id: selectedCategoryIdForMapping})
      ).then((response) => {
        // console.log("respones of attributes:",response)
        setShowAttributes(true)
      })
    }
  }

  const handleSaveButtonClick = () => {
    // Call the constructPayload function to create the payload with attributes
    console.log('payload final:', finalPayload)

    dispatch(postFieldDataAttributesAsync(finalPayload)).then((response) => {
      // console.log("response of post:",response)
      toast.success('Succesfully Saved')
    })
  }

  const handleAttributeSelection = (selectedAttributes) => {
    if (!selectedAttributes) {
      console.error('Attributes data not available')
      return
    }

    // Get the value entered in the input field for category name

    const payload = {
      platform_id: selectedSecondaryPlatform?.id,
      name: categoryName, // Set the category name from the input field
      category_data: selectedCategoriesIds[selectedCategoriesIds.length - 1], // Assuming last selected category
      attributes: selectedAttributes,
    }

    console.log('Payload:', payload);
    // Further actions with payload (e.g., send to API)
    setFinalPayload(payload)
  }

  useEffect(() => {
    if (isVisible) {
      setCategoryLoader(true)
      if (categoryData) {
        Promise.all([
          dispatch(
            getSelectedCategoryTreeAsync({category_data_id: categoryData.category_data.id})
          ).then((response) => {
            if (response.payload?.length) {
              let shadowSelectedIds = []
              // shadowSelectedIds = shadowSelectedIds.slice(0,index)
              shadowSelectedIds = response.payload
                .map((it) => it.id)
                .slice(1, response.payload?.length)
              setSelectedCategoriesIds(shadowSelectedIds)
              let temp = {}
              let selectedtemp = {}
              // { ...selectedCategoriesData, [fieldId]:[...response.payload] }
              setCategories([response.payload[0]])
              for (let id of response.payload) {
                if (id.id !== response.payload[0].id) {
                  temp[id.id] = [id]
                }
              }
              setSelectedCategoriesData(temp)
              // setCurrentIndex(response.payload?.length-1)
            }
          }),

          dispatch(
            getCategoryTemplateUsingTemplateIdAsync({category_temp_id: categoryData.id})
          ).then((response) => {
            // console.log('mapping response----->', response);
            setAttributesInUpdateCase(response.payload)
            dispatch(
              getPlatformFieldDataAttributesAsync({category_id: categoryData.category_data.id})
            ).then((response) => {
              // console.log("respones of attributes:",response)
              setShowAttributes(true)
            })
          }),
        ]).finally(() => {
          setCategoryLoader(false)
        })
      } else {
        if (selectedSecondaryPlatform?.id) {
          Promise.all([
            dispatch(
              getPlatformFieldsAsync({
                platformId: selectedSecondaryPlatform?.id,
                otherParams: {field_code: 'CATEGORY'},
              })
            ).then((response) => {
              // Set categoryMainId
              setCategoryMainId(response?.payload[0]?.id)
              if (!categoryData) {
                dispatch(
                  getPlatformParentFieldDataCategoriesAsync({platform_Id: response?.payload[0]?.id})
                ).then((response) => {
                  // Set categories received from API response
                  setCategories(response.payload) // Assuming the payload contains the categories
                })
              }
            }),
          ]).finally(() => {
            setCategoryLoader(false)
          })
        }
      }
    }
  }, [isVisible])

  const handleCategorySelect = (categoryId: number | null, index: number) => {
    const updatedSelectedCategories = [...selectedCategories]

    updatedSelectedCategories[index] = categoryId

    setSelectedCategories(updatedSelectedCategories.slice(0, index + 1))

    // Dispatch API call to fetch child categories if categoryId is not null
    if (categoryId !== null) {
      dispatch(getPlatformChildFieldDataCategoriesAsync({platform_Id: categoryId}))
        .then((response) => {
          // Check if the response contains child categories
          if (response.payload && response.payload.length > 0) {
            setChildCategories(response.payload)
          } else {
            // No child categories found, you may want to handle this case
            // Check if the selected category has child categories
            const hasChildCategories = categories.some((cat) => cat.parent_data === categoryId)
            // if (!hasChildCategories) {
            //   // Clear child categories if the selected category does not have any child categories
            //   setChildCategories([]);
            // }
          }
        })
        .catch((error) => {
          // Handle error if needed
        })
    } else {
      // Clear child categories if the selected category is null
      setChildCategories([])
    }
  }

  const getNthCategoryOfPlatformParentFieldData = (fieldId: number, index: number) => {
    dispatch(getPlatformChildFieldDataCategoriesAsync({platform_Id: fieldId})).then((response) => {
      if (response.payload?.length) {
        let shadowSelectedIds = [...selectedCategoriesIds]
        shadowSelectedIds = shadowSelectedIds.slice(0, index)
        shadowSelectedIds = [...shadowSelectedIds, fieldId]
        setSelectedCategoriesIds(shadowSelectedIds)
        let temp = {}
        let selectedtemp = {...selectedCategoriesData, [fieldId]: [...response.payload]}
        for (let id of shadowSelectedIds) {
          temp[id] = selectedtemp[id]
        }
        setSelectedCategoriesData(temp)
        setCurrentIndex(index)
      } else {
        setSelectedCategoryIdForMapping(fieldId)
        setCurrentIndex(index)
      }
    })
  }

  useEffect(() => {
    let drdnele = document.getElementById(`dropdown${currentIndex}`) as HTMLSelectElement
    if (drdnele) {
      drdnele.selectedIndex = 0
    }
  }, [currentIndex])

  useEffect(() => {
    // in case of update we want to select default values in dropdowns
    if (categoryData && selectedCategoriesIds.length) {
      let parentdrdn = document.getElementById(`parentIndex0`) as HTMLSelectElement
      if (parentdrdn) {
        parentdrdn.selectedIndex = 1
        // parentdrdn.disabled = true
      }

      for (let i in selectedCategoriesIds) {
        let drdnele = document.getElementById(`dropdown${i}`) as HTMLSelectElement
        if (drdnele) {
          drdnele.value = selectedCategoriesIds[i] + ''
        }
      }
    }
  }, [selectedCategoriesIds, categoryData, selectedCategoriesData, categoryLoader])

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Use this Switch when you want all Products to use this Mapping/Rule.
    </Tooltip>
  )

  type INITIAL_VALUES = {
    // platform_id: number
    name: string
    isDefault: boolean
    // category_data: number
    // attributes: {
    //   attribute_field: number
    //   value_str: null|string
    //   value: number|null
    //   mapped_field: null|number
    // }[]|[]
  }

  const initialValues: INITIAL_VALUES = {
    // platform_id: 7,
    name: categoryData?.name ? categoryData?.name : '',
    isDefault: categoryData?.isDefault ? categoryData.isDefault : false,
    // category_data: 759,
    // attributes: [],
  }

  return (
    <>
      <Modal size={'xl'} backdrop='static' className='pl-0' centered show={isVisible} onHide={closeModal}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, helpers) => {
            console.log('paylod',finalPayload)
            helpers.setSubmitting(true)
            let cop = {...finalPayload}
            cop.name = values.name
            cop.isDefault = values.isDefault
            cop.platform_id= selectedSecondaryPlatform?.id
            cop.category_data = selectedCategoryIdForMapping
              ? selectedCategoryIdForMapping
              : categoryData?.category_data.id!

            if (categoryData) {
              // let filteredAttribute = [...cop.attributes].filter((ite)=>(ite.value!==null&&ite.value_str!==null&&ite.mapped_field!==null))
              // cop.attributes = filteredAttribute

              dispatch(
                updateCategoryTemplateDataAsync({
                  category_template: categoryData.id,
                  payloadupdate: {...cop},
                })
              ).then((response) => {
                if (response.payload?.data?.error) {
                  toast.error(response.payload?.data?.error)
                  helpers.setSubmitting(false)
                }

                if (response.payload?.id) {
                  toast.success('Category mapping updated successfully')
                  helpers.setSubmitting(false)
                  getLatestCategoryTemplateList()
                  closeModal()
                }
                // toast.success("Succesfully Saved")
              })
            } else {
              console.log("cop",cop)
              dispatch(postFieldDataAttributesAsync(cop)).then((response) => {
                if (response.payload?.data?.error) {
                  toast.error(response.payload?.data?.error)
                  helpers.setSubmitting(false)
                }

                if (response.payload?.id) {
                  toast.success('Category mapping created successfully')
                  helpers.setSubmitting(false)
                  getLatestCategoryTemplateList()
                  closeModal()
                }
                // toast.success("Succesfully Saved")
              })
            }
          }}
        >
          {({values, handleSubmit, handleChange, setFormikState,isSubmitting}) => {
            return (
              <>
                <form action='' onSubmit={handleSubmit}>
                  <Modal.Header>
                    <Modal.Title>
                      {categoryData?.id ? 'Update Category Mapping' : 'Add New Category Mapping'}
                    </Modal.Title>
                    <div className='d-inline-flex'>
                      <Button
                        size='sm'
                        type='button'
                        className='mx-2'
                        
                        onClick={() => {
                          closeModal()
                          setSelectedCategoriesIds([])
                          setSelectedCategoriesData({})
                          setAttributesInUpdateCase(null)
                          setShowAttributes(false)
                          setSelectedCategoryIdForMapping(null)
                        }}
                        disabled={isSubmitting}

                      >
                        Close
                      </Button>
                      <Button
                        size='sm'
                        type='submit'
                        variant='success'
                        // onClick={handleSaveButtonClick}
                        disabled={isSubmitting}
                      >
                       {isSubmitting?  "Saving" : " Save Changes"} 
                      </Button>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    {categoryLoader ? (
                      <Loader />
                    ) : (
                      <div>
                        <div>
                          <div className='d-flex justify-content-end form-check form-switch mt-2 ps-2'>
                            <div>
                              <label className='form-label mt-1'>
                                Default&nbsp;
                                <OverlayTrigger placement='left' overlay={renderTooltip}>
                                  <i className='bi bi-info-circle'></i>
                                </OverlayTrigger>
                              </label>
                            </div>
                            <input
                              className='ms-4 mb-4 form-check-input h-20px w-40px'
                              type='checkbox'
                              checked={values.isDefault}
                              name='isDefault'
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className='mb-8'>
                          <label className='form-label'>Rule name</label>
                          <input
                            name='name'
                            className='form-control'
                            placeholder='Rule name'
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>
                        {/* {Array.from(
                            {length: selectedCategoriesIds.length + 1},
                            (_, index) => index
                          ).map((index) => renderCategoryDropdown(index))} */}

                        {/* for top most parent  */}
                        <div className='mb-8'>
                          <label className='form-label'>{`Select Parent Category`}</label>
                          <select
                            id='parentIndex0'
                            className='form-select'
                            defaultValue={''}
                            disabled={categoryData !== null}
                            onChange={(ev) => {
                              setSelectedCategoryIdForMapping(null)
                              getNthCategoryOfPlatformParentFieldData(Number(ev.target.value), 0)
                            }}
                          >
                            <option value={''}>Select Category</option>
                            {Array.isArray(categories) &&
                              categories?.map((category, ind) => {
                                return (
                                  <option key={'par' + ind} value={category?.id}>
                                    {category?.api_name}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                        
                  
                        {selectedCategoriesIds.map((catId, index) => {
                          return (
                            <div key={'wrap' + index} className='mb-8'>
                              <label className='form-label'>{`Select Sub Category`}</label>
                              <select
                                id={`dropdown${index}`}
                                className='form-select'
                                disabled={categoryData !== null}
                                defaultValue={''}
                                onChange={(ev) => {
                                  setSelectedCategoryIdForMapping(null)
                                  getNthCategoryOfPlatformParentFieldData(
                                    Number(ev.target.value),
                                    index + 1
                                  )
                                  setShowAttributes(false)
                                }}
                              >
                                <option value={''}>Select Category</option>
                                {selectedCategoriesData[catId]?.length
                                  ? selectedCategoriesData[catId].map((item, ind) => {
                                      return (
                                        <option key={'option' + ind} value={item.id}>
                                          {item.api_name}
                                        </option>
                                      )
                                    })
                                  : null}
                              </select>
                            </div>
                          )
                        })}
                              { getPlatformChildFieldCategoryDataLoading && 
                        <div className="row justify-content-center">
                          <Spinner animation='border' /> 
                        </div>
                        }
                        

                        {( ['DEBENHAMS','SUPERPHARM','CATCH','THERANGEPLUS','EBAY','ETSY'].includes(selectedSecondaryPlatform?.code!))? (
                          <div className=' m-3 text-center'>
                            <Button
                              disabled={selectedCategoryIdForMapping == null}
                              onClick={() => createMappingUsingSelectedCategory()}
                            >
                              { getAttributeDataLoading ? <Spinner animation='border' /> : 'Map Attributes'}
                            </Button>
                          </div>
                        ) : null}

                        {/* Conditionally render attributes based on showAttributes state */}
                        <div>
                          {showAttributes && (
                            <InputAttributes
                              key={categoryData + ''}
                              paltformId={selectedSecondaryPlatform?.id}
                              categoryAttributes={getAttributeData}
                              handleAttributeSelection={handleAttributeSelection}
                              updatingMapData={attributesInUpdateCase}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Modal.Body>
                </form>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default AddNewCategoryTemplateModal
